import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const InvitationWrapper = styled.div`
.image-section{
  @media only screen and (max-width: 767px) {
    height: 300px !important;
  }
}
  .text-title {
    line-height: normal;
    margin-bottom: 32px;
  }
  .description {
    p:first-child,
    p:nth-child(2) {
      margin-bottom: 24px;
    }
  }
  .button-group {
    display: flex;
    button{
      font-weight: 600;
    }
  }
  .right-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }
`;

export default WithDirection(InvitationWrapper);
