import { permissionService } from '@iso/services';

const actions = {
  LOAD_PERMISSIONS: 'LOAD_PERMISSIONS',
  CHANGE_ACTIVITY_PERMISSION: 'CHANGE_ACTIVITY_PERMISSION',
  
  store: () => {
    return dispatch => {
      permissionService.getPermissions().then(res => {
        if(res.code === '0000'){
          dispatch(loadPermissions(res.permissions));
        }
      });
    }
  },
  changeActivePermission: (activePropertyId) => {
    return dispatch => {
      permissionService.getUserPermission(activePropertyId).then(res => {
        if(res.code === '0000'){
          dispatch(changeActive(res.permission));
        }
      });
    }
  }
};

function loadPermissions(permissions) {
  return {
    type: actions.LOAD_PERMISSIONS,
    permissions,
  }
}


function changeActive(activePermission){
  return {
    type: actions.CHANGE_ACTIVITY_PERMISSION,
    activePermission
  }
}

export default actions;
