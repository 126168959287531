export const animalTypes = [
  {
    label: 'AI',
    value: 'ai',
  },
  {
    label: 'Horse',
    value: 'horse',
  },
  {
    label: 'Dog',
    value: 'dog',
  },
  {
    label: 'Bull',
    value: 'bull',
  },
  {
    label: 'Ram',
    value: 'ram',
  },
  {
    label: 'Pig',
    value: 'pig',
  },
  {
    label: 'Other',
    value: 'other',
  }
]