export const objectColors = [
  {
    label: 'Black',
    value: 'black',
    color: 'rgb(44, 48, 62)',
    hexCode: '#2C303E'
  },
  {
    label: 'White',
    value: 'white',
    color: 'rgb(248, 249, 250)',
    hexCode: '#F8F9FA'
  },
  {
    label: 'Orange',
    value: 'orange',
    color: 'rgb(245, 160, 27)',
    hexCode: '#F5A01B'
  },
  {
    label: 'Light Green',
    value: 'light_green',
    color: 'rgb(146, 202, 37)',
    hexCode: '#92CA25'
  },
  {
    label: 'Purple',
    value: 'purple',
    color: 'rgb(150, 111, 225)',
    hexCode: '#966FE1'
  },
  {
    label: 'Yellow',
    value: 'yellow',
    color: 'rgb(239, 203, 25)',
    hexCode: '#EFCB19'
  },
  {
    label: 'Red',
    value: 'red',
    color: 'rgb(226, 78, 76)',
    hexCode: '#E24E4C'
  },
  {
    label: 'Sky Blue',
    value: 'sky_blue',
    color: 'rgb(49, 166, 197)',
    hexCode: '#31A6C5'
  },
  {
    label: 'Dark Blue',
    value: 'dark_blue',
    color: 'rgb(13, 103, 173)',
    hexCode: '#0D67AD'
  },
  {
    label: 'Blue',
    value: 'blue',
    color: 'rgb(97, 170, 217)',
    hexCode: '#61AAD9'
  },
  {
    label: 'Light Blue',
    value: 'light_blue',
    color: 'rgb(173, 219, 232)',
    hexCode: '#ADDBE8'
  },
  {
    label: 'Light Grey',
    value: 'light_grey',
    color: 'rgb(216, 222, 226)',
    hexCode: '#D8DEE2'
  },
  {
    label: 'Dark Grey',
    value: 'dark_grey',
    color: 'rgb(116, 119, 132)',
    hexCode: '#747784'
  },
  {
    label: 'Green',
    value: 'green',
    color: 'rgb(101, 187, 56)',
    hexCode: '#65BB38'
  },
  {
    label: 'Dark Green',
    value: 'dark_green',
    color: 'rgb(85, 159, 47)',
    hexCode: '#559F2F'
  },
  {
    label: 'Pink',
    value: 'pink',
    color: 'rgb(252, 145, 189)',
    hexCode: '#FC91BD'
  },
];