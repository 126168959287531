import { propertyService } from '@iso/services';

const actions = {
  STORE_PROPERTIES: 'STORE_PROPERTIES',
  CHANGE_ACTIVE_SUBSCRIPTION: 'CHANGE_ACTIVE_SUBSCRIPTION',
  
  changeActivePropertySubscription: (activePropertySubscription) => {
    return dispatch => {
      dispatch(changeActivePropertySubscription(activePropertySubscription));
    }
  }
};

function storeProperties(properties) {
  return {
    type: actions.STORE_PROPERTIES,
    properties,
  }
}

function changeActivePropertySubscription(activePropertySubscription){
  return {
    type: actions.CHANGE_ACTIVE_SUBSCRIPTION,
    activePropertySubscription
  }
}

export default actions;
