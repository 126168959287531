import React, { useState, useEffect } from "react";
import WelcomeModalWrapper from "./WelcomeModal.styles";
import Purpose from "@iso/components/WelcomeModal/Purpose/Purpose";
import InitialProperty from "@iso/components/WelcomeModal/InitialProperty/InitialProperty";
import Invitation from "@iso/components/WelcomeModal/Invitation/Invitation";
import TrialSubscription from "@iso/components/WelcomeModal/TrialSubscription/TrialSubscription";
import SubscriptionFlow from "@iso/components/WelcomeModal/SubscriptionFlow/SubscriptionFlow";
import GetReady from "@iso/components/WelcomeModal/GetReady/GetReady";

const WelcomeModal = (props) => {
  const [purpose, setPurpose] = useState("");
  const [step, setStep] = useState("purpose");
  const [propertyId, setPropertyId] = useState(null);

  const cancelModal = () => {
    props.cancelWelcomeModal();
  };

  const renderContent = () => {
    switch (step) {
      case "purpose":
        return <Purpose setPurpose={setPurpose} setStep={setStep}></Purpose>;
      case "initial_property":
        return (
          <InitialProperty
            purpose={purpose}
            setStep={setStep}
            getPropertyList={props.getPropertyList}
            setPropertyId={setPropertyId}
          ></InitialProperty>
        );
      case "invitation":
        return (
          <Invitation propertyId={propertyId} setStep={setStep}></Invitation>
        );
      case "get_ready":
        return <GetReady propertyId={propertyId} setStep={setStep}></GetReady>;
      case "trial_subscription":
        return (
          <TrialSubscription
            propertyId={propertyId}
            setStep={setStep}
          ></TrialSubscription>
        );
      case "subscription_flow":
        return <SubscriptionFlow></SubscriptionFlow>;
      default:
        return <></>;
    }
  };

  return (
    <WelcomeModalWrapper
      step={step}
      width={1200}
      visible={props.visibleWelcomeModal}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[]}
    >
      <div className="form-body">{renderContent()}</div>
    </WelcomeModalWrapper>
  );
};

export default WelcomeModal;
