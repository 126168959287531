export const aircraftTypes = [
  {
    label: 'Fixed Wing',
    value: 'fixed_wing',
  },
  {
    label: 'Multi Rotor',
    value: 'multi_rotor',
  },
  {
    label: 'Other',
    value: 'other',
  }
]