import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";
import "gantt-task-react/dist/index.css";

const TaskChartWrapper = styled.div`
  width: 100%;
  // height: 100%;
  .ViewContainer {
    margin-bottom: 15px;
    display: flex;
    gap:0.5rem;
    button {

font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
      width:58px;
      height:40px;
      color : white;
      padding: 0px, 16px, 10px, 16px;
      border-radius: 8px;
      border: 1px ;
      border-color : #535763;
      background-color : #535763;
      gap: 8px;
      &.active {
        color: #ffffff;
        background-color: #f80e46;
        border-color : #f80e46;
      }
    }
  }
`;

export default WithDirection(TaskChartWrapper);
