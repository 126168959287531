const notificationActions = {
  NOTIFICATION_DATA: 'NOTIFICATION_DATA',
  
  notificationData: data => ({
    type: notificationActions.NOTIFICATION_DATA,
    data,
  }),
  
};

export default notificationActions;
