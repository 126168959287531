export const acreageUnit = [
  {
    label: 'Acres',
    value: 'acres',
  },
  {
    label: 'Hectare',
    value: 'hectare',
  },
  {
    label: 'Square Yard',
    value: 'yard',
  }
]