export const qualificationTypes = [
  {
    label: 'Trade',
    value: 'trade',
  },
  {
    label: 'Course',
    value: 'course',
  },
  {
    label: 'First Aid',
    value: 'first_aid',
  }
]