export const vehicleLicence = [
  {
    label: 'Car',
    value: 'car',
  },
  {
    label: 'Light Ridge',
    value: 'light_ridge',
  },
  {
    label: 'Medium Ridge',
    value: 'medium_ridge',
  },
  {
    label: 'Heavy Ridge',
    value: 'heavy_ridge',
  },
  {
    label: 'Heavy Combination',
    value: 'heavy_combination',
  },
  {
    label: 'Multi Combination',
    value: 'multi_combination',
  }
]