import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const SafeFarmWrapper = styled.div`
  width: 100%;
  height: 100%;
  h1 {
    color: #f80e46;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .statistic-card {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    min-height: 300px;
  }
  .statistic-cards{
    background-color: #ffffff !important;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height: auto;
    min-height: 300px;
    margin : 0px ;
    padding-top: 10px !important;
  }
  .statistic-cards.isoBoxWrapper{
  padding:0px !important;
}
@media only screen and (max-width: 1200px) {
    .statistic-cards{
      margin-top : 10px !important;
    }
    .tablesCust{
      margin-top : 0px;
    gap : 0.5rem;
    }
  }
`;
export default WithDirection(SafeFarmWrapper);
