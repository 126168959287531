import axios from "axios";
import { apiEndpoint } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const publicFormService = {
  getFormByToken,
  submitPublicForm,
  uploadImage
};

function getFormByToken(token){
  return (
    axios
      .get(
        `${apiEndpoint.publicForm}/get-form-by-token?token=${token}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function submitPublicForm(data){
  return (
    axios
    .post(
      `${apiEndpoint.publicForm}/submit-public-form`,
      data
    ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function uploadImage(data){
  return (
    axios
    .post(
      `${apiEndpoint.publicForm}/upload-image`,
      data
    ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}
