export const assetStates = [
  {
    label: 'Available',
    value: 'available',
    color: 'rgb(85, 159, 47)'
  },
  {
    label: 'Empty',
    value: 'empty',
    color: 'rgb(239, 203, 25)'
  },
  {
    label: 'Low',
    value: 'low',
    color: 'rgb(245, 160, 27)'
  },
  {
    label: 'Out of action',
    value: 'out of action',
    color: 'rgb(226, 78, 76)'
  },
  {
    label: 'Other',
    value: 'other',
    color: 'rgb(97, 170, 217)'
  }
]