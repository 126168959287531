import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Input, Button, Select, Row, Col, Switch, DatePicker, Upload, message, Checkbox, List, Typography, Space} from 'antd';
import IntlMessages from '@iso/components/utility/intlMessages';
import InspectionModalWrapper from './InspectionModal.styles'
import Inspection from './Inspection';

const InspectionModal = (props) => {

  return (
    <InspectionModalWrapper
      className="user-access-modal"
      forceRender={true}
      maskClosable={true}
      open={props.visibleInspectionModal}
      title={<IntlMessages id="Form Type" />}
      onCancel={props.cancelInspectionModal}
      footer={[
        <Button key="back" onClick={props.cancelInspectionModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>
      ]}
    >
      <Inspection formId={props.formId} propertyId={props.propertyId}></Inspection>
    </InspectionModalWrapper>
  );
}

export default InspectionModal;