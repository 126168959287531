import React from "react";
import { Row, Col } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import GetReadyWrapper from "./GetReady.styles";
const isMobile = !isDesktop && !isTablet;

const GetReady = (props) => {
  return (
    <GetReadyWrapper>
      {isTablet || isMobile ? (
        <Row>
          <Col className="image-section" span={24}>
            <RightSection></RightSection>
          </Col>
          <Col span={24}>
            <LeftSection propertyId={props.propertyId} setStep={props.setStep}></LeftSection>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={{ span: 24 }} lg={{ span: 11 }}>
            <LeftSection propertyId={props.propertyId} setStep={props.setStep}></LeftSection>
          </Col>
          <Col
            className="image-section"
            md={{ span: 24 }}
            lg={{ span: 11, offset: 2 }}
          >
            <RightSection></RightSection>
          </Col>
        </Row>
      )}
    </GetReadyWrapper>
  );
};

export default GetReady;
