export const waterTypes = [
  {
    label: 'Dam',
    value: 'dam',
  },
  {
    label: 'Tank',
    value: 'tank',
  },
  {
    label: 'Trough',
    value: 'trough',
  },
  {
    label: 'Bore',
    value: 'bore',
  },
  {
    label: 'Windmill',
    value: 'windmill'
  }
]