import React, { useState, useEffect, useRef } from 'react';
import { Button} from 'antd';

const PitchToggle = (props) => {
  const {pitch,setPitch,mapgl} = props;
  useEffect(() => {
    if(mapgl){
      loadPitch(mapgl);
    }
  },[pitch]);

  const loadPitch = (mapgl) => {
    let options = { pitch: 0 };
    if(pitch){
      options = {pitch: 75}   
    }
    mapgl.easeTo(options);
  }
  return(
    <Button className={pitch ? "mapboxgl-ctrl-pitchtoggle-2d" : "mapboxgl-ctrl-pitchtoggle-3d"} onClick={() => setPitch(pitch => !pitch) }></Button>
  )
}

export default PitchToggle;


