import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import SplitModalWrapper from './SplitModal.styles';
import { Form, Input, Button, Select, Row, Col, InputNumber, DatePicker,Radio,Divider,message } from 'antd';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import Loader from '@iso/components/utility/loader';
import { objectColors } from '@iso/constants/objectColors';
import moment from 'moment';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import _ from 'lodash';
import { mobTypes } from '@iso/constants/mobTypes';
import styled from "styled-components";
import L from 'leaflet';
import inside from 'point-in-polygon';
import MoveModalWrapper from './MoveModal.styles';
import { mobService, primaryObjectService} from '@iso/services';

const { Option } = Select;

const MoveModal = (props) => {
  const [form] = Form.useForm();
  const {
    mobData,
    isMoveModalOpen,
    areaNear,
    setIsMoveModalOpen,
    updatePropertyDetail,
    propertyId,
    setAreaNear,
    getLatLngMarkerArea,
    objectRef,
    setPointArea,
    pointArea,
    updateDataMobs
  } = props;

  const optionsRadio = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
  ];

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  const messageWarning = () => {
    messageApi.open({
      type: 'warning',
      content: 'The mob area is not enough',
    });
  };

  const updateMob = (created_at, time, point) => {
      console.log('mobData',mobData);
      let mob = mobData;
      
      if(_.isEmpty(pointArea)){
        messageWarning();
        return;
      }
     
      mob = {
        ...mobData,
        point: pointArea ? pointArea : '',
      }
      
     
      const data = {
        created_at: created_at ? dateHelper.dateForAPI(created_at) : '',
        time: time ? time : '',
        mob: mob
      }
      mobService.storeOrUpdate(data, mobData.id);
      setAreaNear([]);
      setPointArea({});
  }
  const cancelMoveModal = () => {
    form.resetFields();
    updateDataMobs(propertyId);
    setPointArea({});
    setAreaNear([]);
    setIsMoveModalOpen(false);
  }

  const handlerMoveModal = () => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        updateMob(values.created_at,values.time);
        setIsMoveModalOpen(false);
        updatePropertyDetail(propertyId);
        form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
      setLoading();

  }

  const listMobs = (mobs) => {
    let arrayPoint = [];
     _.forEach(mobs, (mob) => {
        arrayPoint.push(
          {
            "lat": mob.latitude,
            "lng": mob.longitude
          }
        );
    });
     return arrayPoint;
  }

  const onChangeListNearest = (id) => {
    primaryObjectService.view(id).then(res => {
      if(res.code === '0000'){
        let arrayPoints = listMobs(res.primary_object.mobs);
        setPointArea(getLatLngMarkerArea(1,res.primary_object,arrayPoints)[0]);
      }
    });
  }

  const renderListNearestArea = (listAreas) => {
    let options = [];
     _.forEach(listAreas, (value, index) => {
      options.push(
        <Option key={index} value={value.primary_object.id}>
          {value.primary_object.name}
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a Area"
        allowClear
        onChange={onChangeListNearest}
        size="large"
      >
        {options}
      </Select>
      )
  }

  return(
    <>
    {contextHolder}
    <MoveModalWrapper title="Did this happen in the past? "
        visible={isMoveModalOpen}
        onCancel={cancelMoveModal}
        footer={[
          <Button key="back" onClick={cancelMoveModal} className="cancel-button">
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button key="submit" className="btn-success" type="primary" onClick={handlerMoveModal} loading={loading}>
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
      
        <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            fields={fields}
            className="form-body"
          >

          <Form.Item
            name='created_at'
            className="isoInputWrapper"
            label="Date"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DatePicker size="large" style={{width: '100%'}}/>
          </Form.Item>
          {areaNear.length > 0 &&
            <Form.Item
              name=''
              className="isoInputWrapper"
              label="Nearest Area"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              {renderListNearestArea(areaNear)}
            </Form.Item>
          }
          <Form.Item
            name='time'
            className="isoInputWrapper"
            label="Time"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
          <Radio.Group options={optionsRadio} optionType="button" />
          </Form.Item>
        </Form>

      </MoveModalWrapper>
      </>
  )
}

export default MoveModal;

