import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  message,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
} from "antd";
import _ from "lodash";
import { HazardFormWrapper } from "./HazardFormWrapper.style";
import {
  formService,
 
  propertyService,
 
} from "@iso/services";
import { publicFormService } from "../../../../../services/publicform.service";
import { visitorFormService } from "../../../../../services/visitorform.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSelector, useDispatch } from "react-redux";
import SignaturePad from "@iso/components/SignaturePad/index";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";
import { hazardstatus } from "../../../../../constants/hazardstatus";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";
const { TextArea } = Input;
const { Option } = Select;

const HazardForm = (props) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const mapRef = useRef();

  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        form.setFieldValue(["hazard", "lat"], res.property.lat); 
        form.setFieldValue(["hazard", "lng"], res.property.lng);
      }
    });
  }, [props]);


  // const updatePropertyDetail = React.useCallback((id) => {
  //   propertyService.viewProperty(id).then(res => {
  //     if (res.code === '0000') {
  //       setPosition([res.property.lat, res.property.lng]);
  //     }
  //   })
  // }, []);
  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        handleFinish(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (props.formData && props.formData.hazard) {
      const formResult = props.formData.hazard;
      for (let field in formResult) {
        if (["date_time", "submit_time"].includes(field)) {
          form.setFieldValue(["hazard", field], moment(formResult[field]));
        } else {
          form.setFieldValue(["hazard", field], formResult[field]);
        }
      }
    }
  }, [props.formData]);

  const timeConfig = {
    rules: [{ required: true, message: "Please select time!" }],
  };

  const handleFinish = (values) => {
    values.hazard.date_time = dateHelper.dateTimeForAPI(
      values.hazard.date_time
    );
    values.hazard.submit_time = dateHelper.dateTimeForAPI(
      values.hazard.submit_time
    );
    const data = {
      form: {
        global_form: values,
        type: "hazard_form",
      },
    };
    if (props.visitorForm) {
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
      visitorFormService.createVisitorForm(data).then(onSuccessCallback);
    } else if (props.isPublicForm) {
      data.form.form_token = props.formToken;
      publicFormService.submitPublicForm(data).then(onSuccessCallback);
    } else {
      formService
        .storeOrUpdate(data, props.formData.id)
        .then(onSuccessCallback);
    }
  };

  const onSuccessCallback = (res) => {
    if (res.code === "0000") {
      message.success("Submit success");
      props.setShowFinalScreen(true);
      setLoading(false);
    } else {
      message.error(res.message);
      setLoading(false);
    }
  };

  const handleClickMap = (e) => {
    const { lat, lng } = e.latlng;
    form.setFieldValue(["hazard", "lat"], lat);
    form.setFieldValue(["hazard", "lng"], lng);
  };

  useEffect(() => {
    if (props.propertyId) {
      updatePropertyDetail(props.propertyId);
    }
  }, [props.propertyId]);


  const renderHazardStatus = () => {
    let options = [];
    _.forEach(hazardstatus, (status, index) => {
      options.push(
        <Option key={index} value={status.value}>
          <div>{status.label}</div>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a status" allowClear size="large">
        {options}
      </Select>
    );
  };

  return (
    <HazardFormWrapper>
      <Form form={form} layout="vertical" fields={fields}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["hazard", "date_time"]}
              label={<IntlMessages id="When was the hazard identified" />}
              tooltip={<IntlMessages id="hazardForm.time.tooltip" />}
              {...timeConfig}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["hazard", "status"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Status" />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              {renderHazardStatus()}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={["hazard", "description"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Brief description of hazard/incident" />}
          tooltip={<IntlMessages id="hazardForm.description.tooltip" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues["hazard"];
          }}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                className="isoInputWrapper no-style"
                label={<IntlMessages id="Location" />}
                tooltip={<IntlMessages id="hazardForm.location" />}
              >
                <Map
                  ref={mapRef}
                  // center ={[-37.16274, 146.08866]}
                  center={
                    getFieldValue(["hazard", "lat"]) &&
                    getFieldValue(["hazard", "lng"])
                      ? [
                          getFieldValue(["hazard", "lat"]),
                          getFieldValue(["hazard", "lng"]),
                        ]
                      : false
                  }
                  doubleClickZoom={false}
                  onclick={handleClickMap}
                  zoom={16}
                  zoomControl={true}
                  style={{ width: "100%", height: "300px" }}
                >
                  { subscriptionHelper.checkIsGold(activePropertySubscription) ? (
                    <ReactLeafletGoogleLayer
                      type={"hybrid"}
                      useGoogMapsLoader={true}
                    />
                  ) : (
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  )}
                  {getFieldValue(["hazard", "lat"]) &&
                    getFieldValue(["hazard", "lng"]) && (
                      <Marker
                        position={[
                          getFieldValue(["hazard", "lat"]),
                          getFieldValue(["hazard", "lng"]),
                        ]}
                        draggable={true}
                      >
                        <Popup
                          position={[
                            getFieldValue(["hazard", "lat"]),
                            getFieldValue(["hazard", "lng"]),
                          ]}
                        ></Popup>
                      </Marker>
                    )}
                </Map>
                
                <Row style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col xs={{ span: 24 }} md={{ span: 11 }}>
                    <Form.Item
                      name={["hazard", "lat"]}
                      className="isoInputWrapper"
                      label="Latitute"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                    <Form.Item
                      name={["hazard", "lng"]}
                      label="Longitude"
                      className="isoInputWrapper"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name={["hazard", "recommendation"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Recommendation" />}
          tooltip={<IntlMessages id="hazardForm.recommendation.tooltip" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["hazard", "submit_time"]}
          label={<IntlMessages id="Date submitted to manager or property owner" />}
          {...timeConfig}
        >
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item
          name={["hazard", "action_taken"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Action Taken" />}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["hazard", "has_acknowledged"]}
          label={<IntlMessages id="Has the hazard/incident been acknowledged by management or property owner ?" />}
          {...timeConfig}
        >
          <Radio.Group>
            <Radio value="yes"> Yes </Radio>
            <Radio value="no"> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={["hazard", "describe_to_resolve"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Describe what has been done to resolve the hazard/incident" />}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name={["hazard", "consider_fixed"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Do you consider the hazard/incident fixed ?" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Radio.Group>
            <Radio value="yes"> Yes </Radio>
            <Radio value="no"> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={["hazard", "name"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Name" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Form.Item
          name={["hazard", "position"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Position" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Form.Item
          name={["hazard", "signature"]}
          label="Signature"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <SignaturePad className='btn-success' tableName={"Signature"}></SignaturePad>
        </Form.Item>
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </HazardFormWrapper>
  );
};

export default HazardForm;
