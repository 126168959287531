export const averageWeightWeaner  = [
  {
    label: '150',
    value: 150,
  },
  {
    label: '200',
    value: 200,
  },
  {
    label: '250',
    value: 250
  },
  {
    label: '300',
    value: 300
  },  
  {
    label: '350',
    value: 350
  },  
  {
    label: '400',
    value: 400
  },  
  {
    label: '450',
    value: 450
  },  
  {
    label: '500',
    value: 500
  },
]