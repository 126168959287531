export const averageWeightEwes  = [
  {
    label: '40',
    value: 40,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '60',
    value: 60
  },
  {
    label: '70',
    value: 70
  },  

]