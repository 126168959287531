export const healthSafetyTypes = [
  {
    label: 'Evacuation Point',
    value: 'evacuation_point',
  },
  {
    label: 'Fire Extinguisher',
    value: 'fire_extinguisher',
  },
  {
    label: 'First Aid Kit',
    value: 'first_aid_kit'
  },
  {
    label: 'Policy',
    value: 'policy'
  },
  {
    label: 'Induction',
    value: 'induction'
  },  
  {
    label: 'Procedure',
    value: 'procedure'
  }
]