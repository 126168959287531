export const assetHistoryCategories = [
  {
    label: 'Usage',
    value: 'usage',
  },
  {
    label: 'Service',
    value: 'service',
  },
  {
    label: 'Refill',
    value: 'refill',
  },
  {
    label: 'Other',
    value: 'other',
  }
]