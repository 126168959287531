export const simplePasture = [
  {
    label: 'How long will the paddock last?',
    value: 'howLongWill',
  },
  {
    label: 'How much feed will be available at the end of the grazing period?',
    value: 'howMuchFeed',
  },
  // {
  //   label: 'How many head can I run in this paddock?',
  //   value: 'howManyHead'
  // },
  {
    label: 'How many hectares do I need?',
    value: 'howManyHectares'
  },
]