import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
import Modal from '@iso/components/Feedback/Modal';

const WeatherEmailSettingWrapper = styled(Modal)`
 .group-icon{
    display: flex;
    p{
        margin-left: 5px;
    }
 }
 .ant-checkbox{
    bottom: 4px
 }
.weather-type{
    margin-bottom: 24px;
}
.normal-label{
    margin-bottom: 8px;
}
.ant-select-multiple{
    width: 100%;
}
.btn-success{
    background-color: #00B341;
}
`;

export default WithDirection(WeatherEmailSettingWrapper);