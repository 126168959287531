import React from "react";

const Visitors = () => {
  return (
    <div className="sidebar-icon">
      <svg
      className="sidebar-icon"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
      >
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,72.000000 
	C1.000000,48.376186 1.000000,24.752375 1.000000,1.064281 
	C34.230019,1.064281 67.460190,1.064281 101.000000,1.064281 
	C101.000000,24.353315 101.000000,47.708000 100.782272,71.873665 
	C95.631660,69.568146 90.824623,66.222420 85.719421,63.419842 
	C82.582733,61.697918 80.677925,59.354156 82.447060,56.341949 
	C85.706718,50.791889 86.958488,44.955231 87.871872,38.675732 
	C89.020004,30.782331 81.958206,20.359514 74.067574,21.225306 
	C66.789520,22.023878 63.883080,15.378532 58.186375,13.538954 
	C56.383057,12.956626 54.347569,11.017321 51.768539,10.993483 
	C46.030544,10.940448 40.976730,12.836617 37.389587,17.265314 
	C34.970539,20.251875 32.444141,21.233280 28.663424,21.107632 
	C21.567888,20.871815 16.296894,25.080391 15.117866,32.031242 
	C14.456461,35.930496 14.748018,40.056099 15.097117,44.037022 
	C15.296503,46.310707 16.066223,48.756660 17.283497,50.679050 
	C21.764891,57.756294 20.839375,61.082607 13.444588,64.943878 
	C9.593019,66.955017 6.101681,69.652390 2.412243,71.983795 
	C2.073389,72.197922 1.476872,72.004288 1.000000,72.000000 
z"
        />
        <path
          fill="rgb(143, 148, 151)"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,72.454109 
	C1.476872,72.004288 2.073389,72.197922 2.412243,71.983795 
	C6.101681,69.652390 9.593019,66.955017 13.444588,64.943878 
	C20.839375,61.082607 21.764891,57.756294 17.283497,50.679050 
	C16.066223,48.756660 15.296503,46.310707 15.097117,44.037022 
	C14.748018,40.056099 14.456461,35.930496 15.117866,32.031242 
	C16.296894,25.080391 21.567888,20.871815 28.663424,21.107632 
	C32.444141,21.233280 34.970539,20.251875 37.389587,17.265314 
	C40.976730,12.836617 46.030544,10.940448 51.768539,10.993483 
	C54.347569,11.017321 56.383057,12.956626 58.186375,13.538954 
	C63.883080,15.378532 66.789520,22.023878 74.067574,21.225306 
	C81.958206,20.359514 89.020004,30.782331 87.871872,38.675732 
	C86.958488,44.955231 85.706718,50.791889 82.447060,56.341949 
	C80.677925,59.354156 82.582733,61.697918 85.719421,63.419842 
	C90.824623,66.222420 95.631660,69.568146 100.782272,72.342323 
	C101.000000,74.700012 101.000000,77.400032 100.535652,80.550018 
	C95.809052,80.999985 91.546806,80.999985 86.758316,80.999985 
	C89.169296,85.897324 87.357262,87.078743 83.078308,87.054817 
	C61.768631,86.935707 40.457626,86.943619 19.147867,87.055046 
	C15.492275,87.074158 12.860272,86.705673 14.368005,82.000000 
	C9.557457,82.000000 5.278728,82.000000 1.000000,82.000000 
	C1.000000,78.969406 1.000000,75.938812 1.000000,72.454109 
M40.500317,82.999245 
	C54.236954,82.999245 67.973595,82.999245 81.710236,82.999245 
	C81.986588,82.522598 82.262947,82.045952 82.539307,81.569298 
	C80.039246,79.214348 77.826324,76.397835 74.967598,74.619438 
	C71.262856,72.314751 66.818352,71.195839 63.125374,68.876816 
	C60.695637,67.351051 57.985416,64.918663 57.223305,62.351265 
	C55.163925,55.413620 59.881119,49.882236 62.368336,43.911964 
	C62.992832,42.412922 63.294548,40.750324 63.543579,39.131958 
	C63.803833,37.440670 63.424160,35.556942 64.020424,34.029022 
	C65.779770,29.520716 64.592346,25.001991 61.755074,22.241110 
	C58.690159,19.258707 54.101017,16.944353 49.873795,16.287128 
	C44.836929,15.504025 38.852551,20.933695 38.147121,26.147858 
	C37.674431,29.641743 39.736401,33.668678 38.745277,36.861397 
	C36.872883,42.892979 40.733658,46.878742 42.922333,51.082932 
	C46.838997,58.606384 45.444839,66.029472 38.973133,69.005768 
	C34.829998,70.911171 30.484667,72.508736 26.654972,74.919456 
	C23.911409,76.646469 21.871889,79.491936 19.518257,81.838394 
	C19.857138,82.225342 20.196020,82.612297 20.534903,82.999245 
	C26.856825,82.999245 33.178749,82.999245 40.500317,82.999245 
M64.071945,52.656013 
	C60.349564,60.772785 60.775261,61.970432 68.654770,65.936874 
	C73.290703,68.270538 78.997948,69.667290 82.245811,73.268791 
	C86.405960,77.881912 90.647568,76.761940 95.548317,76.396667 
	C94.420708,74.584793 93.147591,72.947227 91.486862,71.965134 
	C88.842987,70.401642 85.971085,69.205109 83.141350,67.978615 
	C77.919380,65.715263 75.102119,59.025555 77.487083,54.090530 
	C79.733124,49.442982 81.809479,44.963982 82.214050,39.513935 
	C82.884483,30.482590 78.472832,25.426378 68.992004,26.460045 
	C68.992004,30.593861 68.366310,34.902660 69.144012,38.941208 
	C70.213051,44.492668 65.124992,47.351391 64.071945,52.656013 
M28.628313,26.015541 
	C22.037222,28.224705 18.344246,31.653030 19.155458,36.174629 
	C20.198820,41.990189 21.717291,47.843578 23.982885,53.284340 
	C26.569685,59.496471 25.074003,65.067177 18.934977,68.017380 
	C15.571653,69.633682 12.174636,71.210297 8.962108,73.093597 
	C7.824601,73.760445 7.188955,75.283363 5.641499,77.309212 
	C10.232548,76.969887 15.094971,77.993950 16.464334,76.259773 
	C21.310434,70.122627 28.359880,68.361816 34.661423,65.104698 
	C41.075958,61.789177 41.242149,60.013966 38.223179,53.497543 
	C35.756767,48.173824 31.470366,43.423527 33.233986,36.298241 
	C33.959721,33.366146 30.816170,29.476343 28.628313,26.015541 
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,82.469376 
	C5.278728,82.000000 9.557457,82.000000 14.368005,82.000000 
	C12.860272,86.705673 15.492275,87.074158 19.147867,87.055046 
	C40.457626,86.943619 61.768631,86.935707 83.078308,87.054817 
	C87.357262,87.078743 89.169296,85.897324 86.758316,80.999985 
	C91.546806,80.999985 95.809052,80.999985 100.535652,80.999992 
	C101.000000,87.645546 101.000000,94.291100 100.999985,100.938751 
	C67.666626,100.940849 34.333282,100.940849 1.000000,100.940849 
	C1.000000,94.900719 1.000000,88.919731 1.000000,82.469376 
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M40.000496,82.999245 
	C33.178749,82.999245 26.856825,82.999245 20.534903,82.999245 
	C20.196020,82.612297 19.857138,82.225342 19.518257,81.838394 
	C21.871889,79.491936 23.911409,76.646469 26.654972,74.919456 
	C30.484667,72.508736 34.829998,70.911171 38.973133,69.005768 
	C45.444839,66.029472 46.838997,58.606384 42.922333,51.082932 
	C40.733658,46.878742 36.872883,42.892979 38.745277,36.861397 
	C39.736401,33.668678 37.674431,29.641743 38.147121,26.147858 
	C38.852551,20.933695 44.836929,15.504025 49.873795,16.287128 
	C54.101017,16.944353 58.690159,19.258707 61.755074,22.241110 
	C64.592346,25.001991 65.779770,29.520716 64.020424,34.029022 
	C63.424160,35.556942 63.803833,37.440670 63.543579,39.131958 
	C63.294548,40.750324 62.992832,42.412922 62.368336,43.911964 
	C59.881119,49.882236 55.163925,55.413620 57.223305,62.351265 
	C57.985416,64.918663 60.695637,67.351051 63.125374,68.876816 
	C66.818352,71.195839 71.262856,72.314751 74.967598,74.619438 
	C77.826324,76.397835 80.039246,79.214348 82.539307,81.569298 
	C82.262947,82.045952 81.986588,82.522598 81.710236,82.999245 
	C67.973595,82.999245 54.236954,82.999245 40.000496,82.999245 
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M64.287720,52.325020 
	C65.124992,47.351391 70.213051,44.492668 69.144012,38.941208 
	C68.366310,34.902660 68.992004,30.593861 68.992004,26.460045 
	C78.472832,25.426378 82.884483,30.482590 82.214050,39.513935 
	C81.809479,44.963982 79.733124,49.442982 77.487083,54.090530 
	C75.102119,59.025555 77.919380,65.715263 83.141350,67.978615 
	C85.971085,69.205109 88.842987,70.401642 91.486862,71.965134 
	C93.147591,72.947227 94.420708,74.584793 95.548317,76.396667 
	C90.647568,76.761940 86.405960,77.881912 82.245811,73.268791 
	C78.997948,69.667290 73.290703,68.270538 68.654770,65.936874 
	C60.775261,61.970432 60.349564,60.772785 64.287720,52.325020 
z"
        />
        <path
          fill="transparent"
          opacity="1.000000"
          stroke="none"
          d="
M29.030453,26.018850 
	C30.816170,29.476343 33.959721,33.366146 33.233986,36.298241 
	C31.470366,43.423527 35.756767,48.173824 38.223179,53.497543 
	C41.242149,60.013966 41.075958,61.789177 34.661423,65.104698 
	C28.359880,68.361816 21.310434,70.122627 16.464334,76.259773 
	C15.094971,77.993950 10.232548,76.969887 5.641499,77.309212 
	C7.188955,75.283363 7.824601,73.760445 8.962108,73.093597 
	C12.174636,71.210297 15.571653,69.633682 18.934977,68.017380 
	C25.074003,65.067177 26.569685,59.496471 23.982885,53.284340 
	C21.717291,47.843578 20.198820,41.990189 19.155458,36.174629 
	C18.344246,31.653030 22.037222,28.224705 29.030453,26.018850 
z"
        />
      </svg>
    </div>
  );
};
export default Visitors;
