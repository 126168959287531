import React from "react";
import { Row, Col } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import InvitationWrapper from "./Invitation.styles";
const isMobile = !isDesktop && !isTablet;

const Invitation = (props) => {
  return (
    <InvitationWrapper>
      {isTablet || isMobile ? (
        <Row>
          <Col className="image-section" span={24}>
            <RightSection></RightSection>
          </Col>
          <Col span={24}>
            <LeftSection setStep={props.setStep} propertyId={props.propertyId}></LeftSection>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={{ span: 24 }} lg={{ span: 11 }}>
            <LeftSection setStep={props.setStep} propertyId={props.propertyId}></LeftSection>
          </Col>
          <Col
            className="image-section"
            md={{ span: 24 }}
            lg={{ span: 11, offset: 2 }}
          >
            <RightSection></RightSection>
          </Col>
        </Row>
      )}
    </InvitationWrapper>
  );
};

export default Invitation;
