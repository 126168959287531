export const weatherCategory = {
    rain: {
      label: 'Rain',
      value: 'rain',
    },
    temp: {
      label: 'Temperature',
      value: 'temp',
    },
    cloud: {
      label: 'Cloud',
      value: 'cloud',
    },
    wind: {
      label: 'Wind',
      value: 'wind',
    }
  }