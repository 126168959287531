export const averageWeightLambs  = [
  {
    label: '15',
    value: 15,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '35',
    value: 35
  },  
  {
    label: '45',
    value: 45
  }, 
]