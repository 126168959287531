export const emergencyTypes = [
  {
    label: 'Emergency Contact',
    value: 'emergency_contact',
  },
  {
    label: 'Expiry Of Tetanus',
    value: 'expiry_of_tetanus',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Notes',
    value: 'notes',
  }
]