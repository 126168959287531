export const mobTypes = [
  {
    label: 'Cattle',
    value: 'cattle',
  },
  {
    label: 'Sheep',
    value: 'sheep',
  },
  {
    label: 'Goat',
    value: 'goat',
  },
  {
    label: 'Other',
    value: 'other',
  }
]