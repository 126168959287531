import React from "react"
import { ViewMode } from "gantt-task-react"

export const ViewSwitcher = ({setView,view }) => {
  
  const onClickViewMode = (viewMode) =>{
    setView(viewMode);
  }
  return (
    <div className='ViewContainer'>
      <button className={view === "Day" ? "active Button" : "Button"} onClick={() => onClickViewMode(ViewMode.Day)}>
        Day
      </button>
      <button className={view === "Week" ? "active Button" : "Button"} onClick={() => onClickViewMode(ViewMode.Week)}>
        Week
      </button>
      <button className={view === "Month" ? "active Button" : "Button"} onClick={() => onClickViewMode(ViewMode.Month)}>
        Month
      </button> 
      <button className={view === "Year" ? "active Button" : "Button"} onClick={() => onClickViewMode(ViewMode.Year)}>
        Year
      </button>
    </div>
  )
}