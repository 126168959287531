export const pregTesting = [
  {
    label: 'Empty',
    value: 'empty',
  },
  {
    label: 'Pregnant',
    value: 'pregnant',
  },
  {
    label: 'Dry',
    value: 'dry',
  },
  {
    label: 'Multiple',
    value: 'multiple',
  },
]