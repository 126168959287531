const bronzeSubscriptions = [process.env.REACT_APP_STRIPE_PLAN_BRONZE];
const silverSubscriptions = [process.env.REACT_APP_STRIPE_PLAN_SILVER];
const goldSubscriptions = [
    process.env.REACT_APP_STRIPE_PLAN_GOLD,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD,
    process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY
];

const checkIsBronze = (subscription) => {
    return bronzeSubscriptions.includes(subscription);
};

const checkIsSilver = (subscription) => {
    return silverSubscriptions.includes(subscription);
};

const checkIsNotSilver = (subscription) => {
    return !silverSubscriptions.includes(subscription);
};

const checkIsGold = (subscription) => {
    return goldSubscriptions.includes(subscription);
};

const checkIsNotGold = (subscription) => {
    return !goldSubscriptions.includes(subscription);
};

export const subscriptionHelper = {
    checkIsBronze,
    checkIsSilver,
    checkIsNotSilver,
    checkIsGold,
    checkIsNotGold
}