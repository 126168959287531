import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const InspectionWrapper = styled.div`
margin:15px;
  width: 100%;
  height: 100%;
  .button-group{
    width: 100%;
    padding-right: 10px;
    margin-bottom: 10px;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    align-items: flex-end;
    .filter-label{
      margin: 0px 5px 10px 20px;
    }
  }
  .mainDiv{
   
    display: flex;
    align-items: center;
   padding:0px !important;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
   
  }
  .mainDiv .search-bar {
    background: rgba(143, 146, 161, 0.05);
    border-radius: 8px;
    border: none;
   margin:0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    flex-direction: row-reverse;
    max-width: 228px;
    
}
.mainDiv .search-bar .ant-input {
  background: none!important;
  border: none!important;
  color: #8F92A1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
  .btn-success{
    background-color: #f80e46;
    border-color: #f80e46;
    &:hover{
      opacity: 0.8;
    }
  }  
  .ant-table-wrapper {
    min-width: 100%;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }
  .delete-button{
    margin-left:8px;
  }
  .dataTables_filter{
    margin-bottom:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
  }
  .dataTables_filter .input-sm{
    margin-left:10px;
    text-align:center;
    outline-offset: unset;
    border-radius: 8px;
  }
  .react-collapsible-theme .react-collapsible-search input{
    text-align: center;
    border: 0;
    border: 5px #C9C9CE solid;
    border-radius: 6px;
    width: 100%;
    height: 2rem;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65)
  }
  .search-icon{
    top:5px!important;
    left:15px!important;
    width:20px;
  }

  .clear-icon{
    position: relative;
    top:5px!important;
    right:2px!important;
  }

  .search-icon svg{
    color: rgba(0, 0, 0, 0.65)
  }

  .action-button.view-history-button, .action-button{
    margin-bottom:5px;
  }

  @media (max-width: 768px){
    .react-collapsible-theme .react-collapsible-search .search-input{
      width:100%;
    }
    .property-filter{
      margin-bottom: 10px;
      .filter-label{
        margin-right: 12px;
      }
    }
  }
`;

export default WithDirection(InspectionWrapper);