import React from 'react';
import { Button} from 'antd';
import {InputNumber, Slider } from 'antd';

const StyleLightControl  = (props) =>{
  const {setStyleMap,styleMap,mapgl,switchLayer} = props;
  const onClick =() => {
    if(mapgl){
      setStyleMap(process.env.REACT_APP_MAPBOX_STYLE_LIGHT)
      switchLayer(mapgl,process.env.REACT_APP_MAPBOX_STYLE_LIGHT);
    }
    
  }
  return(
    <Button     
      className={
        styleMap === process.env.REACT_APP_MAPBOX_STYLE_LIGHT ?  
        "mapboxgl-ctrl-active mapboxgl-ctrl-icon mapboxgl-ctrl-light-mode" : 
        "mapboxgl-ctrl-icon mapboxgl-ctrl-light-mode"
      } 
      onClick={onClick}></Button>
  )
}
export default StyleLightControl;


