import L from 'leaflet';
import iot from './iot.png';

export const IotDeviceIcon = () => {
  var icon = new L.Icon({
    iconUrl: iot,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [44, 60],
    iconAnchor: [22, 60],
    className: 'custom-icon-marker iot-device-marker'
  });

  return icon;
};