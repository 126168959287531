export const containerUnits = [
  {
    label: 'Mililitres',
    value: 'mililitres',
  },
  {
    label: 'Litres',
    value: 'litres',
  },
  {
    label: 'Grams',
    value: 'grams',
  },
  {
    label: 'Kilograms',
    value: 'kilograms',
  },
  {
    label: 'Units',
    value: 'units',
  }
]