export const taskRepeatTypes = [
  {
    label: 'No Repeat',
    value: 0,
  },
  {
    label: 'Repeat Weekly',
    value: 1,
  },
  {
    label: 'Repeat Monthly',
    value: 2,
  },
  {
    label: 'Repeat Quarterly',
    value: 3,
  },
  {
    label: 'Repeat Annually',
    value: 4,
  }
]