import { propertyService } from '@iso/services';

const actions = {
  STORE_PROPERTIES: 'STORE_PROPERTIES',
  CHANGE_ACTIVE_PIC: 'CHANGE_ACTIVE_PIC',
  
  changeActivePropertyPIC: (activePropertyPIC) => {
    return dispatch => {
      dispatch(changeActivePropertyPIC(activePropertyPIC));
    }
  }
};

function storeProperties(properties) {
  return {
    type: actions.STORE_PROPERTIES,
    properties,
  }
}

function changeActivePropertyPIC(activePropertyPIC){
  return {
    type: actions.CHANGE_ACTIVE_PIC,
    activePropertyPIC
  }
}

export default actions;
