export const shedTypes = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Machinery',
    value: 'machinery',
  },
  {
    label: 'Shearing',
    value: 'shearing',
  },
  {
    label: 'Hay',
    value: 'hay',
  },
  {
    label: 'Fuel',
    value: 'fuel',
  },
  {
    label: 'Other',
    value: 'other',
  }
]