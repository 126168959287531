export const treatmentRates = [
  {
    label: 'Per Head',
    value: 'per_head',
  },
  {
    label: 'Per Weight',
    value: 'per_weight',
  },
  {
    label: 'Other',
    value: 'other',
  },
]