import React, { useState, useEffect, useCallback } from 'react';
import {Select, Modal} from 'antd';
import _ from 'lodash';
import './permissionModal.css';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const { Option } = Select;
const PermissionSelect = (props) => {
  const {object, permissions,updateDataTable} = props;
  const text = 'There can only be one Owner, are you sure you want to make this change?';
  let options=[];
  _.forEach(permissions, (permission) => {
    options.push(
      <Option key={permission.id} value={permission.id}>{permission.name}</Option>
    );
  });
  const [value,setValue] = useState();
  const handleOptionClick = (value) => {
    if(value == 1){
      showConfirm(value);
    }else{
      props.handleOptionClick(value, object);
    }
    updateDataTable();
    
  } 

  const showConfirm = (value) => {
    confirm({
      className:'confirm-permission-modal',
      title: 'There can only be one Owner, are you sure you want to make this change?',
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk() {
        props.handleOptionClick(value, object);
        updateDataTable();
      },
      onCancel() {
        
      },
    });
  };

  return(
    <Select
    placeholder="Select a permission"
    allowClear
    defaultValue={object.permission_id}
    size="medium"
    onChange={handleOptionClick}
    style={{ width: 150 }}
    disabled={object.permission_id==1}
    >  
      {options}
    </Select>
  )
}

export default PermissionSelect;