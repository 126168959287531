import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  message,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
} from "antd";
import _ from "lodash";
import { TrainingFormWrapper } from "./TrainingFormWrapper.style";
import {
  formService,
  propertyService,
} from "@iso/services";
import { visitorFormService } from "../../../../../services/visitorform.service";
import { publicFormService } from "../../../../../services/publicform.service";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { useSelector, useDispatch } from "react-redux";
import SignaturePad from "@iso/components/SignaturePad/index";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const TrainingForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const mapRef = useRef();

  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then((res) => {
      if (res.code === "0000") {
        form.setFieldValue(["training", "lat"], res.property.lat);
        form.setFieldValue(["training", "lng"], res.property.lng);
      }
    });
  }, []);

  const handleSubmit = async () => {
    if (!props.isAbleToSubmit) {
      return;
    }
    if (props.visitorForm) {
      const visitorFormValidation = await props.visitorForm.validateFields();
      if (visitorFormValidation.errorFields) {
        return;
      }
    }
    form
      .validateFields()
      .then((values) => {
        handleFinish(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (props.formData && props.formData.training) {
      const formResult = props.formData.training;
      for (let field in formResult) {
        if (
          ["certification_license_expiration", "date_completed"].includes(field)
        ) {
          form.setFieldValue(["training", field], moment(formResult[field]));
        } else if (field === "duration" && formResult[field]) {
          form.setFieldValue(
            ["training", field],
            [moment(formResult[field][0]), moment(formResult[field][1])]
          );
        } else {
          form.setFieldValue(["training", field], formResult[field]);
        }
      }
    }
  }, [props.formData]);

  const timeConfig = {
    rules: [{ required: true, message: "Please select time!" }],
  };

  const handleFinish = (values) => {
    values.training.date_completed = dateHelper.dateTimeForAPI(
      values.training.date_completed
    );
    values.training.certification_license_expiration =
      dateHelper.dateTimeForAPI(
        values.training.certification_license_expiration
      );
    if (values.training.duration) {
      values.training.duration = [
        dateHelper.dateTimeForAPI(values.training.duration[0]),
        dateHelper.dateTimeForAPI(values.training.duration[1]),
      ];
    }
    const data = {
      form: {
        global_form: values,
        type: "training_form",
      },
    };
    if (props.visitorForm) {
      data.visitor = props.visitorForm.getFieldsValue().visitor;
      data.form.visitor_form_token = props.visitorFormToken;
      visitorFormService.createVisitorForm(data).then(onSuccessCallback);
    } else if (props.isPublicForm) {
      data.form.form_token = props.formToken;
      publicFormService.submitPublicForm(data).then(onSuccessCallback);
    } else {
      formService
        .storeOrUpdate(data, props.formData.id)
        .then(onSuccessCallback);
    }
  };

  const onSuccessCallback = (res) => {
    if (res.code === "0000") {
      message.success("Submit success");
      props.setShowFinalScreen(true);
      setLoading(false);
    } else {
      message.error(res.message);
      setLoading(false);
    }
  };

  const handleClickMap = (e) => {
    const { lat, lng } = e.latlng;
    form.setFieldValue(["training", "lat"], lat);
    form.setFieldValue(["training", "lng"], lng);
  };

  useEffect(() => {
    if (props.propertyId) {
      updatePropertyDetail(props.propertyId);
    }
  }, [props.propertyId]);

  useEffect(() => {
    if (props.property) {
      if (props.property.lat && props.property.lng) {
        form.setFieldValue(["training", "lat"], props.property.lat);
        form.setFieldValue(["training", "lng"], props.property.lng);
      }
    }
  }, [props.property]);

  return (
    <TrainingFormWrapper>
      <Form form={form} layout="vertical" fields={fields}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["training", "training_provided"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Training Provided" />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["training", "reason"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Reason For Training" />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["training", "duration"]}
              label={<IntlMessages id="Duration" />}
              {...timeConfig}
            >
              <RangePicker format="YYYY-MM-DD" size="large" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["training", "who_provided_training"]}
              className="isoInputWrapper"
              label={<IntlMessages id="Who Provided Training" />}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues["training"];
          }}
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item
                className="isoInputWrapper no-style"
                label={<IntlMessages id="Location" />}
              >
                <Map
                  ref={mapRef}
                  center={
                    getFieldValue(["training", "lat"]) &&
                    getFieldValue(["training", "lng"])
                      ? [
                          getFieldValue(["training", "lat"]),
                          getFieldValue(["training", "lng"]),
                        ]
                      : false
                  }
                  doubleClickZoom={false}
                  onclick={handleClickMap}
                  zoom={16}
                  zoomControl={true}
                  style={{ width: "100%", height: "300px" }}
                >
                  { subscriptionHelper.checkIsGold(activePropertySubscription) ? (
                    <ReactLeafletGoogleLayer
                      type={"hybrid"}
                      useGoogMapsLoader={true}
                    />
                  ) : (
                    <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  )}
                  {getFieldValue(["training", "lat"]) &&
                    getFieldValue(["training", "lng"]) && (
                      <Marker
                        position={[
                          getFieldValue(["training", "lat"]),
                          getFieldValue(["training", "lng"]),
                        ]}
                        draggable={true}
                      >
                        <Popup
                          position={[
                            getFieldValue(["training", "lat"]),
                            getFieldValue(["training", "lng"]),
                          ]}
                        ></Popup>
                      </Marker>
                    )}
                </Map>
                <Row style={{ marginTop: "10px", marginBottom: "5px" }}>
                  <Col xs={{ span: 24 }} md={{ span: 11 }}>
                    <Form.Item
                      name={["training", "lat"]}
                      className="isoInputWrapper"
                      label="Latitute"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                    <Form.Item
                      name={["training", "lng"]}
                      label="Longitude"
                      className="isoInputWrapper"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name={["training", "date_completed"]}
          label={<IntlMessages id="Date Completed" />}
          {...timeConfig}
        >
          <DatePicker size="large" format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name={["training", "certification_license_type"]}
          className="isoInputWrapper"
          label={<IntlMessages id="Certification/ License Type" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 11 }}>
            <Form.Item
              name={["training", "certification_license_number"]}
              className="isoInputWrapper"
              label={
                <IntlMessages id="Certification/ License Number" />
              }
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <Form.Item
              name={["training", "certification_license_expiration"]}
              label={
                <IntlMessages id="Certification/ License Expiration" />
              }
              {...timeConfig}
            >
              <DatePicker size="large" format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={["training", "signature_of_worker"]}
          label={<IntlMessages id="Signature Of Worker" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <SignaturePad tableName={"Signature Of Worker"}></SignaturePad>
        </Form.Item>

        <Form.Item
          name={["training", "competent"]}
          label={<IntlMessages id="Competent" />}
          {...timeConfig}
        >
          <Radio.Group>
            <Radio value="yes"> Yes </Radio>
            <Radio value="no"> No </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["training", "signature_of_manager"]}
          label={<IntlMessages id="Signature Of Manager" />}
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <SignaturePad tableName={"Signature Of Manager"}></SignaturePad>
        </Form.Item>

        <Button
          key="submit"
          className="btn-success btn-submit-bio"
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </Button>
      </Form>
    </TrainingFormWrapper>
  );
};

export default TrainingForm;
