import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import logo from "@iso/assets/images/logo.png";
import InvitationModal from "./InvitationModal";

const LeftSection = (props) => {
  const [visibleInvitationModal, setVisibleInvitationModal] = useState(false);

  const cancelInvitationModal = () => {
    setVisibleInvitationModal(false);
  };

  return (
    <>
      <img className="small-logo" src={logo} />
      <h1 className="text-title">
        Invite your family, consultants and employees
      </h1>
      <div className="description">
        <p>
          Having everyone that works on your property in SafeFarm, allows for a
          safer and more efficient business operation. Do not worry SafeFarm does
          not charge per user.
        </p>
        <p>
          On the free account you can have unlimited users / connections added
          to your property.
        </p>
        <p>
          {
            "Email Address, Name, Mobile (optional), Role Type (Need {i} next to role) so people can see what each role is for."
          }
        </p>
      </div>
      <Row className="button-group" gutter={0}>
        <Col xs={{span: 24}} md={{span:12}} lg={{span: 12}}>
          <Button
            className="submit-btn"
            type="primary"
            size="large"
            onClick={() => {
              setVisibleInvitationModal(true);
            }}
          >
            Invite
          </Button>
        </Col>
        <Col xs={{span: 24}} md={{span:12}} lg={{span: 12}}>
          <Button
            className="outline-btn"
            size="large"
            onClick={() => {
              props.setStep('get_ready')
            }}
          >
            Not Now
          </Button>
        </Col>
      </Row>
      <InvitationModal
        setStep={props.setStep}
        visibleInvitationModal={visibleInvitationModal}
        cancelInvitationModal={cancelInvitationModal}
        propertyId={props.propertyId}
      ></InvitationModal>
    </>
  );
};

export default LeftSection;
