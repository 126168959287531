export const feedUnits = [
  {
    label: 'Kilogram',
    value: 'kilogram',
  },
  {
    label: 'Tone',
    value: 'tone',
  },
  {
    label: 'Bale',
    value: 'bale',
  },
  {
    label: 'Other',
    value: 'other',
  },
]