import React from 'react';
import { Table, Tag, Space } from 'antd';
import type { ColumnsType } from 'antd/lib/table';


const Table7 = () => {
  const columns = [
    {
      title: 'Livestock class',
      dataIndex: 'livestock',
    },
    {
      title: '75% ',
      dataIndex: '75',
    },
    {
      title: '68% ',
      dataIndex: '68',
    },    
    {
      title: '60%',
      dataIndex: '60',
    },
  ];
  const data = [
    { key: 1,
      livestock: 'Dry sheep',
      75: 400,
      68: 600,
      60: 1200,
    },    
    { key: 2,
      livestock: 'Pregnant ewes',
      children: [
        {
          livestock: 'Mid pregnancy',
          75: 500,
          68: 700,
          60: 1700,
        }, 
        {
          livestock: 'Last month',
          75: 700,
          68: 1200,
          60: 'ns',
        },
      ]
    }, 
    { key: 3,
      livestock: 'Lactating ewes',
      children: [
        {
          livestock: 'Single',
          75: 1000,
          68: 1700,
          60: 'ns',
        }, 
        {
          livestock: 'Twins',
          75: 1500,
          68: 'ns',
          60: 'ns',
        },
      ]
    }, 
    { key: 4,
      livestock: 'Growing sheep % of potential growth',
      children: [
        {
          livestock: '30% (75 g/day)',
          75: 400,
          68: 700,
          60: 1700,
        }, 
        {
          livestock: '50% (125 g/day)',
          75: 600,
          68: 1000,
          60: 'ns',
        },
        {
          livestock: '70% (175 g/day)',
          75: 800,
          68: 1700,
          60: 'ns',
        },
        {
          livestock: '90% (225 g/day)',
          75: 1600,
          68: 'ns',
          60: 'ns',
        },
      ]
    },
    { key: 5,
      livestock: 'Cattle pregnancy status',
      children: [
        {
          livestock: 'Dry cow',
          75: 700,
          68: 1100,
          60: 2600,
        }, 
        {
          livestock: 'Pregnant cow, 7-8 mths',
          75: 900,
          68: 1700,
          60: 'ns',
        },
        {
          livestock: 'Lactating cow (calf 2 mths)',
          75: 1100,
          68: 2200,
          60: 'ns',
        },
      ]
    },
    { key: 6,
      livestock: 'Growing cattle % of potential growth',
      children: [
        {
          livestock: '30% (0.39 kg/day)',
          75: 600,
          68: 1100,
          60: 2900,
        }, 
        {
          livestock: '50% (0.61 kg/day)',
          75: 800,
          68: 1600,
          60: 'ns',
        },
        {
          livestock: '70% (0.85 kg/day)',
          75: 1200,
          68: 2600,
          60: 'ns',
        },
        {
          livestock: '90% (1.12 kg/day)',
          75: 2200,
          68: 'ns',
          60: 'ns',
        },
      ]
    }, 
     
   
  ];

  
  return (
    <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      
    </div>
  )
}


export default Table7;