export const assetCategories = [
  {
    label: 'Aircraft',
    value: 'aircraft',
  },
  {
    label: 'Animal',
    value: 'animal',
  },
  {
    label: 'Fencing',
    value: 'fencing',
  },
  {
    label: 'Inventory',
    value: 'inventory',
  },
  {
    label: 'Machinery',
    value: 'machinery',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'Homestead',
    value: 'property',
  },
  {
    label: 'Shed',
    value: 'shed',
  },
  
  {
    label: 'Storage',
    value: 'storage',
  },
  
  {
    label: 'Water',
    value: 'water',
  },
  {
    label: 'Weather',
    value: 'weather',
  },
  {
    label: 'Healthy and Safety',
    value: 'health_and_safety',
  }
]