import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const layerObjectService = {
  getList,
  storeOrUpdate,
  destroy,
  updateColor
};

function getList(layerId) {
  return (
    axios
      .get(
        `${apiEndpoint.layerObjects}?layer_id=${layerId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if (res && res.status === 200 && res.data.code === '0000') {
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null) {
  return (id ? (
    axios
      .put(
        apiEndpointFunction.layerObjects(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.layerObjects,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if (res && res.status === 200) {
      return res.data;
    }
    return {};
  });
}

function destroy(id) {
  return (
    axios
      .delete(
        apiEndpointFunction.layerObjects(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if (res && res.status === 200) {
          return res.data;
        }
      })
  );
}

function updateColor(data, id) {
  return (
    axios
      .put(
        apiEndpointFunction.layerObjects(id) + '/update-color',
        data,
        {
          headers: authHeader(),
        }
      )
      .then(res => {
        if (res && res.status === 200) {
          return res.data;
        }
        return {};
      })
  );
}