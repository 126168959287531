import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const pushnotification = {
  getData,
  storeOrUpdate,
  markRead,
};

function getData(propertyId,userId){
  return (
    axios
      .get(
        `${apiEndpoint.pushnotification}?property_id=${propertyId}&user_id=${userId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.pushnotification(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.pushnotification,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function markRead(data){
  return (
    axios
      .post(
        `${apiEndpoint.pushnotification}/mark-read`,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

