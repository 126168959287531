import React, { useState, useEffect } from "react";
import { Button, Radio, Form, Row, Col } from "antd";
import logo from "@iso/assets/images/logo.png";

const LeftSection = (props) => {
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setFields([
      {
        name: ["purpose"],
        value: "",
      },
    ]);
  }, []);

  const handleSubmit = (e) => {
    form
      .validateFields()
      .then(async (values) => {
        props.setPurpose(values.purpose);
        props.setStep("initial_property");
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <img className="small-logo" src={logo} />
      <h1 className="text-title">How are you planning to use SafeFarm ?</h1>
      <Form form={form} layout="vertical" scrollToFirstError fields={fields}>
        <Form.Item
          name={["purpose"]}
          className="isoInputWrapper"
          label=""
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Radio.Group className="purpose-options">
            <Radio value="for_family_safety">For family safety</Radio>
            <Radio value="for_employee_safety">For employee safety</Radio>
            <Radio value="for_property_management">
              For property management
            </Radio>
            <Radio value="for_property_weather">For property weather</Radio>
            <Radio value="all_of_the_above">All of the above</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Row className="button-group" gutter={16}>
        <Col>
          <Button
            className="submit-btn"
            type="primary"
            size="large"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LeftSection;
