export const assetHistoryTypes = [
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Volume',
    value: 'volume',
  },
  {
    label: 'Other',
    value: 'other',
  }
]