import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const categoryGlobalFormService = {
  storeOrUpdate,
  get,
  destroy,
  view,
  getLivedForms,
  getGlobalForms,
};

function get(){
    return (
        axios
            .get(
                apiEndpoint.categoryGlobalForm,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}

function storeOrUpdate(data, id = null){
    return (id ? (
        axios
          .put(
              `${apiEndpointFunction.categoryGlobalForm(id)}`,
            data,
            {
              headers: authHeader(),
            }
          )
    ) : (
        axios
          .post(
            apiEndpoint.categoryGlobalForm,
            data,
            {
              headers: authHeader()
            }
          )
    )).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
    });
}

function getGlobalForms(id=null){
  return (id ? (
    axios
      .get(
          `${apiEndpoint.categoryGlobalForm}/get-global-forms?category_id=${id}`,
          {
              headers: authHeader(),
          }
      )
  ):(
    axios
      .get(
          `${apiEndpoint.categoryGlobalForm}/get-global-forms`,
          {
              headers: authHeader(),
          }
      )
  )).then(res => {
      if(res && res.status === 200){
          return res.data;
      }
  });
}


function view(id){
    return (
        axios
            .get(
                `${apiEndpointFunction.categoryGlobalForm(id)}`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}

function destroy(id){
    return (
        axios
            .delete(
                `${apiEndpointFunction.categoryGlobalForm(id)}`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}

function getLivedForms(){
    return (
        axios
            .get(
                `${apiEndpoint.categoryGlobalForm}/get-lived-forms`,
                {
                    headers: authHeader(),
                }
            ).then(res => {
            if(res && res.status === 200){
                return res.data;
            }
        })
    );
}
