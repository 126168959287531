import React, { useState, useEffect } from "react";
import WeatherEmailSettingWrapper from "./WeatherEmailSetting.style";
import IntlMessages from "@iso/components/utility/intlMessages";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Divider,
  Switch,
  TimePicker,
  message
} from "antd";
import { ReactComponent as PrecipTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/precip-tomorrowio.svg";
import { ReactComponent as TemperatureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/temperature-tomorrowio.svg";
import { ReactComponent as FeelsLikeTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/feels-like-tomorrowio.svg";
import { ReactComponent as HumidityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/humidity-tomorrowio.svg";
import { ReactComponent as WindSpeedTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-speed-tomorrowio.svg";
import { ReactComponent as WindGustTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-gust-tomorrowio.svg";
import { ReactComponent as WindDirectionTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/wind-direction-tomorrowio.svg";
import { ReactComponent as PressureTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/pressure-tomorrowio.svg";
import { ReactComponent as RainAccTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/rain-acc-tomorrowio.svg";
import { ReactComponent as VisibilityTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/visibility-tomorrowio.svg";
import { ReactComponent as CloudCoverTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-cover-tomorrowio.svg";
import { ReactComponent as CloudBaseTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-base-tomorrowio.svg";
import { ReactComponent as CloudCeilingTomorrowio } from "@iso/containers/Pages/WeatherMap/Controls/icons/cloud-ceiling-tomorrowio.svg";
import { weatherCategory } from "@iso/constants/weatherCategory";
import _ from "lodash";

import { weatherEmailSettingService } from "../../../services/weatheremailsetting.service";
import { useSelector } from 'react-redux';

const MONTH_OPTIONS = [
  {
    value: 1,
    label: "January",
  },
  {
    value: 2,
    label: "February",
  },
  {
    value: 3,
    label: "March",
  },
  {
    value: 4,
    label: "April",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "June",
  },
  {
    value: 7,
    label: "July",
  },
  {
    value: 8,
    label: "August",
  },
  {
    value: 9,
    label: "September",
  },
  {
    value: 10,
    label: "October",
  },
  {
    value: 11,
    label: "November",
  },
  {
    value: 12,
    label: "December",
  },
];

const INITIAL_DATA = {
  is_send_daily: false,
  is_send_weekly: false,
  weather_types: [],
  months: MONTH_OPTIONS.map((item) => item.value),
}
const WeatherEmailSetting = ({isShowEmailSetting, setIsShowEmailSetting}) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weatherEmailSetting, setWeatherEmailSetting] = useState(INITIAL_DATA);
  const [weatherTypes, setWeatherTypes] = useState({
    precipitationIntensity: {
      key: "precipitationIntensity",
      label: "Precip. Intensity",
      unit: "mm/hr",
      icon: PrecipTomorrowio,
      category: weatherCategory.rain.value,
    },
    temperature: {
      key: "temperature",
      label: "Temperature",
      unit: "°C",
      icon: TemperatureTomorrowio,
      category: weatherCategory.temp.value,
    },
    temperatureApparent: {
      key: "temperatureApparent",
      label: "Feels Like",
      unit: "°C",
      icon: FeelsLikeTomorrowio,
      category: weatherCategory.temp.value,
    },
    humidity: {
      key: "humidity",
      label: "Humidity",
      unit: "%",
      icon: HumidityTomorrowio,
      category: weatherCategory.temp.value,
    },
    windSpeed: {
      key: "windSpeed",
      label: "Wind Speed",
      unit: "knots",
      icon: WindSpeedTomorrowio,
      category: weatherCategory.wind.value,
    },
    windGust: {
      key: "windGust",
      label: "Wind Gust",
      unit: "knots",
      icon: WindGustTomorrowio,
      category: weatherCategory.wind.value,
    },
    windDirection: {
      key: "windDirection",
      label: "Wind Direction",
      unit: "",
      icon: WindDirectionTomorrowio,
      category: weatherCategory.wind.value,
    },
    pressureSeaLevel: {
      key: "pressureSeaLevel",
      label: "Pressure",
      value: "",
      unit: "",
      icon: PressureTomorrowio,
      category: weatherCategory.rain.value,
    },
    rainAccumulation: {
      key: "rainAccumulation",
      label: "Rain Acc",
      unit: "mm",
      icon: RainAccTomorrowio,
      category: weatherCategory.rain.value,
    },
    visibility: {
      key: "visibility",
      label: "Visibility",
      unit: "km",
      icon: VisibilityTomorrowio,
      category: weatherCategory.rain.value,
    },
    cloudCover: {
      key: "cloudCover",
      label: "Cloud Cover",
      unit: "%",
      icon: CloudCoverTomorrowio,
      category: weatherCategory.cloud.value,
    },
    cloudBase: {
      key: "cloudBase",
      label: "Cloud Base",
      unit: "ft",
      icon: CloudBaseTomorrowio,
      category: weatherCategory.cloud.value,
    },
    cloudCeiling: {
      key: "cloudCeiling",
      label: "Cloud Ceiling",
      unit: "ft",
      icon: CloudCeilingTomorrowio,
      category: weatherCategory.cloud.value,
    },
  });

  const handleChangeWeatherType = (weatherType, isCheck) => {
    const newWeatherEmailSetting = { ...weatherEmailSetting };
    if (isCheck) {
      newWeatherEmailSetting.weather_types.push(weatherType);
    } else {
      _.remove(newWeatherEmailSetting.weather_types, (item) => {
        return item === weatherType;
      });
    }
    setWeatherEmailSetting(newWeatherEmailSetting);
  };

  const renderWeatherTypeCheckboxes = (category) => {
    const weatherTypeCheckboxs = [];
    for (const [key, value] of Object.entries(weatherTypes)) {
      if (value.category !== category) continue;
      weatherTypeCheckboxs.push(
        <div>
          <Checkbox
            key={key}
            checked={weatherEmailSetting.weather_types.includes(value.key)}
            onChange={(e) =>
              handleChangeWeatherType(value.key, e.target.checked)
            }
          >
            <div className="group-icon">
              <value.icon width={24} height={24} />
              <p>{value.label}</p>
            </div>
          </Checkbox>
        </div>
      );
    }
    return weatherTypeCheckboxs;
  };

  const handleChangeMonths = (months) => {
    const newWeatherEmailSetting = { ...weatherEmailSetting };
    newWeatherEmailSetting.months = months;
    setWeatherEmailSetting(newWeatherEmailSetting);
  };

  const onChangeSwitch = (checked, type) => {
    const newWeatherEmailSetting = { ...weatherEmailSetting };
    newWeatherEmailSetting[type] = checked;
    setWeatherEmailSetting(newWeatherEmailSetting);
  };

  const handleSubmit = () => {
    if(!activePropertyId) return message.error("Please select property")
    setLoading(true);
    const data = {
      ...weatherEmailSetting,
      property_id: activePropertyId,
      months: JSON.stringify(weatherEmailSetting.months),
      weather_types: JSON.stringify(weatherEmailSetting.weather_types),
    }
    weatherEmailSettingService.create(data).then((res) => {
     
      if (res.code === "0000") {
        message.success("Saved !");
      } else {
        message.success(res.error);
      }
      setLoading(false);
    }).catch((err) => {
    
      setLoading(false);
    });;
  }

  const getWeatherEmailSetting = () => {
    if(!activePropertyId) return
    setLoading(true);
    
    weatherEmailSettingService.getByPropertyId(activePropertyId).then((res) => {
     
      if (res.code === "0000") {
        setWeatherEmailSetting(res.data ? res.data : INITIAL_DATA);
      } else {
        message.success(res.error);
      }
      setLoading(false);
    }).catch((err) => {
      console.log('err', err)
      setLoading(false);
    });
  }

  useEffect(() => {
    getWeatherEmailSetting()
  }, [activePropertyId])
  return (
    <WeatherEmailSettingWrapper
      forceRender={true}
      visible={isShowEmailSetting}
      title={<IntlMessages id="weatherPage.weatherEmail.setting" />}
      maskClosable={false}
      onCancel={() => setIsShowEmailSetting(false)}
      footer={[
        <Button key="back" className="cancel-button" onClick={() => setIsShowEmailSetting(false)}>
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button
          key="submit"
          className="btn-success"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="addProperty"
        layout="horizontal"
        scrollToFirstError
        initialValues={{
          use_my_account: false,
        }}
        fields={fields}
      >
        <Form.Item
          label={<IntlMessages id="weatherPage.weatherEmail.sendEmailDaily" />}
          valuePropName="checked"
          tooltip={<IntlMessages id="weatherPage.weatherEmail.sendEmailDaily.tooltip" />}
        >
          <Switch onChange={ (checked) => onChangeSwitch(checked, "is_send_daily")} checked={weatherEmailSetting.is_send_daily ? true : false}/>
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="weatherPage.weatherEmail.sendEmailWeekly" />}
          valuePropName="checked"
          tooltip={<IntlMessages id="weatherPage.weatherEmail.sendEmailWeekly.tooltip" />}
        >
          <Switch onChange={ (checked) => onChangeSwitch(checked, "is_send_weekly")} checked={weatherEmailSetting.is_send_weekly ? true : false}/>
        </Form.Item>
        <div className="weather-type">
          <p className="normal-label"><IntlMessages id="weatherPage.weatherEmail.weatherType" /></p>
          <Row gutter={24}>
            {_.map(weatherCategory, (item, index) => {
              return (
                <Col className="here" span={12}>
                  <p>{item.label}</p>
                  {renderWeatherTypeCheckboxes(item.value)}
                </Col>
              );
            })}
          </Row>
        </div>
        <p className="normal-label">
          <IntlMessages id="weatherPage.weatherEmail.months" />
        </p>
        <Select
          id="month-select"
          mode="multiple"
          allowClear={true}
          placeholder="Please select month"
          value={weatherEmailSetting.months}
          options={MONTH_OPTIONS}
          onChange={handleChangeMonths}
        />
      </Form>
    </WeatherEmailSettingWrapper>
  );
};

export default WeatherEmailSetting;