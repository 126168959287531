export const storageTypes = [
  {
    label: 'Shed',
    value: 'shed',
  },
  {
    label: 'Silo',
    value: 'silo',
  },
  {
    label: 'Paddock',
    value: 'paddock',
  },
  {
    label: 'Other',
    value: 'other',
  }
]