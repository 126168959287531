import React,{useEffect} from "react";
import { Card, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import "./Dashboard.style.css";
import WeatherMap from "../WeatherMap/WeatherMap";
import PropertyMap from "../PropertyMap/PropertyMap";
import PropertyArea from "../PropertyArea/PropertyArea";
import Visitor from "../Visitor/Visitor";
import Form from "../Form/Form";
import { useSelector } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import VisitorModal from "../Visitor/VisitorModal";
import { visitorService } from "../../../services/visitor.service";
import FormModal from "../Form/FormModal";
import { useHistory } from "react-router-dom";
import { formService } from '../../../services/form.service';
import { assetService } from '../../../services/asset.service';
import { healthsafety } from '@iso/services';
import appActions from '@iso/redux/app/actions';
import Hazards from '../Hazards/Hazards';
import HazardsModal from '../Hazards/HazardsModal'

import WeatherForm from "../Weather/WeatherForm";
import {
  propertyService,
  dashboardService
} from "@iso/services";
import UserAccessModal from "../Property/UserAccessModal";
const { changeCurrent } = appActions;
const { openModal } = modalActions;
const Dashboard = () => {
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
  const [visibleWeatherObswrvation,setVisibleWeatherObservation] = React.useState(false);
  const [forms, setForms] = React.useState([]);
  const [visitors, setVisitors] = React.useState(0);
  const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
  const [property, setProperty] = React.useState({});
  const [propertyAreaData, setPropertyAreaData] = React.useState(0);
  const [safetyAssetsData, setSafetyAssetsData] = React.useState(0);
  const [visitorData, setVisitorData] = React.useState([]);
  const [HazardCount,setHazardCount] = React.useState(0);
  const [formData, setFormData] = React.useState(0);
  const [activityActive,setActivityActive] = React.useState('hazard');
  const [primaryObjects,setPrimaryObjects] = React.useState([]);
  const [visibleModalActivity,setVisibleModalActivity] = React.useState(false);
  const [visibleUsermodal,setVisibleUsermodal] =React.useState(false);
  const [statistic, setStatistic] = React.useState({
    number_of_areas: 0,
    number_of_forms : 0,
    number_of_assets: 0,
  });



  const dispatch = useDispatch();
  const history = useHistory();


  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  useEffect(() => {
    if (activePropertyId) {
      dashboardService.getDashboardData(activePropertyId).then(res => {
        if(res.code === '0000'){
          setStatistic(res.data)
        }
      })
    }
  }, [activePropertyId]);
  useEffect(() => {
    if (activePropertyId) {
      propertyService.viewProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          
          setPrimaryObjects(res.primary_objects); 
        }
      });
    }
  }, [activePropertyId,HazardCount]);


  useEffect(() => {
    updateAssetData();
});

  const updateAssetData = () => {
    if (activePropertyId) {
      assetService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {  
          setSafetyAssetsData(res.facilities.length)
        }
      });
    }
  };
  
  if (HazardCount) {
    healthsafety.getActivityPolicyByProperty(activePropertyId).then(res => {
      if (res.code === '0000') {
     setHazardCount (res.activities.length)
      }
    });
  }
  const handlePropertyArea = (length) => {
    setPropertyAreaData(length)
  }

  const handleVisitorData = (length) => {
    setVisitors(length);
  }
  const handleFormData = (length) => {
    setFormData(length)
  }
  const handleHazards = (length) => {
    setHazardCount(length)
  }

  const updateVisitorData = () => {
    if (activePropertyId) {
      visitorService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setVisitorData(res.visitors);
          setVisitors(res.visitors.length);
        }
      });
    }
  };

  const addArea = () => {
    history.push('property-map');
    dispatch(changeCurrent(['property-map']));
  };
  const showAddAssetModal = () => {
    const modalData = {
      type: "asset",
      canCreate: true,
    };
    dispatch(openModal(modalData));
  };

  const addForms = () => {
    setVisiblityFormModal(true);
  }
  const updateFormData = () => {
    if (activePropertyId) {
      formService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setForms(res.forms);
          setFormData(res.forms.length)
        }
      });
    }
  };
const addObservation = () => {
  setVisibleWeatherObservation(true)
}
  const addHazard = () =>{
    setVisibleModalActivity(true)
    // history.push('hazards');
    // dispatch(changeCurrent(['hazards']));
  }
  const cancelFormModal = () => {
    setVisiblityFormModal(false);
  };
  const addVisitors = () => {
    setVisibleVisitorModal(true)
  }
  const cancelModal = () => {
    setVisibleVisitorModal(false);
    setVisitorData({});
  };

  const userModal = () =>{
    setVisibleUsermodal(true);
  }
  const categoryCard = [
    {
      id: 3,
      name: "MANAGE USERS",
      buttonTxt: "USERS",
      addFunctionCard: userModal
    },
    // {
    //   id: 4,
    //   name: "MANAGE FORM",
    //   buttonTxt: "FORMS",
    //   addFunctionCard: addForms
    // },
    {
      id: 5,
      name: "RECORD WEATHER",
      buttonTxt: "OBSERVATION",
      addFunctionCard: addObservation
    },
    {
      id: 6,
      name: "PLD WARNINGS",
      buttonTxt: "ALERTS",
      addFunctionCard: ''
    },
  ];
  
  const dashboardUpperCard = [
    {
      id: 1,
      name: "AREAS",
      count: statistic.number_of_areas,
      add: addArea
    },
    {
      id: 2,
      name: "VISITORS",
      count: '',
      add: addVisitors
    },
    {
      id: 3,
      name: "ASSETS",
      count: statistic.number_of_assets,
      add: showAddAssetModal
    },
    {
      id: 4,
      name: "FORMS",
      count: statistic.number_of_forms,
      add: addForms
    },
    {
      id: 5,
      name: "HAZARDS",
      count: '',
      add: addHazard
    },
    {
      id: 6,
      name: "NEAR MISS",
      count: 0,
      add: '',
    },
  ];
  
 
  return (
    <>
      <div className="manage-property-main">
        <div className="manage-property-cards">
    
          {dashboardUpperCard?.map((item) => (
            <Card className="dashbord-box" key={item.id}>
              <div className="catBtn" >
              <img src={process.env.PUBLIC_URL + "./images/icons/UpperIcon.svg"} height ='24px' width ='24px' className="arrowImg" alt="sdfsdf"></img>
                {item.icon}
                <h4 className="upperCatName">{item.name}</h4>
              </div>
              <div className="count-btn">
              <h2>{item.count}</h2>
                <button  
                 onClick={item?.add}
                 
                 >+</button>
              </div>
            </Card>
          ))}
           
        </div>
        <div>
          <Row gutter={16} >
            <Col xs={24} sm={24} md={24} lg={12}  >
              <Row style={{ borderRadius: '20px' }} className="Weathers">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className="weather-map-style">
                  <WeatherMap className='weathermap'/>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12}  className="propertyMap">
              <PropertyMap  className="weather-map-style"/>
            </Col>
          </Row>
        </div>
      </div>

      <VisitorModal
        visibleVisitorModal={visibleVisitorModal}
        cancelModal={cancelModal}
        propertyId={activePropertyId}
        editedVisitor={visitorData}
        updateVisitorData={updateVisitorData} 
      />
      <FormModal
        visibleFormModal={visibleFormModal}
        cancelModal={cancelFormModal}
        propertyId={activePropertyId}
        updateFormData={updateFormData}
        formData={formData}
      />
      {visibleWeatherObswrvation ?
    <WeatherForm
    primaryObjects ={primaryObjects}
    setVisibleWeatherObservation = {setVisibleWeatherObservation}
    visibleWeatherObswrvation = {visibleWeatherObswrvation}
    /> :''}
      
        <UserAccessModal
        visibleUsermodal ={visibleUsermodal}
        setVisibleUsermodal = {setVisibleUsermodal}
        />

      {visibleModalActivity ?
      <HazardsModal
      setVisibleModalActivity ={setVisibleModalActivity}
      visibleModalActivity={visibleModalActivity} 
      propertyId={activePropertyId} 
      activityActive={activityActive}
      />:''}
    </>
  );
};

export default Dashboard;
