export const activityCategories = [
  {
    label: 'Treatment',
    value: 'treatment',
  },
  {
    label: 'Feeding',
    value: 'feeding',
  },
  {
    label: 'Joining',
    value: 'joining',
  },
  {
    label: 'Marking',
    value: 'marking',
  },
  {
    label: 'Weighing',
    value: 'weighing',
  },
  {
    label: 'Preg Testing',
    value: 'preg_testing',
  },
  {
    label: 'Crutching',
    value: 'crutching',
  },
  {
    label: 'Death',
    value: 'death',
  },
  {
    label: 'Sale',
    value: 'sale',
  },
  {
    label: 'Other',
    value: 'other',
  },

]