export const physiologicalStateCows    = [
  {
    label: 'Dry / Early Preg',
    value: 'dryEarlyPreg',
  },
  {
    label: 'Late Preg (Last 3 Months)',
    value: 'latePreg',
  },
  {
    label: 'Early Lactation (2 Months)',
    value: 'earlyLactation'
  },
  {
    label: 'Late Lactation (150kg Calf)',
    value: 'lateLactation'
  },  

]