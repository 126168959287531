export const physiologicalStateEwes    = [
  {
    label: 'Dry',
    value: 'dry',
  },
  {
    label: 'Pregnant (50 Days)',
    value: 'pregnant50',
  }, 
  {
    label: 'Pregnant (70 Days)',
    value: 'pregnant70',
  },  
  {
    label: 'Pregnant (100 Days)',
    value: 'pregnant100',
  },  {
    label: 'Pregnant (130 Days)',
    value: 'pregnant130',
  },
  {
    label: 'Lactating (10 Days)',
    value: 'lactating10'
  }, 
  {
    label: 'Lactating (30 Days)',
    value: 'lactating30'
  }, 
  {
    label: 'Lactating (50 Days)',
    value: 'lactating50'
  },
 
]