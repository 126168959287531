//React
import React, { useState, useEffect } from 'react';
import { SearchPropType } from '../utils/propTypes'
//Components
import { getIcon } from '../assets/icons/Icon';

const Search = ({ searchString, searchRows, clearSearch, showSearchIcon, showClearIcon }) => {
    const [value, setValue] = useState(searchString);

    const handleOnChange = (event) => {
        searchString = event.target.value;
        setValue(event.target.value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => handleSearchRows(), 1000);
        return () => clearTimeout(timeoutId);
    }, [value]);

    const handleSearchRows = () => {
        searchRows({target: {value}})
    };

    const handleClearSearch = () => {
        setValue("");
        clearSearch();

        searchString = "";
    };

    const searchIcon = getIcon({ name: "search", size: 22 });
    const clearIcon = getIcon({ name: "clear", size: 22 });
    return (
        <div className="react-collapsible-search">
            <div className="search-input">
              <input onChange={ handleOnChange } value={ value } placeholder="Search Here..."/>
              { showSearchIcon && searchIcon }
              { showClearIcon && <button className="react-collapsible-clear" onClick={ handleClearSearch }>{ clearIcon }</button> }
            </div>
        </div>
    );
};

Search.propTypes = SearchPropType;

export default Search
