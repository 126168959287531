import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const InitialPropertyWrapper = styled.div`
  .text-title {
    line-height: normal;
    margin-bottom: 24px;
  }
  .text-noti {
    margin-bottom: 24px;
    font-size: 16px;
  }
  .text-footer {
    font-size: 16px;
  }
  .right-section {
    position: absolute;
    top: 30%;
    left: 6%;
    @media only screen and (max-width: 992px) {
      top: 6%;
      left: 18%;
    }
   
    @media only screen and (max-width: 767px) {
      top: 4%;
      left: 0%;
    }
    .group-card-1{
      border: 1px solid #b0d3ff;
      background: #e3efff;
      .quantity{
        color: #1877F2;
      }
    }
    .group-card-2{
      border: 1px solid #FFC4C0;
      background: #FFE3E1;
      .quantity{
        color: #D20E00;
      }
    }
    .group-card-3{
      border: 1px solid #C2FFB5;
      background: #E5FFDF;
      .quantity{
        color: #20B600;
      }
    }
    .group-card-4{
      border: 1px solid #ECC5FF;
      background: #F5E0FF;
      .quantity{
        color: #B71FFF;
      }
    }
    .group-two{
      display: flex;
      flex-wrap: wrap;
    }
    .group-card {
      border-radius: 10px;
      max-width: 200px;
      min-width: 100px;
      padding: 27px 24px 24px 24px;
      margin: 10px 7px;
      @media only screen and (max-width: 992px) {
        width: 185px;
        padding: 11px 20px 9px 15px;
      }
      @media only screen and (max-width: 992px) {
        width: 185px;
        padding: 11px 20px 9px 15px;
      }
      @media only screen and (max-width: 425px) {
        
        min-width: 95% !important;
      }
      @media only screen and (max-width: 1024px) {
        max-width: 200px;
      min-width: 100px;
        padding: 11px 20px 3px 11px;
        margin: 10px 4px;
      }
      .quantity{
        font-size: 32px;
        font-weight: 700;
        margin-top: 29px;
        @media only screen and (max-width: 992px) {
          margin-top: 15px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 25px;
          margin-top: 3px;
        }
      }
      .headers {
        display: inline-flex;
        img {
          margin-right: 12px;
        }
        p {
          font-size: 24px;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .hidden {
    display: none;
  }
  @media only screen and (max-width: 425px) {
  .rowCust{
    display: block ;
  }
  }
`;

export default WithDirection(InitialPropertyWrapper);
