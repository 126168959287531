export const pastureQuality  = [
  {
    label: '40% dig, 4.8 MJ ME/kg - Dry (Dead)',
    value: '40',
  },
  {
    label: '50% dig, 6.5 MJ ME/kg - Dry, perennial',
    value: '50',
  },
  {
    label: '60% dig, 8.2 MJ ME/kg  - Gone to seed',
    value: '60',
  },
  {
    label: '70% dig, 10 MJ ME/kg - Green, grassy',
    value: '70',
  },
  {
    label: '75% dig, 10.8 MJ ME/kg - Green, 15-30% clover',
    value: '75',
  },  
  {
    label: '80% dig - 11.6 MJ ME/kg - Green, 60% clover',
    value: '80',
  }
]