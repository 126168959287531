import React from "react";
import InvitationImage from "@iso/assets/images/welcomeModals/invitation-image.png";

const RightSection = () => {
  return (
    <div className="right-section">
      <img className="" src={InvitationImage} />
    </div>
  );
};

export default RightSection;
