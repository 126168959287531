import React from "react";
import GetReadyImage from "@iso/assets/images/welcomeModals/get-ready.png";

const RightSection = () => {
  return (
    <div className="right-section">
      <img className="" src={GetReadyImage} />
    </div>
  );
};

export default RightSection;
