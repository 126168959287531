export const machineryLicence = [
  {
    label: 'Excavator',
    value: 'excavator',
  },
  {
    label: 'Skid Steer',
    value: 'skid_steer',
  },
  {
    label: 'Backhoe',
    value: 'backhoe',
  },
  {
    label: 'BullDozer',
    value: 'bullDozer',
  },
  {
    label: 'Front End Loader',
    value: 'front_end_loader',
  },
  {
    label: 'Roller',
    value: 'roller',
  },
  {
    label: 'Bob Cat',
    value: 'bob_cat',
  },
  {
    label: 'Fork Lift',
    value: 'fork_lift',
  }
]