import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const livestockService = {
  getList,
  getAll,
  storeOrUpdate,
  destroy,
  getByMob,
  checkExistsByMob,
  checkUniqueID,
  getDataForBubbleChart,
  getAllAssetByProperty,
  archiveAsset
};

function getList(propertyId, page){
  const params = {
    'property_id': propertyId,
    'page': page,
  };

  return getAll(params);
}

function getAll(params = {}){
  return (
    axios
      .get(
        apiEndpoint.assets,
        {
          params: params,
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getByMob(mobId){
  return (
    axios
      .get(
        `${apiEndpoint.assets}/get-assets-by-mob?mob_id=${mobId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function checkExistsByMob(mobId){
  return (
    axios
      .get(
        `${apiEndpoint.assets}/check-asset-exists-by-mob?mob_id=${mobId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.assets(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.assets,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.assets(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function checkUniqueID(unique_id){
  return (
    axios
      .post(
        `${apiEndpoint.assets}/verify-unique-identification`,
        unique_id,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }        
      })
  );
}

function getDataForBubbleChart(property_id){
  return (
    axios
      .get(
        `${apiEndpoint.getDataForBubbleChart}`,
        {
          headers: authHeader(),
          params: {
            property_id,
          }
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getAllAssetByProperty(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.getAllAssetsByProperty}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function archiveAsset(data){
  return (
    axios
      .post(
        apiEndpoint.archiveAsset,
        data,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}