import styled from "styled-components";
import WithDirection from "@iso/lib/helpers/rtl";

const SubscriptionFlowWrapper = styled.div`
  .text-title {
    line-height: normal;
    margin-bottom: 32px;
  }
  .flow-col {
    margin-top: 54px;
  }
  #flow-1 {
    width: 100%;
    height: 30.57px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    background: #d7ebd2;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    margin-bottom: 54.11px;
  }
  .flow-container {
    padding: 10px;
  }
  .flow-title {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .flow-content {
    font-size: 18px;
    text-align: center;
  }
  .icon-outside {
    width: 100%;
    margin-bottom: 54.11px;
    height: 30.57px;
    background: #d7ebd2;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
  }
  .icon-inside {
    width: 55.582px;
    height: 55.582px;
    background: rgb(248, 14, 70);
    border-radius: 30px;
    padding: 16px;
  }
  .free-trail-link {
    color:rgb(248, 14, 70);
    font-size: 18px;
  }
  .submit-btn {
    background-color: rgb(248, 14, 70);
    width: 399px;
    height: 48px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 16px;
  }
  .footer {
    text-align: center;
    margin-top: 170px;
  }
`;

export default WithDirection(SubscriptionFlowWrapper);
