export const fuelTypes = [
  {
    label: 'Diesel',
    value: 'diesel',
  },
  {
    label: 'Petrol',
    value: 'petrol',
  },
  {
    label: 'AVGAS',
    value: 'avgas'
  },
  {
    label: 'JET',
    value: 'jet'
  },
  {
    label: 'Other',
    value: 'other'
  }
]