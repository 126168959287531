export const surveyQuestionTypes = [
  {
    label: 'Yes/No',
    value: 'yes/no',
  },
  {
    label: 'Scale',
    value: 'scale',
  },
  {
    label: 'Free Text',
    value: 'text',
  }
]