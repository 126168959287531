import React, { useState, useEffect, useRef,useMemo } from 'react';
import { EditControl } from "react-leaflet-draw";
import { v4 as uuidv4 } from 'uuid';
import AppLocale from '@iso/config/translation';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Menu ,Modal} from 'antd';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, Polyline, Circle, Rectangle,Marker,Popup } from 'react-leaflet';
import { layerObjectService,layerService } from '@iso/services';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Control from '@skyeer/react-leaflet-custom-control';
import _ from 'lodash';
import { IntlProvider } from 'react-intl';
import { objectColors } from '@iso/constants/objectColors';
import { PlusOutlined } from '@ant-design/icons';
import IntlMessages from '@iso/components/utility/intlMessages';
import ColorModal from './ColorModal';
import TextModal from './TextModal';
import EditTextModal from './EditTextModal';
import modalActions from '@iso/redux/modal/actions';
import L from 'leaflet';
import styled from "styled-components";
import "./styleLayer.css";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { openModal } = modalActions;
const colorDefault = '#F85E2B';

const DrawTool = (props) => {
    const objectRef = React.useRef([]);
    const controlDraw = useRef(null);
    const mapRef = useRef();
    const dispatch = useDispatch();
    const featureGroupRef = useRef();
    const activePermission = useSelector((state) => state.permission.activePermission);
    const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
    const activePropertyTrialSatelliteExpired = useSelector((state) => state.property_trial_satellite.activePropertyTrialSatelliteExpired);
    const { locale } = useSelector(state => state.LanguageSwitcher.language);
    const [showSatellite, setShowSatellite] = useState(true);
    const [objectsOfLayer, setObjectsOfLayer] = useState([]);
    const [updateObjects, setUpdateObjects] = useState([]);
    const [deleteObjects, setDeleteObjects] = useState([]);
    const currentAppLocale = AppLocale[locale];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [pointText, setPointText] = useState([]);
    const [typeText, setTypeText] = useState([]);
    const [dataText, setDataText] = useState({});
    const [draggable, setDraggable] = useState(false);
    const [isVisibleDeleteLayer, setIsVisibleDeleteLayer] = useState(false);

    const onCreatedObjectLayer = async drawControl => {
        // await props.setDisabledArea(true);
        var type = drawControl.layerType;
        var layer = drawControl.layer;

        switch (type) {
            case 'circle':
                var dataCircle = handleCircle(layer, type);
                onStoreLayerObject(dataCircle);
                break;
            case 'polygon':
            case 'rectangle':
            case 'polyline':
                var data = handlePolygonPolyline(layer, type);
                onStoreLayerObject(data);
                break;
            case 'marker':
              setPointText(layer.getLatLng());
              setTypeText(type);
              setIsModalVisible(true);
              break;
            default:
                break;
        }
        layer.remove();
    }

    useEffect(() => {
      L.drawLocal.draw.handlers.marker.tooltip.start = 'Click map to place text';
    },[props.layerId]);

    const cancelModal =()=>{
      setIsModalVisible(false);
    }    
    const cancelEditModal =()=>{
      setIsEditModalVisible(false);
    }

    // include polygon, rectangle, polyline (more than or equal to 2 points)
    const handlePolygonPolyline = (shape, type, color) => {
        var points = shape.getLatLngs();

        var arrPointsTmp = [];

        if (type === 'polyline') {
            for (var point of points) {
                arrPointsTmp.push(Object.values({lat:point.lat,lng:point.lng}));
            }
        } else {
            for (var point of points[0]) {
                arrPointsTmp.push(Object.values({lat:point.lat,lng:point.lng}));
            }
        }

        var arrPoints = _.map(arrPointsTmp, (p) => {
            return p.toString().replace(',', ', ');
        });

        const data = {
            // shape,
            type,
            color,
            object: {
                coordinates: arrPoints,
            }
        }
        return data;
    }

    // only circle (one point)
    const handleCircle = (circle,type,color) => {
        var centerPoint = circle.getLatLng();
        var radius = circle.getRadius();
        const data = {
            // shape: circle,
            type,
            color,
            object: {
                centerPoint,
                radius
            }
        }
        return data;

    }

    // when click save after edit
    const onEditedObjectLayer = (e) => {
        setDraggable(true);
        // console.log(mapRef.current.leafletElement);
        _.forEach(updateObjects, function (objectData, objectId) {
            if (typeof objectData !== 'undefined') {
                const data = {
                    layer_object: objectData
                }
                layerObjectService.storeOrUpdate(data, objectId).then(res => {
                  if (res.code === '0000') {
                    // getDataLayer();
                    // console.log(e.layers);
                    
                  }
              });

            }
        });

        setUpdateObjects([]);
    }
    const getDataLayer = () => {
       if (props.propertyId && props.layerId) {
              layerObjectService.getList(props.layerId).then(res => {
                  if (res.code === '0000') {
                      setObjectsOfLayer(res.layer_objects);
                  }
              });
          }
    }

    // when click save after delete
    const onDeletedObjectLayer = (e) => {
        _.forEach(deleteObjects, function (objectId) {
            layerObjectService.destroy(objectId);
        });
        setDeleteObjects([]);
    }

    // store
    const onStoreLayerObject = (object_values) => {
        const object_data = {
            layer_object: {
                ...object_values,
                property_id: props.propertyId,
                layer_id: props.layerId
            }
        }

        layerObjectService.storeOrUpdate(object_data).then(res => {
            if (res.code === '0000') {
              // handleShowLayerObjects(res.layer_object);
              getDataLayer();
            }
        });
    }

    const handleShowPrimaryObjects = (objects) => {
        var html = [];
        if (objects.length > 0) {
            _.forEach(objects, function (object) {
                const colorIndex = _.findIndex(objectColors, (color) => {
                    return color.value === object.color
                });

                html.push(
                    <Polygon key={object.id}
                        positions={object.area}
                        color={objectColors[colorIndex].color}
                        // onEdit={false}
                        className='primary-objects'
                        ref={el => (objectRef.current[object.id] = el)}
                    >
                        <Tooltip permanent={true} open={true} direction="center" className='primary-objects-tooltip'>{object.name}</Tooltip>
                    </Polygon>
                );
            });
        }
        return html;
    }
    const handleClickText = (object, e) => {
      if(!controlDraw.current.leafletElement._toolbars.edit._modes.remove || !controlDraw.current.leafletElement._toolbars.edit._modes.remove.handler._enabled){
        setIsEditModalVisible(true);
        setDataText(object);
      }
    }

    const handleShowLayerObjects = (objects) => {
        var html = [];
        if (objects.length > 0) {
            _.forEach(objects, function (object) {
                const colorIndex = _.findIndex(objectColors, (color) => {
                    return color.value === object.color
                });
                switch (object.type) {
                    case 'polygon':
                        html.push(
                            <Polygon key={object.id}
                                positions={object.polygon}
                                color={object.color ? object.color : colorDefault}
                                onClick={handleClickObject.bind(this, object)}
                                onRemove={handleRemoveObject.bind(this, object)}
                                onEdit={handleEditObject.bind(this, object)}
                                ref={el => (objectRef.current[object.id] = el)}
                            />
                        );
                        break;
                    case 'rectangle':
                        html.push(
                            <Rectangle key={object.id}
                                bounds={object.polygon}
                                color={object.color ? object.color : colorDefault}
                                onClick={handleClickObject.bind(this, object)}
                                onRemove={handleRemoveObject.bind(this, object)}
                                onEdit={handleEditObject.bind(this, object)}
                                ref={el => (objectRef.current[object.id] = el)}
                            />
                        );
                        break;
                    case 'polyline':
                        html.push(
                            <Polyline key={object.id}
                                positions={object.polyline}
                                color={object.color ? object.color : colorDefault}
                                onClick={handleClickObject.bind(this, object)}
                                onRemove={handleRemoveObject.bind(this, object)}
                                onEdit={handleEditObject.bind(this, object)}
                                ref={el => (objectRef.current[object.id] = el)}
                            />
                        );
                        break;
                    case 'circle':
                        html.push(
                            <Circle key={object.id}
                                center={[object.circle_lat, object.circle_lng]}
                                radius={object.circle_radius}
                                onClick={handleClickObject.bind(this, object)}
                                onRemove={handleRemoveObject.bind(this, object)}
                                onEdit={handleEditObject.bind(this, object)}
                                color={object.color ? object.color : colorDefault}
                                ref={el => (objectRef.current[object.id] = el)}
                            />
                        );
                        break;
                    case 'marker':
                      const color = object.color ? object.color : '#3388ff';
                      const text = L.divIcon({html: '<div style="color:'+color+';font-size:16px; width: 150px;text-shadow: 0 0 1px #00000038;">'+object.text+'</div>',iconSize: [0,0],iconAnchor: [0, 0]});
                      html.push(
                          <Marker 
                            draggable={draggable}
                            ondragend={onDragEnd.bind(this, object)}
                            onClick={handleClickText.bind(this, object)}
                            onRemove={handleRemoveObject.bind(this, object)}
                            position={[object.circle_lat, object.circle_lng]} 
                            icon={text} 
                          /> 
                      )
                      break;
                    default:
                        break;

                }


            });
        }
        return html;
    }
    const onDragEnd = (object,e) => {
      var centerPoint = e.target.getLatLng();
      const data = {
          layer_object:{
            type: object.type,
            color:object.color,
            object:{
              centerPoint: centerPoint,
              text: object.text,
            }
          }
      }
      layerObjectService.storeOrUpdate(data, object.id).then(res => {
        if (res.code === '0000') {
          getDataLayer();
        }
      });
    }
    
    const handleClickObject = (object, e) => {
        var type = 'changeColor';
        const modalData = {
            type,
            target: e.target,
            old_color: object.color,
            layer_object_id: object.id
        }
        if(!controlDraw.current.leafletElement._toolbars.edit._modes.remove || !controlDraw.current.leafletElement._toolbars.edit._modes.remove.handler._enabled){
          dispatch(openModal(modalData));
        }
    } 

    const handleEditObject = (object, e) => {
        if (object.type === 'circle') {
            var data = handleCircle(e.target,object.type,object.color);
        } else {
            var data = handlePolygonPolyline(e.target, object.type,object.color);
        }
        updateObjects[object.id] = {
            type: object.type,
            color: object.color,
            object: data.object
        }
        setUpdateObjects(updateObjects);
    }

    const handleRemoveObject = (object, e) => {
        deleteObjects.push(object.id);
        setDeleteObjects(deleteObjects);
    }
    const handleOkLayer = () =>{
      layerService.destroy(props.layerId).then(res =>{
        props.getListLayer();
        props.setIsEditingLayer(false);
        props.setLayerChosen(0);
        L.drawLocal.draw.handlers.marker.tooltip.start = 'Click map to place map';
      });
    }
    const handleCancelLayer = () => {
      setIsVisibleDeleteLayer(false);
    }
    const handleMenuClick = async (e) => {
        if (e.key === 'returnMap') {
            props.setIsEditingLayer(false);
            props.setLayerChosen(0);
            L.drawLocal.draw.handlers.marker.tooltip.start = 'Click map to place map';
        } else if (e.key === 'deleteLayer') {
          setIsVisibleDeleteLayer(true); 
        }

    }

    const menuLayerAction = () => {
        return (
            <Menu onClick={handleMenuClick.bind(this)} style={{ width: '150px' }}>
                <Menu.Item key="deleteLayer">
                    {/* <IntlMessages id="propertyMapPage.area" /> */}
                    Delete Layer
                </Menu.Item>
                <Menu.Item key="returnMap">
                    {/* <IntlMessages id="propertyMapPage.task" /> */}
                    Return Map
                </Menu.Item>


            </Menu>
        )
    }

    // load objects of layer
    useEffect(() => {
        getDataLayer();
    }, [props.layerId])

    return (
       <LayerWrap className="layerWrap" hidden_primary_type_ids={props.notSelectedPrimaryObjectTypeIds}>
        <React.Fragment>
            <Map ref={mapRef} center={props.center} zoom={18} zoomControl={false} tap={true}>
                {((subscriptionHelper.checkIsSilver(activePropertySubscription) || subscriptionHelper.checkIsGold(activePropertySubscription)
                    || ((subscriptionHelper.checkIsBronze(activePropertySubscription) || activePropertySubscription === false) && activePropertyTrialSatelliteExpired === false))
                    && showSatellite) ?
                    <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false} /> :
                    <TileLayer
                        url={props.darkTheme ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />

                }
                <ZoomControl position="bottomleft" zoomInText="+" zoomOutText="-" />
               
                <FeatureGroup ref={featureGroupRef}>
                    <EditControl
                        position='topright'
                        onCreated={onCreatedObjectLayer}
                        onEdited={onEditedObjectLayer}
                        onDeleted={onDeletedObjectLayer}
                        draw={{
                            marker: {
                              icon: L.divIcon({html: '<div style="opacity:0; display:none; border: 0; background: transparent;"></div>',iconAnchor: [0, 0],iconSize: [0, 0]})
                            },
                            polyline: true,
                            circle: true,
                            circlemarker: false,
                            rectangle: true,
                            polygon: ((activePermission == "owner" || activePermission == "admin") ? {
                                allowIntersection: false,
                                showArea: (activePermission == "owner" || activePermission == "admin"),
                            } : false)
                        }}
                        edit={{
                            remove: (activePermission == "owner" || activePermission == "admin"),
                            edit: (activePermission == "owner" || activePermission == "admin" || activePermission == "modify"),
                        }}
                        ref={controlDraw}
                        key={uuidv4()}
                  
                    />

                    {handleShowPrimaryObjects(props.primaryObject)}
                    {handleShowLayerObjects(objectsOfLayer)}
                  
                    <Control position="bottomright">
                        <div className="rightComponent">
                            <IntlProvider
                                locale={currentAppLocale.locale}
                                messages={currentAppLocale.messages}
                            >

                                <Dropdown overlay={menuLayerAction()} trigger={['click']}>
                                    <Button icon={<PlusOutlined />} type="primary" className="btn-success">
                                        {/* <IntlMessages id="propertyMapPage.add" />  */}
                                        Actions
                                    </Button>
                                </Dropdown>
                            </IntlProvider>
                        </div>
                    </Control>
                </FeatureGroup>

            </Map>
            <ColorModal />
            <EditTextModal 
              isEditModalVisible={isEditModalVisible} 
              cancelEditModal={cancelEditModal} 
              getDataLayer={getDataLayer}
              dataText={dataText}
            />
            <TextModal 
              isModalVisible={isModalVisible} 
              cancelModal={cancelModal} 
              pointText={pointText} 
              typeText={typeText} 
              layerId={props.layerId} 
              getDataLayer={getDataLayer}
              />
              <Modal className="deleteLayer" 
                title="Delete Layer" 
                open={isVisibleDeleteLayer} 
                onOk={handleOkLayer} 
                onCancel={handleCancelLayer}
                footer={[
                <Button key="back" onClick={handleCancelLayer} className="cancel-button"> 
                  Cancel
                </Button>,
                <Button key="submit" onClick={handleOkLayer} className="btn-success" type="primary">
                  Submit
                </Button>
              ]}
              >
                <p>Do you want to delete this layer?</p>
              </Modal>
        </React.Fragment> 
      </LayerWrap> 

    )

}

export default DrawTool;
const LayerWrap = styled.div`  
`;