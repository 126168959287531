import React,{useEffect,useState} from 'react';
import { Row, Col } from 'antd';
import TracfficLightWrapper from './TracfficLight.styles';

const TrafficLight = (props) => {

  return (
    <TracfficLightWrapper className="traffic-light-wrap">
      <div className="border-box">
        <div className="border-box-inner">
        <Row gutter={10}>
          <Col xs={{span: 8}} md={{span: 8}}>
            <div className={"circle-light-color "+(props.color === 'red' ? 'red' : '')}></div>
          </Col> 
          <Col xs={{span: 8}} md={{span: 8}}>
            <div className={"circle-light-color "+(props.color === 'yellow' ? 'yellow' : '')}></div>
          </Col>  
          <Col xs={{span: 8}} md={{span: 8}}>
            <div className={"circle-light-color "+(props.color === 'green' ? 'green' : '')}></div>
          </Col>  
        </Row>
        </div>
      </div>
    </TracfficLightWrapper>
  );
}

export default TrafficLight;
