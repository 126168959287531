import L from 'leaflet';
import task from './AssetIcons/airagri_task.png';

export const IconTask = () => {
  var icon = new L.Icon({
    iconUrl: task,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [44, 60],
    iconAnchor: [22, 60],
    className: 'custom-icon-marker task-marker'
  });

  return icon;
};