import React from 'react';
import { HeatBarWrapper } from './heatBar.styles';

export default function (props) {
  const heatBarData = [
    {
      "label": "50 +",
      "color": "#CC0000"
    },
    {
      "label": "40 - 49",
      "color": "#E06666"
    },
    {
      "label": "30 - 39",
      "color": "#EA9999"
    },
    {
      "label": "20 - 29",
      "color": "#F6B26A"
    },
    {
      "label": "10 - 19",
      "color": "#F9CA9C"
    },
    {
      "label": "0 - 9",
      "color": "#FFE599"
    },
    {
      "label": "0 - 9",
      "color": "#FFF2CC"
    },
    {
      "label": "10 - 19",
      "color": "#D9EAD3"
    },
    {
      "label": "20 - 29",
      "color": "#B6D7A7"
    },
    {
      "label": "30 - 39",
      "color": "#92C47C"
    },
    {
      "label": "40 - 49",
      "color": "#69A84F"
    },
    {
      "label": "50 +",
      "color": "#33A854"
    }
  ]

  return (
    <HeatBarWrapper>
      <p className="heat-label">Days In Use</p>
      {
        heatBarData.map((item, index) => {
          if(index < 6){
            return (
              <div className="heat-item">
                <div className="color-rec" style={{backgroundColor: `${item.color}`}}></div>
                <span>{item.label}</span>
              </div>
            )
          }
        })
      }
      <p className="heat-label mt-1">Days Rest</p>
      {
        heatBarData.map((item, index) => {
          if(index >= 6){
            return (
              <div className="heat-item">
                <div className="color-rec" style={{backgroundColor: `${item.color}`}}></div>
                <span>{item.label}</span>
              </div>
            )
          }
        })
      }
    </HeatBarWrapper>
  );
}
