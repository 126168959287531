import React, { useState, useEffect, useCallback } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import TaskModalWrapper from "./TaskModal.styles";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Upload,
  Switch,
  InputNumber,
} from "antd";
import _, { values } from "lodash";
import { taskPriorities } from "@iso/constants/taskPriorities";
import { taskRepeatTypes } from "@iso/constants/taskRepeatTypes";
import { taskStatus } from "@iso/constants/taskStatus";
import Loader from "@iso/components/utility/loader";
import { propertyService, taskService } from "@iso/services";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { Option } = Select;
const { closeModal } = modalActions;

const TaskModal = (props) => {
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [modalLoading, setModalLoading] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const [assignUser,setAssignUser] = useState({});
  const [dataTask, setDataTask] = useState({});
  const updateDataAssignee = useCallback(() => {
    propertyService.getListUsersOfProperty(props.propertyId).then((res) => {
      if (res.code === "0000") {
        setAssignees(res.users);
        setModalLoading(false);
      }
    });
  }, [props]);

  const [imageId, setImageId] = useState(null);

  const handleChangeTag = (input_tags) => {
    setTags(input_tags);
  };

  useEffect(() => {
    handleChangeTag(tags);
  }, [tags]);

  useEffect(() => {
    if (modalData.type === "task") {
     
     let  options=[];
        _.forEach(modalData?.task?.assignees,(status, index) => {
          options.push(status?.user?.email
          );
        })

       
      if (modalData.task) {
        setDataTask(modalData.task);
        
        if (modalData.task.tags) {
          let task_tags = modalData.task.tags.split("|");
          setTags(task_tags);
        }
      }
     
      setFields([
        {
          name: ["task", "title"],
          value: modalData.task.title || "",
        },
        {
          name: ["task", "due_date"],
          value: modalData.task.due_date ? moment(modalData.task.due_date) : "",
        },
        {
          name: ["task", "start_date"],
          value: modalData.task.start_date
            ? moment(modalData.task.start_date)
            : "",
        },
        {
          name: ["task", "priority"],
          value: modalData.task.priority || "medium",
        },
        {
          name: ["task", "status"],
          value: modalData.task.status || "todo",
        },
        {
          name: ["task", "assignee_id"],
          value: modalData.task.assignee ? modalData.task.assignee.id : 0,
        },
        {
          name: ["task", "assignees"],
          value: modalData.task.assignees ?modalData.task.assignees.map((item)=>item.user_id) : [],
        },
        {
          name: ["task", "details"],
          value: modalData.task.details || "",
        },
        {
          name: ["task", "primary_object_id"],
          value: modalData.task.primary_object_id
            ? modalData.task.primary_object_id
            : 0,
        },
        {
          name: ["task", "is_adhoch"],
          value: modalData.task.is_adhoch === 1 ? true : false,
        },
        {
          name: ["task", "days_before_task"],
          value: modalData.task.days_before_task,
        },
        {
          name: ["task", "repeat_frequency"],
          value: modalData.task.repeat_frequency,
        },
        {
          name: ["task", "duration"],
          value: modalData.task.duration,
        },
      ]);
      if (props.propertyId && modalVisibility) {
        updateDataAssignee();
      }
    
      setImageId(null);
      setAssignUser(modalData.task ? modalData.task.assignees.map((item)=>item.user_id) : []);
    }

  }, [props.propertyId, updateDataAssignee, modalVisibility, modalData]);
 
  const cancelModal = () => {
    dispatch(closeModal());
    setTags([]);
    if (modalData.layer) {
      modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
    }
    setLoading(false);
    form.resetFields();
  };

  const renderOptionPriority = () => {
    let options = [];
    _.forEach(taskPriorities,(taskPriority, index) => {
      options.push(
        <Option key={index} value={taskPriority.value}>
          {taskPriority.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderOptionStatus = () => {
    let options = [];
    _.forEach(taskStatus, (status, index) => {
      options.push(
        <Option key={index} value={status.value}>
          {status.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderOptionRepeatTypes = () => {
    let options = [];
    _.forEach(taskRepeatTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          {type.label}
        </Option>
      );
    });
    return <Select size="large">{options}</Select>;
  };

  const renderOptionAssignee = (users) => {
    let options = [];
    _.forEach(users, (user, index) => {
      options.push(
        <Option key={user.id} value={user.id}>
          {user.name} | {user.email}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        <Option key={0} value={0}>
          Not assigned
        </Option>
        {options}
      </Select>
    );
  };
  const AssinedUSerSelected = (value) => {

    setAssignUser(value);
    }
   
    // useEffect(() => {
    //   setAssignUser(assignUser);
    // },[assignUser])
    
   
   
      const renderOptionAssignees = (users) => {
        let options = [];
        _.forEach(users, (user, index) => {
          options.push(
            <Option key={user.id} value={user.id}>
              {user.name} | {user.email}
            </Option>
          );
        });
    return (
      <Select allowClear size="large"
      // value={ users.id} 
      
      onChange = {AssinedUSerSelected}
      mode="multiple"
      >
        <Option key={0} value={0}
        >
          Not assigned
        </Option>
        {options}
      </Select>
    );
  };

  const renderOptionObject = (objects) => {
    let options = [];
    _.forEach(objects, (object, index) => {
      options.push(
        <Option key={object.id} value={object.id}>
          {object.name}
        </Option>
      );
    });
    return (
      <Select allowClear size="large">
        <Option key={0} value={0}>
          No paddock
        </Option>
        {options}
      </Select>
    );
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        values.task.tags = tags;
        onStore(values);
      })
      .catch((info) => {
       
        setLoading(false);
      });
  };

  const onStore = (values) => {
    const data = {
      task: {
        ...values.task,
        due_date:
          values.task.due_date && values.task.due_date !== ""
            ? dateHelper.dateForAPI(values.task.due_date)
            : null,
        start_date:
          values.task.start_date && values.task.start_date !== ""
            ? dateHelper.dateForAPI(values.task.start_date)
            : null,
        point: dataTask.point,
        property_id: props.propertyId,
        is_adhoch: values.task.is_adhoch == true ? 1 : 0,
        image_id: imageId,
        assignee_ids:assignUser,
        assignees:assignees
      },
    };
  
    taskService.storeOrUpdateTasks(data, dataTask.id).then((res) => {
      if (res.code === "0000") {
        props.onSaved(props.kanbanToggle);

        form.resetFields();
        setTags([]);
        setLoading(false);
        cancelModal();
      } else {
        setLoading(false);
      }
    });
  };

  const editLocation = () => {
    cancelModal();
    props.editLocation();
  };

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    // beforeUpload: (file, fileList) => {
    //   let $oldOne = $(".anticon-delete")[0];
    //   setTimeout(() => {
    //     if ($oldOne) $($oldOne).trigger("click");
    //   }, 100);
    // },
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        setImageId(response.file.response.image.id);
      }
    },
    listType: "picture",
    multiple: false,
  };

  return (
    <TaskModalWrapper
      className="user-access-modal"
      forceRender={true}
      open={modalVisibility}
      title={
        dataTask.id ? (
          <IntlMessages id="propertyMapPage.editTask" />
        ) : (
          <IntlMessages id="propertyMapPage.addTask" />
        )
      }
      onCancel={cancelModal}
      maskClosable={true}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        (activePermission == "owner" ||
          activePermission == "admin" ||
          activePermission == "modify") && (
          <Button
            key="submit"
            className="btn-success"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>
        ),
      ]}
    >
      {modalLoading ? (
        <Loader />
      ) : (
        <Form
          form={form}
          name="addEditTask"
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          <div className="form-body">
            <Form.Item
              name={["task", "is_adhoch"]}
              className="isoInputWrapper"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="AdHoch"
                unCheckedChildren="Annual Plan"
              />
            </Form.Item>
            <Form.Item
              name={["task", "title"]}
              className="isoInputWrapper"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input size="large" placeholder="Input task title" />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return currentValues["task"];
              }}
            >
              {({ getFieldValue }) => {
                if (getFieldValue("task")) {
                  if (getFieldValue("task").is_adhoch === false) {
                    return (
                      <>
                        <Row>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name={["task", "days_before_task"]}
                              className="isoInputWrapper"
                              label="Reminder - In Days"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                size="large"
                                min={0}
                                max={100}
                                step={1}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name={["task", "repeat_frequency"]}
                              className="isoInputWrapper"
                              label="Repeat Frequency"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              {renderOptionRepeatTypes()}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          name={["task", "duration"]}
                          className="isoInputWrapper"
                          label="Duration - In Days"
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            size="large"
                            min={0}
                            max={100}
                            step={1}
                            addonAfter="days"
                          />
                        </Form.Item>
                      </>
                    );
                  }
                }
              }}
            </Form.Item>
            <Form.Item
              name={["task", "priority"]}
              label="Priority"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              {renderOptionPriority()}
            </Form.Item>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["task", "start_date"]}
                  className="isoInputWrapper"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <DatePicker size="large" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["task", "due_date"]}
                  className="isoInputWrapper"
                  label="Due Date"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <DatePicker size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name={["task", "status"]}
                  className="isoInputWrapper"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  {renderOptionStatus()}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: dataTask.id ? 11 : 24 }}>
                <Form.Item
                  name={["task", "assignee_id"]}
                  className="isoInputWrapper"
                  label="Assignee"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  {renderOptionAssignee(assignees)}
                </Form.Item>
              </Col>
              {dataTask.id && (
                <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                  <Form.Item
                    name={["task", "primary_object_id"]}
                    className="isoInputWrapper"
                    label="Area"
                  >
                    {renderOptionObject(props.primaryObjects)}
                  </Form.Item>
                </Col>
              )}
            </Row>
            
            <Form.Item
                name={["task", "assignees"]}
                className="isoInputWrapper"
                label="Assignees" 
             
              >
                {renderOptionAssignees(assignees)}
              </Form.Item>
              
            <Form.Item className="isoInputWrapper" label="Tags">
              <TagsInput
                name={["task", "tags"]}
                value={tags}
                onChange={handleChangeTag}
                addOnBlur="true"
              />
            </Form.Item>
            <Form.Item name={["task", "details"]} label="Details">
              <Input.TextArea />
            </Form.Item>
            
             { subscriptionHelper.checkIsGold(activePropertySubscription) && (
            <>
              <Form.Item name={["attachment"]} label="Attachment">
                <Upload {...uploadProps} defaultFileList={dataTask.images.full_path}>
                  <Button className="action-button" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              {dataTask.images && dataTask.images.length > 0 && (
               
                dataTask?.images?.map((image, index) => (
                  <img
                    key={index}
                    style={{ width: "50%" }}
                    src={process.env.REACT_APP_API_BASE_URL + image.path}
                    alt={`Image ${index}`}
                  />
                ))
              )}
            </>
          )}
          </div>
        </Form>
      )}
    </TaskModalWrapper>
  );
};

export default TaskModal;