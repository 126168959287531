import React from "react";
import { Row, Col } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import PurposeWrapper from "./Purpose.styles";
const isMobile = !isDesktop && !isTablet;

const Purpose = (props) => {

  return (
    <PurposeWrapper>
      {isTablet || isMobile ? (
        <Row className="rowCust">
          <Col className="image-section"  span={24}>
            <RightSection></RightSection>
          </Col>
          <Col span={24}>
            <LeftSection setPurpose={props.setPurpose} setStep={props.setStep}></LeftSection>
          </Col>
        </Row>
      ) : (
        <Row className="rowCust">
          <Col md={{ span: 24 }} lg={{ span: 11 }}>
            <LeftSection setPurpose={props.setPurpose} setStep={props.setStep}></LeftSection>
          </Col>
          <Col
            className="image-section"
            md={{ span: 24 }}
            lg={{ span: 11, offset: 2 }}
          >
            <RightSection></RightSection>
          </Col>
        </Row>
      )}
    </PurposeWrapper>
  );
};

export default Purpose;
