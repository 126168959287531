import axios from "axios";
import { apiEndpoint } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";

export const weatherEmailSettingService = {
  getByPropertyId,
  create,
};

function getByPropertyId(propertyId) {
  return axios
    .get(`${apiEndpoint.weatherEmailSetting}/get-by-property-id?property_id=${propertyId}`, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
      return [];
    });
}

function create(data) {
  return axios
    .post(apiEndpoint.weatherEmailSetting, data, {
      headers: authHeader(),
    })
    .then((res) => {
      if (res && res.status === 200) {
        return res.data;
      }
      return {};
    });
}