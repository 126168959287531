import React, { useState, useEffect } from 'react';
import PropertyAreaHistoryPage from './PropertyAreaHistory.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm, Timeline, Card, Row, Col } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { activityService, propertyService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import EditHistoryModal from '@iso/containers/Pages/PropertyMap/components/Mob/EditHistoryModal';

const PropertyAreaHistory = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const dispatch = useDispatch();
  const [propertyAreaHistoryData, setPropertyAreaHistoryData] = useState([]);
  const [visibleEditHistoryModal, setVisibleEditHistoryModal] = useState(false);
  const [historyData, setHistoryData] = useState({});

  useEffect(() => {
    setPropertyAreaHistoryData(props.propertyAreaHistory);
  }, [props.propertyAreaHistory]);

  const renderDeleteHistoryButton = (history) => {
    return (
        (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") && <Popconfirm
          placement="bottomRight"
          title="You are about to delete individual record"
          onConfirm={() => {
            confirmDelete(history);
          }}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
        <a style={{color:'red', marginRight: '20px', float:'right'}}>
          {<CloseOutlined />}
        </a>
        </Popconfirm>
      )
  }

  const renderEditHistoryButton = (history) => {
    return (
      (activePermission == "owner" || activePermission == "admin"|| activePermission == "modify") &&
      <a style={{color:'#65BB38', marginRight: '20px', float:'right'}} onClick={() => showEditHistoryModal(history)}>
        {<EditOutlined />}
      </a>
    )
  }

  const showEditHistoryModal = (history_data) => {
    setHistoryData(history_data);
    setVisibleEditHistoryModal(true);
  }

  const cancelEditHistoryModal = () => {
    setHistoryData({});
    setVisibleEditHistoryModal(false);
  }

  const confirmDelete = (del_history) => {
    if(del_history.id){
       activityService.destroy(del_history.id).then(res => {
        if(res.code === '0000'){
          props.updateDataAreaHistory(props.dataId, 'area');        
        }
      });
    }

  }

  return (
    <PropertyAreaHistoryPage>
      <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
      <br/>
        <Timeline>
         {(propertyAreaHistoryData) ? (propertyAreaHistoryData.map((value, index) => {
          if(value.metadata.activity_category === 'treatment'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Treatment' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Name</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.name}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Quantity</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Application</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.application}</b>
                  </Col>
                </Row>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}

              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.activity_category === 'fertilizer'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Fertilizer' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Name</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.name}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Quantity</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.quantity}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Application</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.application}</b>
                  </Col>
                </Row>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.activity_category === 'sow'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Sow' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Seed</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.seed}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Volume</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.volume}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Application</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.application}</b>
                  </Col>
                </Row>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.activity_category === 'pasture_development'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Pasture Development' bordered={true}>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }  

          if(value.metadata.activity_category === 'earth_works'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Earth Works' bordered={true}>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          } 

          if(value.metadata.activity_category === 'other'){
            return (
            <Timeline.Item color="green" key={index}>
              {renderDeleteHistoryButton(value)}
              {renderEditHistoryButton(value)}
              <p>{moment(value.created_at).format('ll')}</p>
              <Card title='Other' bordered={true}>
                {(value.metadata.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }   
        })) : ''}
        </Timeline>
      </div>
      <EditHistoryModal visibleEditHistoryModal={visibleEditHistoryModal} cancelEditHistoryModal={cancelEditHistoryModal}
        historyData={historyData} updateDataHistory={props.updateDataAreaHistory} dataType="area"
      />
    </PropertyAreaHistoryPage>
  )
}

export default PropertyAreaHistory;