import React, { useState, useEffect } from "react";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector } from "react-redux";
import {
  propertyService,
  dashboardService,
  activityService,
  livestockService,
  weatherDataService,
} from "@iso/services";
import { Button, Row, Col, Select } from "antd";
import _ from "lodash";
import * as d3 from "d3";
import WeatherWrapper from "./Weather.styles";
// import LiveStockChart from './LiveStockChart';
// import BubbleChart from './BubbleChart';
// import PieChart from './PieChart';
import WeatherChart from "@iso/containers/Pages/Dashboard/WeatherChart";
import WeatherMap from "@iso/containers/Pages/WeatherMap/WeatherMap";
import UserWeatherChart from "./UserWeatherChart";
import WeatherTable from "./WeatherTable";
import WeatherForm from "./WeatherForm";
import moment from "moment";
import { janesWeather } from "@iso/constants/janesWeather";
import { openWeather } from "@iso/constants/openWeather";
import { LoadingOutlined } from "@ant-design/icons";
import WeatherForecast from "./WeatherForecast";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { Option } = Select;
const Weather = () => {
  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const [property, setProperty] = useState({});
  const [dataTask, setDataTask] = useState({});
  const [dataChart, setDataChart] = useState([]);
  const [dataBubbleChart, setDataBubbleChart] = useState([]);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [firstPrimaryObjects, setFristPrimaryObjects] = useState(0);
  const [weatherDataTable, setWeatherDataTable] = useState({});
  const [weatherApiType, setWeatherApiType] = useState("");
  const [typeChartR, setTypeChartR] = useState("wind_speed");
  const [nameChartR, setNameChartR] = useState("Wind Speed");
  const [dateChart, setDateChart] = useState(moment(new Date(), "YYYY-MM-DD"));

  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
  const [loading, setLoading] = useState(false);
  const [numberDays, setNumberDays] = useState(1);
  const [widthClass, setWidthClass] = useState("w-full");
  const [daysWeather, setDaysWeather] = useState(
    moment(new Date(), "YYYY-MM-DD")
  );
  const [hoursWeather, setHoursWeather] = useState(3);
  const [nextDay, setNextDay] = useState(0);
  const [weatherData, setWeatherData] = useState([]);
  const [currentPosition, setCurrentPosition] = useState();
  const [dashboardInfo, setDashboardInfo] = useState({
    weather: {
      rain: [],
      temperature: [],
    },
  });

  useEffect(() => {
    if (activePropertyId) {
      propertyService.viewProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setProperty(res.property);
          setPrimaryObjects(res.primary_objects);
          if (res.property.country === "Australia") {
            setWeatherApiType("janes_weather");
          } else {
            setWeatherApiType("open_weather");
          }
        }
      });
    }
  }, [activePropertyId]);

  const updateWeatherData = () => {
    if (activePropertyId) {
      weatherDataService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setWeatherDataTable(res.weather_data);
        }
      });
    }
  };

  const updateWeatherChart = (primary_object_id) => {
    weatherDataService
      .getDataForWeatherChart(primary_object_id, "rain")
      .then((res) => {
        if (res.code === "0000") {
          setFristPrimaryObjects(primary_object_id);
          setWeatherData(res.weather_data);
        }
      });
  };

  useEffect(() => {
    if (activePropertyId && primaryObjects[0]) {
      updateWeatherChart(primaryObjects[0].id);
    }
  }, [activePropertyId, primaryObjects]);

  useEffect(() => {
    updateWeatherData();
  }, [activePropertyId]);

  const onSaved = (weather_data) => {
    setWeatherData(weather_data);
  };

  useEffect(() => {
    if (activePropertyId) {
      setLoading(true);
      if (weatherApiType !== "") {
        dashboardService.get(activePropertyId, weatherApiType).then((res) => {
          if (res.code === "0000") {
            setDashboardInfo(res.data);
            setDataTask(res.data.task_count);
            setLoading(false);
          }
        });
      }
    }
  }, [activePropertyId, weatherApiType]);

  const handleChange = (value) => {
    setWeatherApiType(value.value);
    setDaysWeather(moment(new Date(), "YYYY-MM-DD"));
    setNameChartR("Wind Speed");
    setNumberDays(1);
    setDateChart(moment(new Date(), "YYYY-MM-DD"));
    setWidthClass("w-full");
  };

  const handleChangeTypeChart = (index, value) => {
    setTypeChartR(value.value);
    setNameChartR(value.label);
  };

  const handleChangeDaysWeather = (index, value) => {
    setDaysWeather(moment(new Date(), "DD-MM-YYYY").add(value.day, "days"));
    setHoursWeather(value.hour);
    setNextDay(value.next);
    setNumberDays(value.value);
    setWidthClass(value.width);
  };

  const renderOptionDaysWeather = () => {
    return (
      <Select
        placeholder="Select"
        style={{ width: 180 }}
        onChange={handleChangeDaysWeather}
        value={numberDays}
      >
        <Option
          key={1}
          label="1 day - Hourly"
          value={0}
          day={0}
          next={0}
          hour={1}
          width="w-full"
        >
          <div>1 Day - Hourly</div>
        </Option>
        <Option
          key={2}
          label="1 Day - Every 3 Hours"
          value={1}
          day={0}
          next={0}
          hour={3}
          width="w-full"
        >
          <div>1 Day - Every 3 Hours </div>
        </Option>
        <Option
          key={3}
          label="next 24 Hours"
          value={2}
          day={0}
          hour={1}
          next={1}
          width="w-full"
          disabled={
            subscriptionHelper.checkIsGold(activePropertySubscription) ||
            subscriptionHelper.checkIsSilver(activePropertySubscription)
              ? false
              : true
          }
        >
          <div>Next 24 Hours</div>
        </Option>
        <Option
          key={4}
          label="next 36 Hours"
          value={3}
          day={0}
          hour={1}
          next={2}
          width="w-full"
          disabled={
            subscriptionHelper.checkIsGold(activePropertySubscription) ||
            subscriptionHelper.checkIsSilver(activePropertySubscription)
              ? false
              : true
          }
        >
          <div>Next 36 Hours</div>
        </Option>
        <Option
          key={5}
          label="3 Day - Hourly"
          value={4}
          day={2}
          hour={1}
          next={0}
          width="w-8000"
          disabled={
            subscriptionHelper.checkIsGold(activePropertySubscription) ||
            subscriptionHelper.checkIsSilver(activePropertySubscription)
              ? false
              : true
          }
        >
          <div>3 Day - Hourly </div>
        </Option>
        <Option
          key={6}
          label="3 Day - Every 3 Hours"
          value={5}
          day={2}
          hour={3}
          next={0}
          width="w-3000"
          disabled={
            subscriptionHelper.checkIsGold(activePropertySubscription) ||
            subscriptionHelper.checkIsSilver(activePropertySubscription)
              ? false
              : true
          }
        >
          <div>3 Day - Every 3 Hours </div>
        </Option>
        {weatherApiType === "janes_weather" && (
          <Option
            key={7}
            label="10 Day - Hourly"
            value={6}
            day={9}
            hour={1}
            next={0}
            width="w-20000"
            disabled={
              subscriptionHelper.checkIsGold(activePropertySubscription)
                ? false
                : true
            }
          >
            <div>10 Day - Hourly </div>
          </Option>
        )}
        {weatherApiType === "janes_weather" && (
          <Option
            key={8}
            label="10 Day - Every 3 Hours"
            value={7}
            day={9}
            hour={3}
            next={0}
            width="w-8000"
            disabled={
              subscriptionHelper.checkIsGold(activePropertySubscription)
                ? false
                : true
            }
          >
            <div>10 Day - Every 3 Hours</div>
          </Option>
        )}
      </Select>
    );
  };

  const renderOptionTypeJanesWeather = () => {
    let options = [];
    _.forEach(janesWeather, (type, index) => {
      options.push(
        <Option key={index} label={type.label} value={type.value}>
          <div>{type.label}</div>
        </Option>
      );
    });
    return (
      <Select
        placeholder="Select a Types"
        style={{ width: 150 }}
        onChange={handleChangeTypeChart}
        defaultValue={{
          value: janesWeather[0].value,
          label: janesWeather[0].label,
        }}
      >
        {options}
      </Select>
    );
  };

  const renderOptionTypeOpenWeather = () => {
    let options = [];
    _.forEach(openWeather, (type, index) => {
      options.push(
        <Option key={index} label={type.label} value={type.value}>
          <div>{type.label}</div>General
        </Option>
      );
    });
    return (
      <Select
        placeholder="Select a Types"
        style={{ width: 150 }}
        onChange={handleChangeTypeChart}
        defaultValue={{
          value: openWeather[0].value,
          label: openWeather[0].label,
        }}
      >
        {options}
      </Select>
    );
  };

  return (
    <WeatherWrapper>
      <Row>
      <Col span={24}>
        <Box className="statistic-card">
        {/* <WeatherMap setCurrentPosition={setCurrentPosition}/> */}
        {/* <WeatherForecast currentPosition={currentPosition}/> */}
        <WeatherForecast/>
        </Box>
        </Col>
    
        <Col xl={12} md={24} sm={24} xs={24}>
          <Box className="statistic-card">
            <WeatherForm
              primaryObjects={primaryObjects}
              propertyId={activePropertyId}
              updateWeatherChart={updateWeatherChart}
              updateWeatherData={updateWeatherData}
            />
          </Box>
        </Col>
        <Col xl={12} md={24} sm={24} xs={24}>
          <Box id="weather-tab-chart" className="statistic-card">
            <UserWeatherChart
              data={weatherData}
              primaryObjects={primaryObjects}
              onSaved={onSaved}
              firstPrimaryObjects={firstPrimaryObjects}
            />
          </Box>
        </Col>
        
        {/* <Col xl={24} md={24} sm={24} xs={24}>
          <Box id="weather-chat-div" className="statistic-card">
            <h1 className="h1Title">
              {<IntlMessages id="propertyPage.dashboard.weather.title" />}
            </h1>
            {property.country === "Australia" &&
              subscriptionHelper.checkIsGold(activePropertySubscription) && (
                <Select
                  labelInValue
                  defaultValue={{
                    value: "janes_weather",
                    label: "Consensus Advanced Weather feed",
                  }}
                  style={{ width: 280 }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "janes_weather",
                      label: "Consensus Advanced Weather feed",
                    },
                    {
                      value: "open_weather",
                      label: "Global US Weather feed",
                    },
                  ]}
                />
              )}
            { subscriptionHelper.checkIsGold(activePropertySubscription) &&
              weatherApiType === "open_weather" &&
              renderOptionTypeOpenWeather()}
            { subscriptionHelper.checkIsGold(activePropertySubscription) &&
              weatherApiType === "janes_weather" &&
              renderOptionTypeJanesWeather()}
            {renderOptionDaysWeather()}
            {loading && (
              <div class="loading-weather-chart">
                <LoadingOutlined />
              </div>
            )}
            <div className="temperature_weather">
              <WeatherChart
                data={dashboardInfo.weather}
                chartid="temperature-chart"
                weatherApiType={weatherApiType}
                dateChart={dateChart}
                setDateChart={setDateChart}
                typeChartR={typeChartR}
                nameChartR={nameChartR}
                daysWeather={daysWeather}
                widthClass={widthClass}
                hoursWeather={hoursWeather}
                nextDay={nextDay}
                activePropertySubscription={activePropertySubscription}
              />
            </div>
          </Box>
        </Col> */}
        {weatherDataTable && (
          <Col xl={24} md={24} sm={24} xs={24}>
            <WeatherTable
              weatherDataTable={weatherDataTable}
              updateWeatherData={updateWeatherData}
              updateWeatherChart={updateWeatherChart}
            />
          </Col>
        )}
      </Row>
    </WeatherWrapper>
  );
};

export default Weather;
