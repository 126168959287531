import React, { useState, useEffect } from "react";
import Loader from "@iso/components/utility/loader";
import queryString from "query-string";
import _ from "lodash";
import { searchService } from "../../../services/search.service";
import PropertyBox from "./partials/PropertyBox";
import LiveStockBox from "./partials/LiveStockBox";
import PropertyAreaBox from "./partials/PropertyAreaBox";
import MobBox from "./partials/MobBox";
import AssetBox from "./partials/AssetBox";
import Asset from "../Asset/Asset";
import PropertyArea from "../PropertyArea/PropertyArea";
import TaskBox from "./partials/TaskBox";
import VisitorBox from "./partials/VisitorBox";
import Property from "../Property/Property";

const SearchPage = (props) => {
  
    const [search_result, setSearchResult] = useState([]);
    const search_text = queryString.parse(props.location.search).s || "";
    useEffect(() => {
        searchService.search(search_text).then((res) => {
            setSearchResult(res);
        });
        return () => {};
    }, [search_text]);

    return (
        <div>
            {!search_result ? (
                <Loader />
            ) : (
                <div>
                <Property search_result={search_result} /><br/>
                <PropertyArea search_result={search_result} /><br/>
                <Asset search_result={search_result} /><br/>
                <TaskBox search_result={search_result}></TaskBox><br/>
                </div>
            )}
        </div>
    );
};

export default SearchPage;
