import React from 'react';
import './assets/styles/react-table.css';
import Table from './components/Table'
export default function(props) {
  return (
    <Table
      {...props}       
    />
  );
}
