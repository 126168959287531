import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const PaddockWrap = styled.div`
  height: 500px;
  overflow: auto;
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    &:hover{
      opacity: 0.8;
    }
  }
  .confirm-delete-plan .ant-btn-primary{
    background: #65bb38;
  }
  .btn-edit-paddock{
    border:none;
    color: #559f2f;
  } 
  .ant-popover-buttons .ant-btn-sm{
    background: #65bb38;
  }
  .btn-delete-paddock{
    border:none;
    color: red;
  }
  .card_content{
    display:inline;
  }

  .card_value{
    float:right;
  }
`;

export default WithDirection(PaddockWrap);