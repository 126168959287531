import styled from 'styled-components';

const LayoutContentWrapper = styled.div`
  // padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }
  &&.map-wrapper {
    height: 100%;

    &.safari {
      height: calc(100vh - 70px);
    }

    @supports (-webkit-touch-callout: none) {
      @media only screen and (min-width: 768px) {
        height: calc(100vh - 140px);
      }
      @media only screen and (max-width: 767px) {
        height: calc(75vh);    
      }
    }
    padding: 0px !important;
  }
`;

export { LayoutContentWrapper };
