export const stockType = [
  {
    label: 'Cows',
    value: 'cows',
  },
  {
    label: 'Weaner Cattle',
    value: 'weanerCattle',
  },
  {
    label: 'Ewes / Wethers',
    value: 'ewesWethers'
  },
  {
    label: 'Lambs',
    value: 'lambs'
  },
]