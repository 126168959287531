import React, { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@iso/components/utility/box";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Menu,Popover, Select } from "antd";
import ManagePropertyPage from './Partial.styles'
import
{
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  CheckCircleOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  FundViewOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";
import _, { property } from "lodash";
import Table from "@iso/components/collapsing-table/table";
import { propertyService, permissionService } from "@iso/services";
import { subscriptionPlan } from "@iso/constants/subscriptionPlan";
import { Fragment } from "react";
import styled from "styled-components";
import PropertyTableFirstCell from "../../Property/PropertyTableFirstCell";
import UserAccessModal from "../../Property/UserAccessModal";
import SelectPropertyModal from "../../Property/SelectPropertyModal";
import SubscriptionModal from "../../Property/SubscriptionModal";
import PropertyModal from "../../Property/PropertyModal";
import PropertyHistoryModal from "../../Property/PropertyHistoryModal";
import FormModal from '../../Form/FormModal';
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { changeActiveProperty } = propertyActions;

const PropertyBox = (props) => {


    const menuList=( property, permission ) => (
        <Menu
          className="manage-property-custom"
          onClick={handleMenuClick.bind( this, property )}
          style={{ borderRight: "unset" }}
        >
          <Menu.Item
            key="1"
            className="mpList-icon"
            onClick={() =>
            {
              showHistory( property );
            }}
          >
            <HistoryOutlined />
            History
          </Menu.Item>
          <Menu.Item key="editDetails" className="mpList-icon">
            <EditOutlined /> Edit Details
          </Menu.Item>
          <Menu.Item key="userAccess" className="mpList-icon">
            <UserOutlined />
            User Access
          </Menu.Item>
          <Menu.Item key="addForm" className="mpList-icon">
            <PlusCircleOutlined />
            Add Forms
          </Menu.Item>
          <Menu.Item key="viewForm" className="mpList-icon">
            <EyeOutlined />
            View Forms
          </Menu.Item>
          <Menu.Item key="upgrade" className="mpList-icon">
            <UpCircleOutlined />
            Subscription Upgrade
          </Menu.Item>
          { subscriptionHelper.checkIsGold(activePropertySubscription) && 
            <Menu.Item key="iotDevice" icon={<SettingOutlined />}>
              <IntlMessages id="antTable.iotDevice" />
            </Menu.Item>
          }
        </Menu>
      );
    let history = useHistory();
    const dispatch = useDispatch();
    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activePropertySubscription = useSelector(
        (state) => state.property.activePropertySubscription
    );
    const activePermission = useSelector((state) => state.permission);
    const [visible, setVisiblity] = React.useState(false);
    const activeProperty = localStorage.getItem("active_property");
    const [visibleUserAccess, setVisibleUserAccess] = React.useState(false);
    const [editProperty, setEditProperty] = React.useState({});
    const [property, setProperty] = React.useState({});
    const { Option } = Select;
    const [properties, setProperties] = React.useState([]);
    const [isSaved, setIsSaved] = React.useState(false);
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(
        false
    );
    const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
    const [visibleHistoryModal, setVisiblityHistoryModal] = React.useState(
        false
    );
    const [propertyHistory, setPropertyHistory] = React.useState({});
    const [visibleImportModal, setVisibleImportModal] = React.useState(false);

    const [visibleUpgradeModal, setVisibleUpgradeModal] = React.useState(false);

    useEffect(() => {
        propertyService.getProperties().then((res) => {
            if (res.properties.length === 0) {
                setVisiblitySelectProperty(true);
            } else {
                setProperties(res.properties);
                if (res.properties.length === 1) {
                    localStorage.setItem(
                        "active_property",
                        JSON.stringify(res.properties[0])
                    );
                    dispatch(changeActiveProperty(res.properties[0]));
                }
            }
        });
    }, [activePropertyId, isSaved, activePropertySubscription]);

    useEffect(() => {
        if (!activeProperty) {
            setVisiblitySelectProperty(true);
        }
    }, []);

    const handleMenuClick = (property, e) => {
        if (e.key === "editStatus") {
            localStorage.setItem("active_property", JSON.stringify(property));
            dispatch(changeActiveProperty(property));
        }
        if (e.key === "editDetails") {
            setVisiblity(true);
            setEditProperty(property);
        }
        if (e.key === "userAccess") {
            setVisibleUserAccess(true);
            setProperty(property);
        }

        if (e.key === "upgrade") {
            setProperty(property);
            setVisibleUpgradeModal(true);
        }

        if (e.key === "viewTiers") {
            window.open(
                "https://www.airagri.com.au/subscription-pricing-plans/"
            );
        }

        if (e.key === "viewForm") {
            history.push("/manage-forms");
        }

        if (e.key === "addForm") {
            setProperty(property);
            setVisiblityFormModal(true);
        }

        if (e.key == "import") {
            showImportModal();
            setProperty(property);
        }
    };

    const showModal = () => {
        setVisiblity(true);
    };

    const cancelModal = () => {
        setVisiblity(false);
        setEditProperty({});
    };

    const cancelSelectPropertyModal = () => {
        setVisiblitySelectProperty(false);
    };

    const cancelUserAccessModal = () => {
        setVisibleUserAccess(false);
        setProperty({});
    };

    const cancelUpgradeModal = () => {
        setVisibleUpgradeModal(false);
        setProperty({});
    };

    const showHistory = (property) => {
        setPropertyHistory(property);
        setVisiblityHistoryModal(true);
    };

    const cancelHistory = () => {
        setPropertyHistory({});
        setVisiblityHistoryModal(false);
    };

    const menu = (property, permission) => (
        <Menu onClick={handleMenuClick.bind(this, property)}>
            {(permission == "owner" || permission == "admin") && (
                <Menu.Item key="editDetails" icon={<EditOutlined />}>
                    <IntlMessages id="propertyPage.managePage.editDetails" />
                </Menu.Item>
            )}
            {permission == "owner" && (
                <Menu.Item key="userAccess" icon={<UserOutlined />}>
                    <IntlMessages id="propertyPage.managePage.userAccess" />
                </Menu.Item>
            )}
            {activePropertyId != property.id && (
                <Menu.Item key="editStatus" icon={<CheckCircleOutlined />}>
                    <IntlMessages id="propertyPage.managePage.active" />
                </Menu.Item>
            )}
            { subscriptionHelper.checkIsGold(activePropertySubscription) && (
                <Menu.Item key="import" icon={<PlusOutlined />}>
                    <IntlMessages id="mobPage.managePage.import" />
                </Menu.Item>
            )}
        </Menu>
    );

    const menuSubcription = (property, permission) => (
        <Menu onClick={handleMenuClick.bind(this, property)}>
            {permission == "owner" && (
                <Menu.Item key="upgrade" icon={<ArrowUpOutlined />}>
                    <IntlMessages id="propertyPage.managePage.upgrade" />
                </Menu.Item>
            )}
            <Menu.Item key="viewTiers" icon={<QuestionCircleOutlined />}>
                <IntlMessages id="propertyPage.managePage.viewTiers" />
            </Menu.Item>
        </Menu>
    );

    const menuForm = (property, permission) => (
        <Menu onClick={handleMenuClick.bind(this, property)}>
            {permission == "owner" && (
                <Menu.Item key="addForm" icon={<PlusCircleOutlined />}>
                    <IntlMessages id="propertyPage.managePage.addForm" />
                </Menu.Item>
            )}
            <Menu.Item key="viewForm" icon={<EyeOutlined />}>
                <IntlMessages id="propertyPage.managePage.viewForm" />
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            label: <IntlMessages id="antTable.title.property" />,
            accessor: "name",
            minWidth: 100,
            sortable: true,
            position: 1,
            priorityLevel: 1,
            CustomComponent: (cell) => {
                return (
                    <PropertyTableFirstCell
                        activePropertyId={activePropertyId}
                        property={cell.row}
                    />
                );
            },
        },
        {
            label: <IntlMessages id="antTable.title.address" />,
            accessor: "address",
            minWidth: 300,
            sortable: true,
            position: 2,
            priorityLevel: 2,
        },
        {
            label: <IntlMessages id="antTable.title.subscription_tier" />,
            accessor: "subscription_label",
            minWidth: 300,
            sortable: true,
            position: 3,
            priorityLevel: 3,
            CustomComponent: (cell) => {
                let row = cell.row;
                if (row.subscription_label) {
                    return subscriptionPlan.map((value, index) => {
                        if (value.value == row.subscription_label) {
                            return <div>{value.label}</div>;
                        }
                    });
                } else {
                    return (
                        <div>
                            {
                                <IntlMessages id="subscription.prices.REACT_APP_STRIPE_PLAN_BRONZE" />
                            }
                        </div>
                    );
                }
            },
        },
        {
            label: "",
            accessor: "actions",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            noTitle: true,
            CustomComponent: (cell) => {
                let row = cell.row;
                let permission = permissionService.getPermission(
                    row.permission_id
                );
                return (
                    <div>
                        <Dropdown
                            overlay={menu(row, permission)}
                            trigger={["click"]}
                        >
                            <Button className="btn-success ant-btn-primary">
                                <IntlMessages id="antTable.title.actions" />{" "}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        &nbsp;
                        <Dropdown
                            overlay={menuSubcription(row, permission)}
                            trigger={["click"]}
                        >
                            <Button className="action-button">
                                <IntlMessages id="antTable.title.subscription" />{" "}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        &nbsp;
                        <Dropdown
                            overlay={menuForm(row, permission)}
                            trigger={["click"]}
                        >
                            <Button className="action-button">
                                <IntlMessages id="antTable.title.forms" />{" "}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        &nbsp;
                        <Button
                            className="action-button view-history-button"
                            onClick={() => {
                                showHistory(row);
                            }}
                        >
                            <IntlMessages id="liveStockPage.table.viewHistory" />
                        </Button>
                    </div>
                );
            },
        },
        {
            label: "Action",
            accessor: "dot-menu",
            minWidth: 100,
            sortable: true,
            position: 4,
            priorityLevel: 4,
            noTitle: true,
            CustomComponent: ( cell, i ) =>
            {
              let row=cell.row;
              let permission=permissionService.getPermission( row.permission_id );
              return (
                <div className="dot-btn manage-property-dot-menulist">
                  <Popover
                    placement="bottomLeft"
                    className="mp-popover"
                    content={() => menuList( row, permission )}
                    trigger="click"
                  >
                    <div
                      className="manage-property-dot-btn actionstyle"
                      style={{ cursor: "pointer" ,fontSize:'large',fontWeight:'bold',color:'#3f3f44'}}
                    >
                      <EllipsisOutlined className="dotIcon"/>
                    </div>
                  </Popover>
                </div>
              );
            },
          },
    ];

    const handleOptionClick = (value) => {
        _.forEach(properties, (property) => {
            if (property.id == value) {
                localStorage.setItem(
                    "active_property",
                    JSON.stringify(property)
                );
                dispatch(changeActiveProperty(property));
            }
        });
    };

    const renderOptionsProperty = () => {
        let options = [];
        _.forEach(properties, (property, index) => {
            options.push(
                <Option
                    key={index}
                    value={property.id}
                    className="property-option"
                >
                    {property.name}
                </Option>
            );
        });

        return (
            <Select
                placeholder="Select Active Property"
                value={activePropertyId}
                size="large"
                style={{ width: 150 }}
                onChange={handleOptionClick}
            >
                {options}
            </Select>
        );
    };

    const onSaved = () => {
        setIsSaved(!isSaved);
    };

    const cancelFormModal = () => {
        setVisiblityFormModal(false);
    };

    const showImportModal = () => {
        setVisibleImportModal(true);
    };

    const cancelImportModal = () => {
        setVisibleImportModal(false);
        setProperty({});
    };

    return (
        <ManagePropertyPage>
        <Fragment>
            <Box id="containerBox">
                 <div className="manage-property-header">
            <div className="mp-title">
              <h2 className="manage-property-title">Manage Properties</h2></div>
            </div>

                {props.search_result &&
                props.search_result.Property &&
                props.search_result.Property.length > 0 ? (
                    <div className="manage-table">
                    <Table
                        columns={columns}
                        rows={props.search_result.Property}
                        containerId="containerBox"
                        showPagination={false}
                        rowSize={99999}
                    />
                    </div>
                ) : (
                    <h1 style={{ padding: "10px 10px 20px", fontSize: '18px' }}>
                    <IntlMessages id="dashboardPage.noData" />
                  </h1>
                )}
            </Box>
            <PropertyModal
                editProperty={editProperty}
                visible={visible}
                cancelModal={cancelModal}
                onSaved={onSaved}
            />
             <FormModal
          visibleFormModal={visibleFormModal}
          cancelModal={cancelFormModal}
          propertyId={property.id}
          fromProperty={true}
        />

            <UserAccessModal
                visible={visibleUserAccess}
                propertyId={property.id}
                cancelModal={cancelUserAccessModal}
            />
            <SelectPropertyModal
                activeProperty={activeProperty}
                propertyPage={true}
                visibleSelectProperty={visibleSelectProperty}
                cancelSelectPropertyModal={cancelSelectPropertyModal}
            />
            <SubscriptionModal
                visible={visibleUpgradeModal}
                property={property}
                cancelModal={cancelUpgradeModal}
                onSaved={onSaved}
            />
            <PropertyHistoryModal
                visibleHistoryModal={visibleHistoryModal}
                cancelHistory={cancelHistory}
                propertyId={propertyHistory.id}
            />
        </Fragment>
        </ManagePropertyPage>
    );
};

export default PropertyBox;

const ColorItemRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 30px;
    div {
        margin: 4px;
    }
`;

const ColorItemPreview = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;
