export const waterStorageTypes = [
  {
    label: 'Dam',
    value: 'dam',
  },
  {
    label: 'Tank',
    value: 'tank',
  },
  {
    label: 'River',
    value: 'river',
  },
  {
    label: 'Other',
    value: 'other',
  }
]