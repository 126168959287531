import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm, Timeline, Card, Row, Col } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { activityService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import PropertyManagePage from './Property.styles';

const LivestockHistory = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const dispatch = useDispatch();
  const [livestockHistory, setLivestockHistory] = useState([]);

  useEffect(() => {
    if(props.propertyId){
       activityService.getHistoryByProperty(props.propertyId, 'asset').then(res => {
        if(res.code === '0000'){
          setLivestockHistory(res.activities.reverse());
        }
      });
    }
  }, [props.propertyId]);

  return (
    <PropertyManagePage>
      <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
      <br/>
        <Timeline>
         {(livestockHistory) ? (livestockHistory.map((value, index) => {
            if(value.metadata.value && value.metadata.value.weight){
               return  (
                        <Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Weight" bordered={true}>
                            <p className="card_content">Weight</p> 
                            <b className="card_value">{value.metadata.value.weight} kg</b>
                          </Card>
                        </Timeline.Item>
                        )

            }

             if(value.metadata.moving){
               return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Moving" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Historical Area</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.moving.source}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">New Area</p> 
                              </Col>
                                
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                              <b className="card_value">{value.metadata.moving.destination}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Duration in Area</p> 
                              </Col>
                                
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                              <b className="card_value">{value.metadata.moving.duration}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.treatment){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Treatment" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Treatment name</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.treatment.treatment_name}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Quantity</p> 
                              </Col>
                                
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                              <b className="card_value">{value.metadata.treatment.treatment_quantity}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Unit</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.treatment.treatment_unit}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">WHP</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.treatment.treatment_WHP}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">ESI</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.treatment.treatment_ESI}</b>
                              </Col>
                            </Row>
                
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.feeding){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Feeding" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Feed Type</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.feeding.feeding_type}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Quantity</p> 
                              </Col>
                                
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                              <b className="card_value">{value.metadata.feeding.feeding_quantity}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.joining){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Joining" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Sire</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.joining.joining_siren}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Expected Duration</p> 
                              </Col>
                                
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                              <b className="card_value">{value.metadata.joining.joining_expected_duration}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.marking){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Marking" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">System Date</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{moment(value.metadata.marking.marking_system_date).format('ll')}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.weighing){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Weighing" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Weight</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.weighing.weight} kg</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.preg_testing){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Preg Testing" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Preg Testing</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.preg_testing.preg_testing}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.crutching){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Crutching" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">System Date</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{moment(value.metadata.crutching.crutching_system_date).format('ll')}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.death){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Death" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">{value.metadata.death.cause_of_death ? 'Cause of death' : 'Number Of Animals'}</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.death.cause_of_death ? value.metadata.death.cause_of_death : value.metadata.death.number_of_animals}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">{value.metadata.death.cause_of_death ? 'Location' : 'Description'}</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.death.death_location ? value.metadata.death.death_location : value.metadata.death.description}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            if(value.metadata.other){
              return (<Timeline.Item color="green" key={index}>
                          <p>{moment(value.created_at).format('ll')}</p>
                          <br/>
                          <h3>{value.object_name}</h3>
                          <Card title="Other" bordered={true}>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Title</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.other.other_title}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Comment</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.other.other_comment}</b>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={{span: 11}} md={{span: 11}}>
                                <p className="card_content">Location</p> 
                              </Col>
                               
                              <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                               <b className="card_value">{value.metadata.other.other_location}</b>
                              </Col>
                            </Row>
                          </Card>
                        </Timeline.Item>)
            }

            return false
            
        })) : ''}
        </Timeline>
      </div>
    </PropertyManagePage>
  )
}

export default LivestockHistory;