import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const activityService = {
  getList,
  storeOrUpdate,
  destroy,
  getActivitiesByProperty,
  getHistoryByProperty
};

function getList(livestock_id, history_model){
  return (
    axios
      .get(
        `${apiEndpoint.activities}?object_id=${livestock_id}&model=${history_model}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.activities(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.activities,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.activities(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function getActivitiesByProperty(property_id, day_number){
  return (
    axios
      .get(
        `${apiEndpoint.getActivityByProperty}`,
        {
          headers: authHeader(),
          params: {
            property_id,
            day_number
          }
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getHistoryByProperty(property_id, model){
  return (
    axios
      .get(
        `${apiEndpoint.getHistoryByProperty}?property_id=${property_id}&model=${model}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}