import actions from './actions';

const initialState = {
  properties: [],
  activePropertyPIC: null
};

export default function picReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STORE_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case actions.CHANGE_ACTIVE_PIC:
      return {
        ...state,
        activePropertyPIC: action.activePropertyPIC
      };
    default:
      return state;
  }
}
