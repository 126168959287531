import React, { useState, useEffect,useRef,useCallback } from 'react';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import { propertyService,healthsafety } from '@iso/services';
import { useSelector, useDispatch } from 'react-redux';
import AssetHealthSafety from './AssetHealthSafety';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import Control from '@skyeer/react-leaflet-custom-control'
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Checkbox, Row, Col} from 'antd';
import { iotDevice } from '@iso/services';
import DeviceMapMarker from '@iso/containers/Pages/SafeFarm/DeviceMapMarker';
import { DownOutlined} from '@ant-design/icons';
import AppLocale from '@iso/config/translation';
import { IntlProvider } from 'react-intl';
import _ from 'lodash';
import L from 'leaflet';
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const MapHealthSafety = (props) => {
  const mapRef = useRef();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const controlDraw = React.useRef(null);
  const [height, setHeight] = useState(null);
  const [trackTrace, setTrackTrace] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positionDevice, setPositionDevice] = useState();
  const currentAppLocale = AppLocale[locale];
  const [centerPoint, setCenterPoint] = useState();
  const [assets, setAssets] = useState({});
  const [showMarkerAlert, setShowMarkerAlert] = useState(false);

  const getDataAssets = (propertyId) => {
    healthsafety.getHealthAndSafetyAssets(propertyId).then(res => {
      if(res.code === '0000'){
        setAssets(res.assets);
        getPositionHealthSafety(res.assets);
      }
    })
  };

  useEffect(() => {
    if(activePropertyId){
      getDataAssets(activePropertyId);
      setHeight(document.getElementById('health-safety-map').offsetHeight - 40);
      props.updatePropertyDetail(activePropertyId);
    }    
  }, [activePropertyId]);

  const menuShowOptions = () => (
    <Menu>
      { subscriptionHelper.checkIsGold(activePropertySubscription) &&
      <Menu.Item key="trackTrace" htmlFor="dark-theme">
        <Checkbox checked={trackTrace}  onChange={onChangeTrackTrace} id='track-trace'>
          <IntlMessages id="propertyMapPage.trackTrace" />
        </Checkbox>
      </Menu.Item>
      }
    </Menu>
  );

  const getPositionHealthSafety = (data) => {
    const {current = {}} = mapRef;
    const {leafletElement: map} = current;
    let position = [];
    if(data.length > 0){
      _.forEach(data, function(asset){
        if(asset.lat && asset.lng && ( asset.type === "fire_extinguisher" || asset.type ==="first_aid_kit" || asset.type === "evacuation_point") ){
          position.push([asset.lat,asset.lng]);
        }
      });
      let bounds = new L.LatLngBounds(position);
      let center = bounds.getCenter();
      map.fitBounds(bounds);
      setCenterPoint(center);
    }
  }

  const getPositionDevice = () =>{
    let position = [];
    const {current = {}} = mapRef;
    const {leafletElement: map} = current;
    iotDevice.positionDevice(activePropertyId).then(res => {
      if(res.code === '0000'){
        setPositionDevice(res.positions);
        setLoading(false);
        if(res.positions && res.positions.length > 0){
          _.forEach(res.positions, function(data){
            if(data.position && data.position.length > 0){
              _.forEach(data.position, function(p){
                if(p.latitude && p.longitude){
                  position.push([p.latitude,p.longitude]);
                }
              });
            }
          });
          let bounds = new L.LatLngBounds(position);
          let center = bounds.getCenter();
          setCenterPoint(center);
          map.fitBounds(bounds);
        }
      }  
    });
  }

  const onChangeTrackTrace = (e) => {
    setTrackTrace(e.target.checked); 
    if( (e.target.checked) && activePropertyId ){
      getPositionDevice();
    }else{
      getPositionHealthSafety(assets);
    }
    
  }

  return(
    <Map ref={mapRef} center={ centerPoint ? centerPoint : (props.position[0] !== null ? props.position : false) } doubleClickZoom={false}  zoom={15} zoomControl={true} style={{width:'100%', height:'100%'}}>
      <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false}/>
      <AssetHealthSafety
          propertyId={props.propertyId}
          controlDraw={controlDraw}
          primaryObjects={props.primaryObjects}
          updatePropertyDetail={props.updatePropertyDetail}
      /> 
      <Control position="topright">
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        > 
          <Dropdown overlay={menuShowOptions} trigger={['click']}>
            <Button className="action-button">              
              <IntlMessages id="propertyMap.title.view" /> <DownOutlined />
            </Button>
          </Dropdown>
        </IntlProvider>
      </Control>
      {trackTrace &&
        <DeviceMapMarker 
          propertyId={activePropertyId} 
          getPositionDevice={getPositionDevice} 
          setLoading={setLoading} 
          loading={loading} 
          setShowMarkerAlert={setShowMarkerAlert}
          showMarkerAlert={showMarkerAlert}
          positionDevice={positionDevice} />
      }
 
    </Map>
  )

}
export default MapHealthSafety;
