import React from 'react';
import { Table, Tag, Space } from 'antd';
import type { ColumnsType } from 'antd/lib/table';


const Table8 = () => {
  const columns = [
    {
      title: 'Height(cm)',
      dataIndex: 'height',
    },
    {
      title: 'Light',
      dataIndex: 'light',
    },
    {
      title: 'Moderate',
      dataIndex: 'moderate',
    },    
    {
      title: 'Dense',
      dataIndex: 'dense',
    },
  ];
  const data = [
    { key: 1,
      height: 1,
      light: 250,
      moderate: 400,
      dense: 500,
    }, 
    { key: 2,
      height: 2,
      light: 500,
      moderate: 700,
      dense: 800,
    },
    { key: 3,
      height: 3,
      light: 600,
      moderate: 1000,
      dense: 1100,
    }, 
    { key: 4,
      height: 4,
      light: 800,
      moderate: 1200,
      dense: 1400,
    }, 
    { key: 5,
      height: 5,
      light: 1000,
      moderate: 1400,
      dense: 1700,
    }, 
    { key: 6,
      height: 6,
      light: 1150,
      moderate: 1600,
      dense: 2000,
    }, 
    { key: 7,
      height: 7,
      light: 1300,
      moderate: 1750,
      dense: 2300,
    },   
    { key: 8,
      height: 8,
      light: 1450,
      moderate: 1900,
      dense: 2600,
    },  
    { key: 9,
      height: 9,
      light: 1600,
      moderate: 2000,
      dense: 2800,
    },
     { key: 10,
      height: 10,
      light: 1700,
      moderate: 2100,
      dense: 3000,
    },
  ];

  
  return (
    <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      
    </div>
  )
}


export default Table8;