import React from "react";
import { Button } from "antd";

const RightSection = (props) => {
  return (
    <div className="plan-card-container">
      <div className="plan-card">
        <p className="plan-name">Gold</p>
        <p className="description">
          We are giving you three months free on our premium subscription at no
          cost. You can cancel at anytime during the three months.
        </p>
        <p className="price">
          $<span>39.99</span>
          <span>/month</span>
        </p>
        <Button
          className="try-button"
          onClick={props.openTrialConfirmationModal}
        >
          Try 3 months
        </Button>
      </div>
    </div>
  );
};

export default RightSection;
