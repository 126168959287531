import moment from 'moment';
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

export const propertyHelper = {
  checkIsShowSatMap: (property, activePropertySubscription) => {
    if(!property){
      return false;
    }
    if(subscriptionHelper.checkIsGold(activePropertySubscription) || subscriptionHelper.checkIsSilver(activePropertySubscription)){
      return true;
    }
    if(property.created_at){
      let createdAt = moment.utc(new Date(property.created_at));
      let now = moment.utc(new Date());
      let diff = now.diff(createdAt, 'days');
      if(diff <= 30){
        return true;
      }
    }
    return false;
  },
}