import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const permissionService = {
  getPermissions,
  getPermission,
  getUserPermission,
  update
};

function getPermissions(){
  return (
    axios
      .get(
        apiEndpoint.permissions,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getUserPermission(property_id){
  return (
    axios
      .get(
        apiEndpoint.getUserPermission,
        {
          headers: authHeader(),
          params: {
            property_id
          }
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getPermission(property_permission_id){
  let permission = "";
  switch(property_permission_id) {
    case 1:
      // code block
      permission = "owner"
      break;
    case 2:
      // code block
      permission = "admin"
      break;
    case 3:
      // code block
      permission = "modify"
      break;
    case 4:
      // code block
      permission = "view"
      break;
    
    default:
      // code block
  }
  return permission;

}

function update(object){
  return(
    axios
      .put(
        apiEndpointFunction.permissions(object.id),
        object,
        {
          headers: authHeader(),
        }
      )
  )
}