import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DeviceTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  .battery-level{
    text-align: center;
  }
.rowClassName1 td:last-child {
  border-bottom-right-radius: 10px;
}
  .actionmenu{
    width: 106px;
    color : black;
    height : 33px;
    border-radius: 10px;
    background: #ECDD5D;
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
  }
  .title{
    color:rgb(248, 14, 70);
    padding : 10px 0px 0px 10px;
  max-width : 145px;
   height  : 40px;
  }
  .columnCust{
    padding : 0px 0px 10px 10px;
  }
  a{
    color: #729D68;
  }
  .ant-table-wrapper {
    min-width: 100%;
  }
  thead{
    text-align : left;
  }

  .react-collapsible-theme .react-collapsible thead > tr > th {
    background-color:white;
  }
  .table-striped-rows thead {
    background-color: #f1f1f1;
  }
  th.ant-table-cell{
    color:#729D68;
  }
  .ant-pagination-item-active{
    border-color: #729D68;

  }
  .ant-pagination-item-active a {
    color: #729D68;
  }
  .ant-select-device{
    font-size:14px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item{
    border-radius: 5px;
  }
  .ant-select,.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
    color:white;
  }
  .ant-device-search,.ant-device-search:hover{
    //  min-width : 137px;
     height : 40px;
     padding: 10px 16px 10px 16px ;
     border-radius : 8px !important;
     border: 1px solid #D0D5DD;

  }
  .ant-device-search .ant-input {
    border: 0px !important;
  }
  .ant-btn-add-device{
    color : white;
    // width: 73px;
    height : 40px;
    padding: 10px, 16px, 10px, 16px;
    border-radius: 8px;
    border: 1px;
    background: rgb(248, 14, 70);
    border: 1px solid #5E8D54;
    
  }
  .ant-btn-add-device:hover{
    opacity:.9;
  }
  .ant-popconfirm .ant-btn-primary{
    background: #729D68;
  }
  .manage-table{
    overflow : auto;
  }
  #addDevice .ant-input{
    padding: 8px;
    width: 100%;
  }
  #addDevice .ant-form-item{
    width: 60%;
  }
  @media(max-width:820px){
    #addDevice .ant-form-item{
      width:50%;
    }
  }
  .manage-table{
    border-top: 1px solid #EAECF0;
  }
 
  @media only screen and (max-width: 768px) {
   
    .columnCust .ant-input-affix-wrapper {
      margin-left : 10px;
      max-width : 95% !important;
    }
    .ant-btn-add-device{
      max-width : 100%;
      min-width : 40%;
    } 
    .manage-table{
      overflow : auto;
    }
  }
  @media only screen and (max-width: 375px) {
    .columnCust .ant-input-affix-wrapper {
      margin-left : 10px;
      max-width : 95% !important;
    }
    .ant-btn-add-device{
      max-width : 100%;
      min-width : 40%;
    } 
    .manage-table{
      overflow : auto;
    }

  }
 
     
`;
export default WithDirection(DeviceTableWrapper);