import React, { useState } from "react";
import { Row, Col } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import TrialSubscriptionWrapper from "./TrialSubscription.styles";
import TrialConfirmationModal from "./TrialConfirmationModal";
import SubscriptionModal from "./SubscriptionModal";
const isMobile = !isDesktop && !isTablet;

const TrialSubscription = (props) => {
  const [visibleTrialConfirmationModal, setVisibleTrialConfirmationModal] =
    useState(false);

  const [visibleSubscriptionModal, setVisibleSubscriptionModal] =
    useState(false);

  const cancelSubscriptionModal = () => {
    setVisibleSubscriptionModal(false);
  };

  const cancelTrialConfirmationModal = () => {
    setVisibleTrialConfirmationModal(false);
  };

  const openTrialConfirmationModal = () => {
    setVisibleTrialConfirmationModal(true);
  };

  return (
    <TrialSubscriptionWrapper>
      {isTablet || isMobile ? (
        <Row>
          <Col className="image-section" span={24}>
            <RightSection openTrialConfirmationModal={openTrialConfirmationModal}></RightSection>
          </Col>
          <Col span={24}>
            <LeftSection
              propertyId={props.propertyId}
              setStep={props.setStep}
              openTrialConfirmationModal={openTrialConfirmationModal}
            ></LeftSection>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={{ span: 24 }} lg={{ span: 11 }}>
            <LeftSection
              propertyId={props.propertyId}
              setStep={props.setStep}
              openTrialConfirmationModal={openTrialConfirmationModal}
            ></LeftSection>
          </Col>
          <Col
            className="image-section"
            md={{ span: 24 }}
            lg={{ span: 11, offset: 2 }}
          >
            <RightSection openTrialConfirmationModal={openTrialConfirmationModal}></RightSection>
          </Col>
        </Row>
      )}
      <TrialConfirmationModal
        showSubscriptionModal={() => {
          setVisibleSubscriptionModal(true);
        }}
        cancelTrialConfirmationModal={cancelTrialConfirmationModal}
        visibleTrialConfirmationModal={visibleTrialConfirmationModal}
      ></TrialConfirmationModal>
      <SubscriptionModal
        propertyId={props.propertyId}
        visibleSubscriptionModal={visibleSubscriptionModal}
        cancelSubscriptionModal={cancelSubscriptionModal}
      ></SubscriptionModal>
    </TrialSubscriptionWrapper>
  );
};

export default TrialSubscription;
