import React, { useState, useEffect } from 'react';
import { Marker } from 'react-leaflet';
import AssetModal from '@iso/containers/Pages/PropertyMap/components/Asset/AssetModal';
import EditTreatmentModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditTreatmentModal';
import EditFuelModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditFuelModal';
import EditFeedModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditFeedModal';
import EditWaterModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditWaterModal';
import EditAircraftModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditAircraftModal';
import EditGeneralAssetModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditGeneralAssetModal';
import EditAnimalModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditAnimalModal';
import EditWeatherModal from '@iso/containers/Pages/PropertyMap/components/Asset/EditWeatherModal';
import { healthsafety,assetService, primaryObjectService } from '@iso/services';
import { useSelector, useDispatch } from 'react-redux';
import modalActions from '@iso/redux/modal/actions';
import btnSaveAction from '@iso/redux/btnSave/actions';
import {  IconAsset  } from '@iso/containers/Pages/PropertyMap/Icons/IconAsset';
import _ from 'lodash';

const { show } = btnSaveAction;

const { openModal } = modalActions;

const AssetHealthSafety = (props) => {
  const [assets, setAssets] = useState({});
  const objectRef = React.useRef([]);
  const dispatch = useDispatch();

  const [dataAsset, setDataAsset] = useState({});
  const saveSuccess = useSelector((state) => state.btnSave.saveSuccess);
  const dataBtnSave = useSelector((state) => state.btnSave.data);
  const cancelSave = useSelector((state) => state.btnSave.cancelSave);
  const dataTransfer = useSelector((state) => state.btnSave.data);
  const modalData = useSelector((state) => state.modal.modalData);
  const [visibleAssetOther, setVisibilityAssetOther] = React.useState(false);


  const updateDataAssets = React.useCallback((propertyId) => {
    healthsafety.getHealthAndSafetyAssets(propertyId).then(res => {
      if(res.code === '0000'){
        setAssets(res.assets);
      }
    })
  }, [props.primaryObjects]);


  useEffect(() => {
    if(saveSuccess && dataBtnSave.type === 'area'){
      const primaryObjectId = dataBtnSave.dataId;
      const healthSafety = setTimeout(() => {
        updateDataAssets(props.propertyId)
      }, 500);
      return () => {
        clearTimeout(healthSafety);
      };
    }
  }, [saveSuccess, dataBtnSave]);

  useEffect(() => {
    if(props.propertyId){
      updateDataAssets(props.propertyId);
    }
  }, [updateDataAssets, props.propertyId]);

 
  const handleShowAssets = (data) => {
    var html = [];
    
    if(data.length > 0){
      _.forEach(data, function(asset){

        if(asset.lat && asset.lng && ( asset.type === "fire_extinguisher" || asset.type ==="first_aid_kit" || asset.type === "evacuation_point") ){
          html.push(
            <Marker 
              key={asset.id} 
              position={[asset.lat, asset.lng]}
              icon={IconAsset(asset.category, asset.tag_colour, asset.animal_type, asset.type)}
              onClick={handleClickAsset.bind(this, asset)}
              ref={el => (objectRef.current[asset.id] = el)}            
            />
          );
        }
      });
    }
    return html;
  }

  const handleClickAsset = (object, e) => {
      setDataAsset(object);
      setVisibilityAssetOther(true);
    
  }

  const onSaved = (savedAsset) => {
    let assetTmps = _.clone(assets);
    const assetIndex = _.findIndex(assetTmps, (asset) => {
      return asset.id === savedAsset.id
    });
    if(assetIndex === -1){
      assetTmps.push(savedAsset);
    } else {
      assetTmps[assetIndex] = savedAsset;
    }
    setAssets(assetTmps);
    props.updatePropertyDetail(props.propertyId);
  }

  const cancelEditOtherModal = () => {
    setVisibilityAssetOther(false);
  }


  return (
    <>
      {handleShowAssets(assets)}
      <EditGeneralAssetModal        
        updateDataAssets={updateDataAssets}
        propertyId={props.propertyId}
        primaryObjects={props.primaryObjects}
        onSaved={onSaved}
        visibleAssetOther={visibleAssetOther}
        dataAsset={dataAsset}
        cancelEditModal={cancelEditOtherModal}
      />

    </>
  )
}

export default AssetHealthSafety;