
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Checkbox,
  DatePicker,
  Alert,
  Upload,
  message,
  Switch,
  Select,
} from "antd";

import { profileService, googleService, emergencyService } from "@iso/services";
import userActions from "@iso/redux/user/actions";
import IntlMessages from "@iso/components/utility/intlMessages";
import PersonalWrapper from "./Personal.styles";
import moment from "moment";
import { dateHelper } from "@iso/lib/helpers/dateHelper";
import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import _ from "lodash";
import userpic from '@iso/assets/images/AirAgri-user-144.png';
import UploadAvatarModal from "./UploadAvatarModal";
import Dashboard from "../Dashboard/Dashboard";
import { WorkingRight } from '@iso/constants/WorkingRight'
import DeleteModal from "./DeleteModal";
const { store } = userActions;
const { Option } = Select;
let autoComplete;

function handleAddressAutoComplete(
  setAddress,
  autoCompleteRef,
  setAddressShortName,
  setPoint
) {
  if (autoCompleteRef.current && autoCompleteRef.current.input) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current.input,
      { types: ["address"] }
    );

    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(setAddress, setAddressShortName, setPoint);
    });
  } else {
    console.error("autoCompleteRef or its input property is null or undefined.");
  }
}


async function handlePlaceSelect(setAddress, setAddressShortName, setPoint) {
  const addressObject = autoComplete.getPlace();
  const fields = [
    {
      name: ["profile", "address"],
      value: addressObject.formatted_address,
    },
  ];
  for (var i = 0; i < addressObject.address_components.length; i++) {
    var addressType = addressObject.address_components[i].types[0];
    if (addressType === "country") {
      fields.push({
        name: "country",
        value: addressObject.address_components[i].long_name,
      });
    }
    if (addressType === "administrative_area_level_1") {
      fields.push({
        name: "state",
        value: addressObject.address_components[i].long_name,
      });
    }
  }
  setAddress(fields);
}

const Personal = (props) => {
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sharedForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingSharing, setLoadingSharing] = useState(false);
  const [checkingPassword, setCheckingPassword] = useState(true);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [privateChecked, setPrivateChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(0);
  const [mobilePhoneChecked, setMobilePhoneChecked] = useState(0);
  const [homePhoneChecked, setHomePhoneChecked] = useState(0);
  const [addressChecked, setAddressChecked] = useState(0);
  const [profileData, setProfileData] = useState({});
  const [imageId, setImageId] = useState("");
  const autoCompleteRef = useRef(null);
  const [userMetas, setUserMetas] = useState({});
   const [workingRightImageId, setWorkingRightImageId] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [visibleDltAvatar, setVisibleDltAvatar] = useState(false);
  const [visibleUploadAvatar, setVisibleUploadAvatar] = useState(false);
  const [workingRightData, setWorkingRightData] = useState();
  const [selectAvtarId, setSelectAvtarId] = useState();
  const [emergencyContact, setEmergencyContact] = useState([]);
  const [isAllow, setIsAllow] = useState(0);
  const user_id = props ? props.userId :'';
  const form_id =props ? props.formId :'';
  useEffect(() => {
   
      updateProfileData();
      updateEmergencyContact();
    
  }, [currentUser, user_id, form_id])

  
  const updateProfileData = () => {
    const getProfileFn =
      form_id && user_id
        ? profileService.getCheckinFormUserProfile(form_id, user_id)
        : profileService.getProfile();

   getProfileFn.then((res) => {
  if (res && res.code === "0000") {
    setProfileData(res.user);
    setUserMetas(res.user_metas);
    setIsAllow(res.user.share_with_property_owner ? 1 : 0);
  } else if (res && res.message) {
    message.error(res.message);
  } 

});


  };

  const updateEmergencyContact = () => {
    if (form_id && user_id) {
      emergencyService.getCheckinFormUserEmergency(form_id, user_id, "emergency_contact")
      .then((res) => {
        
        if (res && res.code === "0000") {
          setEmergencyContact(res.emergencies);
        }
      });
    }else if (currentUser) {
      emergencyService.getList(currentUser.id, "emergency_contact")
        .then((res) => {
          if (res.code === "0000") {
            setEmergencyContact(res.emergencies);
          }
        });
    }
  };
  useEffect(() => {
    if (autoCompleteRef) {
      document.addEventListener("google-script-loaded", handleAddress);
    }
  }, []);

  useEffect(() => {
    if (checkingPassword) {
      if (profileData.is_public == 0) {
        setPrivateChecked(true);
      }
      setEmailChecked(profileData.public_email);
      setMobilePhoneChecked(profileData.public_mobile_phone);
      setHomePhoneChecked(profileData.public_home_phone);
      setAddressChecked(profileData.public_address);
     

      setFields([
        {
          name: ["profile", "name"],
          value: profileData.name || "",
        },
        {
          name: ["profile", "mobile_phone"],
          value: profileData.mobile_phone || "",
        },
        {
          name: ["profile", "current_password"],
          value: "",
        },
        {
          name: ["profile", "password"],
          value: "",
        },
        {
          name: ["profile", "confirm_password"],
          value: "",
        },
        {
          name: ["profile", "email"],
          value: profileData.email || "",
        },
        {
          name: ["profile", "home_phone"],
          value: profileData.home_phone || "",
        },
        {
          name: ["profile", "surname"],
          value: profileData.surname || "",
        },
        {
          name: ["profile", "address"],
          value: profileData.address || "",
        },
        {
          name: ["profile", "date_of_birth"],
          value: profileData.date_of_birth
            ? moment(profileData.date_of_birth)
            : "",
        },
        {
          name: ['profile', 'working_right'],
          value: profileData?.working_right,
        },
        {
          name: ["profile", "is_public"],
          value: profileData.is_public,
        },
        {
          name: ["profile", "public_surname_option"],
          value: profileData.public_surname_option || "",
        },
        {
          name: ['emergency_contact', 'full_name'],
          value: emergencyContact && emergencyContact.length ? emergencyContact[emergencyContact.length -1]['full_name'] : '',
        },
        {
          name: ['emergency_contact', 'email'],
          value: emergencyContact && emergencyContact.length ? emergencyContact[emergencyContact.length -1]['email'] : '',
        },
        {
          name: ['emergency_contact', 'phone_number'],
          value: emergencyContact && emergencyContact.length ? emergencyContact[emergencyContact.length -1]['phone_number'] : '',
        },
        {
          name: ["profile", "share_with_property_owner"],
          value: profileData.share_with_property_owner || "",
        },

      ]);
    }
  }, [checkingPassword, profileData,emergencyContact]);

  const handleAddress = () => {
    handleAddressAutoComplete(setFields, autoCompleteRef);
  };
 

  const handleWorkingRight = (value) => {
    if (value === 0) {
      setWorkingRightData("Working Visa");
    } else {
      setWorkingRightData("Citizen");
    }
  }
  const renderOptionWorkingRights = () => {
    let options = [];
    _.forEach(WorkingRight, (workingRight, index) => {
      options.push(
        <Option key={index} value={workingRight.value}>
          {workingRight.label}
        </Option>
      );
    });
    return (
      <Select allowClear size="large" className="workingRights" onChange={handleWorkingRight}>
        {options}
      </Select>
    );
  };
  const handleSubmit = () => {
    
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const handleSharingSubmit = () => {
    setLoadingSharing(true);
    sharedForm
      .validateFields()
      .then((values) => {
        onStoreSharing(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoadingSharing(false);
      });
  };

  const onStoreSharing = (values) => {
    
    const data = {
      user: {
        ...values.profile,
        share_with_property_owner :isAllow,
        public_email: emailChecked,
        public_mobile_phone: mobilePhoneChecked,
        public_home_phone: homePhoneChecked,
        public_address: addressChecked,
      },
    };

   
    profileService.updateCurrentProfile(data).then((res) => {
      if (res.code === "0000") {
        dispatch(store());
        updateProfileData();
        //props.cancelModal();

        setTimeout(() => {
          setLoadingSharing(false);
          message.success("Save Successfully!");
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSharing(false);
          message.error("Save Fail!");
        }, 1000);
      }
    });
  };
  const onStore = async (values) => {
    const location_res = await googleService.getLatLng(values.profile.address);

    var point = {};
    var addressShortName = {};
    var addressLongName = {};
    if(location_res.results.length > 0 && location_res.results[location_res.results.length - 1].geometry){
      point = {
        lat: location_res.results[location_res.results.length - 1].geometry.location.lat,
        lng: location_res.results[location_res.results.length - 1].geometry.location.lng,
      };
    } else {
      setErrMsg("The address invalid");
    }
    
    values.profile.name = values.profile.name.trim();
    values.profile.mobile_phone = values.profile.mobile_phone.trim();
    if(values.profile.current_password === '' && values.profile.password === '' && values.profile.confirm_password === ''){
       const data = {
        "user":{
          ...values.profile,
          avatar : imageId,
          date_of_birth: values.profile.date_of_birth ? dateHelper.dateForAPI(values.profile.date_of_birth) : '',
          image_id:imageId,
          address: location_res.results[location_res.results.length - 1].formatted_address,
          point,
          working_right: workingRightData ? workingRightData : '',
        },
        "emergency_contact": {
          ...values.emergency_contact,
          id: emergencyContact && emergencyContact.length ? emergencyContact[emergencyContact.length -1]['id'] : null,
          type: 'emergency_contact'
        }
      }
      profileService.updateCurrentProfile(data).then(res => {
        if(res.code === '0000'){
          dispatch(store());
          //props.cancelModal();
          setCheckingPassword(true);
          updateProfileData();
          form.resetFields();
          setTimeout(() => {
            setLoading(false);
            message.success('Save Successfully!');
          }, 1000);
          
        } else {
          setTimeout(() => {
            setLoading(false);
            message.error('Save Fail!');
          }, 1000);
        }
      });
    }else{
        const current_pwd = {"current_password":values.profile.current_password};
        var res = await profileService.checkCurrentPassword(current_pwd, currentUser.id);
        if(res.code !== '0000'){
          setCheckingPassword(false);
          return false;
        } else {
          const data = {
            "user":{
              ...values.profile,
              date_of_birth: values.profile.date_of_birth ? dateHelper.dateForAPI(values.profile.date_of_birth) : '',
              image_id:imageId,
              address: location_res.results[location_res.results.length - 1].formatted_address,
              point
            },
            "user_meta": values.user_meta
          }
          profileService.updateCurrentProfile(data).then(res => {
            if(res.code === '0000'){
              dispatch(store());
              //props.cancelModal();
              setCheckingPassword(true);
              updateProfileData();
              form.resetFields();
              setTimeout(() => {
                setLoading(false);
                message.success('Save Successfully!');
              }, 1000);
            } else {
              setTimeout(() => {
                setLoading(false);
                message.error('Save Fail!');
              }, 1000);
            }
          });
        }
    }
  }
  const onChangeRadio = (e) => {
    if (e.target.value == 0) {
      setPrivateChecked(true);
    } else {
      setPrivateChecked(false);
    }
  };

  const onChangeEmail = (e) => {
    if (e.target.checked === true) {
      setEmailChecked(1);
    } else {
      setEmailChecked(0);
    }
  };

  const onChangeMobilePhone = (e) => {
    if (e.target.checked === true) {
      setMobilePhoneChecked(1);
    } else {
      setMobilePhoneChecked(0);
    }
  };

  const onChangeHomePhone = (e) => {
    if (e.target.checked === true) {
      setHomePhoneChecked(1);
    } else {
      setHomePhoneChecked(0);
    }
  };

  const onChangeAddress = (e) => {
    if (e.target.checked === true) {
      setAddressChecked(1);
    } else {
      setAddressChecked(0);
    }
  };

  const uploadProps = {
    name: "Image[file]",
    action: apiEndpoint.images,
    headers: authHeader(true),
    beforeUpload: (file, fileList) => {
      let $oldOne = $(".anticon-delete")[0];
      setTimeout(() => {
        if ($oldOne) $($oldOne).trigger("click");
      }, 100);
    },
    onChange: (response) => {
      if (response.file && response.file.status == "done") {
        setImageId(String(response.file.response.image.id));
      }
    },
    listType: "picture",
    multiple: false,
  };
  
  const showUploadAvatarModal = () => {
    setVisibleUploadAvatar(true);
  };

  const cancelUploadAvatarModal = () => {
    setVisibleUploadAvatar(false);
  };
  const handleDeleteImage = () => {
    setSelectAvtarId(profileData.images[profileData.images.length - 1].id);
    setVisibleDltAvatar(true);
  }
  const closeModal = () => {

    setVisibleDltAvatar(false);
  }
  const handleChangeAdHoch = (checked) => {
    setIsAllow(checked ? 1 : 0);
    
  };
  return (
    <>
      {/* <Row>
        <Col xs={{ span: 24 }} md={{ span: 11 }}> */}
      <PersonalWrapper className="profile-area statistic-card">
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          fields={fields}
        >
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              style={{ margin: "15px 15px 0 15px" }}
            />
          )}
          {form_id ? 
          <>
          <div className="form-title">
            <h3>
              {<IntlMessages id="profilePage.personalInformation.title" />}
            </h3>
          </div>
          <div className="form-body">
          <Row>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name={["profile", "name"]}
                  className="inputAable"
                 label="Name"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your name" />
                </Form.Item>
              </Col>
              </Row>
              <Row>
              <Col xs={{ span: 24 }} md={{ span: 24}}>
                <Form.Item
                  name={["profile", "surname"]}
              className="inputAable"
                 label='Surname'
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your surname" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  name={["profile", "email"]}
                  className="inputAable"
                  label= "Email"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your email" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        
          </>
          
         :<>
          <div className="form-title">
            <h3>
              {<IntlMessages id="profilePage.personalInformation.title" />}
            </h3>
          </div>
          <div className="form-body">
            <div className="btnGroup">
            <Row>
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
                
                  <img
                    className="profile-image"
                    style={{ width: "150px", height: "150px" }}
                    alt="Avatar"
                    src={profileData.images && profileData.images.length > 0 ?
                      process.env.REACT_APP_API_BASE_URL +
                      profileData.images[profileData.images.length - 1].path :userpic
                    }
                  />
                </Col>
              {/* <Col xs={{ span: 7 }} md={{ span: 7 }}> */}
              <Form.Item
                name={["profile", "choose_image"]}
                className="inputLabelCstm"
              // label="Upload Avatar"
              >
                <Upload {...uploadProps}>
                  <Button
                    className="uploadAvatar"
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name={["profile", "choose_image"]}
                className="inputLabelCstm"
              // label="Choose an image"
              >
                <Button
                  className="uploadImg"
                  icon={<UploadOutlined />}
                  onClick={showUploadAvatarModal}
                >
                  Choose an image
                </Button>
              </Form.Item>
              <Form.Item
                name={["profile", "choose_image"]}
                className="isoInputWrapper"
              // label="Choose an image"
              >
                <Button
                  className="DltImg"
                  // icon={<UploadOutlined />}
                  onClick={handleDeleteImage}
                >
                  Delete
                </Button>
              </Form.Item>
              {/* </Col> */}
              {/* <Col
                xs={{ span: 15, offset: 2 }}
                md={{ span: 15, offset: 2 }}
              >
                <Form.Item
                  name={["profile", "choose_image"]}
                  className="isoInputWrapper"
                  label="Choose an image"
                >
                  <Button
                    className="action-button"
                    icon={<UploadOutlined />}
                    onClick={showUploadAvatarModal}
                  >
                    Choose an image
                  </Button>
                </Form.Item>
              </Col> */}
            </Row>
            </div>
            <br />
            <br />
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["profile", "name"]}
                  className="inputAable"
                 label="Name"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["profile", "surname"]}
              className="inputAable"
                 label='Surname'
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your surname" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["profile", "date_of_birth"]}
                  className="inputAable"
                 label="Date of Birth"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                    },
                  ]}
                >
                  <DatePicker size="large" className="inputFieldCstm" style={{ width: "100%", border: 'none !important' }} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
              <Form.Item
            className="inputAable"
                  name={['profile', 'working_right']}
                  label="Working right"
                  rules={[
                    {
                      required: false,
                      message: 'This field is required',
                    },
                  ]}
                >
                  {renderOptionWorkingRights(WorkingRight)}
                </Form.Item>
              </Col>
            </Row>

            <div className="changePassword">
              <h4 className="PassInfo">
                {<IntlMessages id="Password Settings" />}
              </h4>
            </div>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["profile", "current_password"]}
                  className="inputLabelCstm"
                  label ="Current Password"
                 
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (!value &&
                            getFieldValue("profile").password === "") ||
                          (value && getFieldValue("profile").password !== "")
                        ) {
                          return Promise.resolve();
                        }
                        // return Promise.reject("This field is required!");
                      },
                    }),
                  ]}
                >
                  <Input
                    className="inputFieldCstm"
                    type="password"
                    size="large"
                    placeholder="Input your current password"
                  />
                </Form.Item>
              </Col>
              {!checkingPassword ? (
                <div className="ant-form-item-has-error">
                  <div className="ant-form-item-explain">
                    <div>Current Password is incorrect!</div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["profile", "password"]}
                  className="inputAable"
                 label= "New Password"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (!value &&
                            getFieldValue("profile").current_password === "") ||
                          getFieldValue("profile").current_password !== value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The New Password must be different from the Current Password!"
                        );
                      },
                    }),
                    {
                      min: 6,
                      message: "Password must be minimum 6 characters.",
                    },
                  ]}
                >
                  <Input
                    className="inputFieldCstm"
                    size="large"
                    type="password"
                    placeholder="Input your new password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}></Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["profile", "confirm_password"]}
                  className="inputAable"
                  label= "Confirm Password"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (!value &&
                            getFieldValue("profile").password === "") ||
                          getFieldValue("profile").password === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The New Password and Confirm Password do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    className="inputFieldCstm"
                    size="large"
                    type="password"
                    placeholder="Confirm new password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="changePassword">
              <h4 className="PassInfo">
                {<IntlMessages id="Contact Information" />}
              </h4>
            </div>
            {/* <div className="form-body"> */}
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["profile", "email"]}
                  className="inputAable"
                  label= "Email"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["profile", "mobile_phone"]}
                  className="inputAable"
                 label = "Mobile Phone"
                  rules={[
                    {
                      pattern: /^\d{10,12}$/, // Adjust this pattern as per your phone number format
                      message: 'Phone number must be 10 to 12 digits!',
                    },
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                   onKeyDown={(e) => {
                    // Allow only numbers (0-9) and specific control keys
                    if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                    className="inputFieldCstm"
                    size="large"
                    placeholder="Input your mobile phone number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={["profile", "home_phone"]}
                  className="inputAable"
                  label = "Home Phone"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                    {
                      pattern: /^\d{10,12}$/, // Adjust this pattern as per your phone number format
                      message: 'Phone number must be 10 to 12 digits!',
                    },
                  ]}
                >
                  <Input
                   onKeyDown={(e) => {
                    // Allow only numbers (0-9) and specific control keys
                    if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                    className="inputFieldCstm"
                    size="large"
                    placeholder="Input your home phone number"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                  name={["profile", "address"]}
                  className="inputAable"
                label= "Address"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className="inputFieldCstm"
                    size="large"
                    placeholder="Input your address"
                    ref={autoCompleteRef}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="changePassword">
              <h3 className="PassInfo">
                {<IntlMessages id="Emergency Contact" />}
              </h3>
            </div>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                   name={['emergency_contact', 'full_name']}
                   className="inputAable"
                  label= 'Full Name'
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input size="large" className="inputFieldCstm" placeholder="Input your full name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                <Form.Item
                name={['emergency_contact', 'email']}
                className="inputAable"
                label="Email"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className="inputFieldCstm"
                    size="large"
                    placeholder="Input your email"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item
                  name={['emergency_contact', 'phone_number']}

                  className="inputAable"
                label="Phone Number"
                  rules={[
                    {
                      required: false,
                      message: "This field is required",
                      whitespace: true,
                    },
                    {
                      pattern: /^\d{10,12}$/,
                      message: 'Phone number must be 10 to 12 digits!',
                    },
                  ]}

                >
                  <Input size="large"
                    onKeyDown={(e) => {
                      if (!/^\d+$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  className="inputFieldCstm" placeholder="Input your phone number" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col xs={{ span: 11 }} md={{ span: 11 }}></Col>
              <Col
                xs={{ span: 11, offset: 2 }}
                md={{ span: 11, offset: 2 }}
              >
                <Button
                  key="submit"
                  className="btn-success"
                  type="primary"
                  style={{ float: "right" }}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  {<IntlMessages id="propertyPage.modal.save" />}
                </Button>
              </Col>
            </Row>
            <div className="changePassword">
              <h4 className="PassInfo">
                {<IntlMessages id="SafeFarm community Settings" />}
              </h4>
            </div>
            <Form
              form={sharedForm}
              layout="vertical"
              scrollToFirstError
              fields={fields}
            >
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 11 }}>
                  <div>
                    <h3 className="shareToCommunity">
                      {<IntlMessages id="profilePage.shareToCommunity.title" />}
                    </h3>
                  </div>
                  <Form.Item
                    name={["profile", "is_public"]}
                    className="inputLabelCstm"
                    label=""
                  >
                    <Radio.Group onChange={onChangeRadio}>
                      <Radio value={1}>Public</Radio>
                      <Radio value={0}>Private</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <div>
                    <h3 className="shareToCommunity">
                      {<IntlMessages id="Surname" />}
                    </h3>
                  </div>

                  <Form.Item
                    name={["profile", "public_surname_option"]}
                    className="inputLabelCstm"
                   
                  >
                    <Radio.Group disabled={privateChecked}>
                      <Row>
                        <Radio value={1}>Share Initial</Radio>


                        <Radio value={2}>Share Surname</Radio>
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                </Col>
                <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                  <div>
                    <h3 className="shareToCommunity">
                      {<IntlMessages id="Shared with property owner" />}
                    </h3>
                  </div>
                  <Form.Item
                   name={["profile", "share_with_property_owner"]}
                    className="inputLabelCstm"
                    // valuePropName="checked"
                  >
                    <Switch
                   
                  //  unCheckedChildren="Deny"
                  //  checkedChildren="Allow"
                   checked={isAllow === 1}  // Set based on isAllow value
                   onChange={(checked) => handleChangeAdHoch(checked)}
                    /> <span>{isAllow === 1 ?"Allow" : "Deny"}</span>
                  </Form.Item>
                  <div>
                    <h3 className="shareToCommunity">
                      {<IntlMessages id="Contact Information" />}
                    </h3>
                  </div>
                  <Row>
                    <Col xs={{ span: 11 }} md={{ span: 11 }}>
                      <Checkbox

                        className="checkbox-contact"
                        disabled={privateChecked}
                        onChange={onChangeEmail}
                        checked={emailChecked}
                      ></Checkbox>
                      <span className="contCheckBox">Email</span>
                    </Col>
                    <Col
                      xs={{ span: 11, offset: 2 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Checkbox
                        className="checkbox-contact"
                        disabled={privateChecked}
                        onChange={onChangeMobilePhone}
                        checked={mobilePhoneChecked}
                      ></Checkbox><span className="contCheckBox">Mobile Phone</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={{ span: 11 }} md={{ span: 11 }}>
                      <Checkbox
                        className="checkbox-contact"
                        disabled={privateChecked}
                        onChange={onChangeHomePhone}
                        checked={homePhoneChecked}
                      ></Checkbox>
                      <span className="contCheckBox">Home Phone</span>

                    </Col>
                    <Col
                      xs={{ span: 11, offset: 2 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Checkbox
                        className="checkbox-contact"
                        disabled={privateChecked}
                        onChange={onChangeAddress}
                        checked={addressChecked}
                      ></Checkbox>
                      <span className="contCheckBox">Address</span>
                    </Col>

                  </Row>

                </Col>

              </Row>
              <Row></Row>

              <div className="form-body">

                <Row style={{ marginTop: "15px" }}>
                  <Col xs={{ span: 11 }} md={{ span: 11 }}></Col>
                  <Col
                    xs={{ span: 11, offset: 2 }}
                    md={{ span: 11, offset: 2 }}
                  >
                    <Button
                      key="submit"
                      className="btn-success"
                      type="primary"
                      style={{ float: "right" }}
                      onClick={handleSharingSubmit}
                      loading={loadingSharing}
                    >
                      {<IntlMessages id="propertyPage.modal.save" />}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
            {/* </div> */}
          </div>
          </> }
        </Form>
        <DeleteModal
          visible={visibleDltAvatar}
          closeModal={closeModal}
          selectAvtarId={selectAvtarId}
          updateProfileData={updateProfileData}
        />
        <UploadAvatarModal
          visibleUploadAvatar={visibleUploadAvatar}
          cancelUploadAvatarModal={cancelUploadAvatarModal}
          updateProfileData={updateProfileData}
        />
      </PersonalWrapper>

    </>
  );
};

export default Personal;
