import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DeviceStatusLightWrapper = styled.div`
  cursor: pointer;
  .circle-light-color {
    width: 8px;
    height: 8px;
    background: #8080806e;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
  }
  .circle-light-color:after {
    content: "";
    border: 2px solid #8080806e;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 7px;
  }
  .border-box {
    width: 70px;
    margin: 0px ;
    padding: 0px;
    border-radius: 40px;
    border: 1px solid #80808040; 
    -webkit-box-shadow: 0px 0px 3px 0px rgba(207,205,207,1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(207,205,207,1);
    box-shadow: 0px 0px 3px 0px rgba(207,205,207,1);
    position: relative;
  }
  .border-box-inner{
    border: 1px solid #80808040; 
    border-radius: 40px;
    padding: 12px 8px;
  }

  .circle-light-color.red{
    background-color:#ea5252;
  }  

  .circle-light-color.yellow{
    background-color:#f6cf67;
  } 

  .circle-light-color.green{
    background-color:#00965b;
  }
`;
export default WithDirection(DeviceStatusLightWrapper);