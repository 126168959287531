import { propertyService } from '@iso/services';

const actions = {
  STORE_PROPERTIES: 'STORE_PROPERTIES',
  CHANGE_ACTIVE_TRIAL_SATELLITE_EXPIRED: 'CHANGE_ACTIVE_TRIAL_SATELLITE_EXPIRED',
  
  changeActiveTrialSatelliteExpired: (activePropertyTrialSatelliteExpired) => {
    return dispatch => {
      dispatch(changeActiveTrialSatelliteExpired(activePropertyTrialSatelliteExpired));
    }
  }
};

function storeProperties(properties) {
  return {
    type: actions.STORE_PROPERTIES,
    properties,
  }
}

function changeActiveTrialSatelliteExpired(activePropertyTrialSatelliteExpired){
  return {
    type: actions.CHANGE_ACTIVE_TRIAL_SATELLITE_EXPIRED,
    activePropertyTrialSatelliteExpired
  }
}

export default actions;
