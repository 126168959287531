import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const PropertyModalWrapper = styled(Modal)`
  &.user-access-modal {
    width: 640px !important;
  }
  .ant-timeline-item-head.ant-timeline-item-head-green {
    color: #f80e46;
    border-color: f80e46;
}

.modal-title-style form .form-body input.ant-input:focus::placeholder {
  color: gray;
}
.ant-select-focused .ant-select-selector {
  border-color: red !important;
}

.ant-select-focused .ant-select-selection-placeholder {
  color: gray !important;
}


.modal-title-style form .form-body input.ant-input:focus,
.modal-title-style form .form-body .ant-select-selector :focus{
  background: rgb(247, 247, 247) !important;
}
.ant-form-item-control-input-content .inputFieldCstm:focus {

border-color: red !important; 
  box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
}
svg.brand-primary-light{
  color: #f80e46;
}
  .ant-btn-primary {
    background-color: #f80e46 !important;
    border-color :  #f80e46 !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .btn-success {
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .form-title {
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3 {
      font-size: 14px;
      color: #f80e46;
    }
  }
  .form-body {
    .ant-form-item.isoInputWrapper {
      margin-bottom: 10px;
  }
   padding: 16px;
    .checkbox-contact {
      margin-bottom: 10px;
    }
      .ant-form-item-control-input-content {
        display: inline-flex;
      } 
    .PicInput{
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px 0px 0px 8px;
      border-top: 1px solid #D0D5DD;
      border-bottom: 1px solid #D0D5DD;
      border-left: 1px solid #D0D5DD;
      background: var(--base-white, #FFF);
    }
    .yarnbtn{
      display: flex;
      // margin-right: 5px
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      // gap: 8px;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #D0D5DD;
      background: #EDEDED;
    }
   
  }
  .btn-gray {
    background-color: #d3d3d3;
    border-color: #a9a9a9;
    width:100%;
  }
  .btn-gray:hover {
    color: #fff;
  }
  .btn-gray:focus {
    color: #fff;
  }
  .ant-modal-close {
    color: #d92550;
    .ant-modal-close-x {
      span {
        border: 1.5px solid #d92550;
        border-radius: 50%;
        padding: 5px;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .ant-modal-header {
    background-color: #3f3f44;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .ant-modal-title {
      color: #ffffff;
    }
  }
  .ant-timeline {
    li {
      .ant-timeline-item-head {
        display: none;
      }
      .ant-timeline-item-content {
        margin-left: 0;
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 12px;
        }
        .ant-card {
          border: 1px solid #eaecf0;
          border-radius: 10px;
        }
      }
    }
  }
  .ant-modal-body {
    .sc-bvgPty {
      div {
        overflow: unset !important;
        height: auto !important;
      }
    }
    .ant-form {
      .ant-select {
        .ant-select-selector {
          border: 1px solid #d0d5dd !important;
          border-radius: 8px;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 16px 30px 24px;
    .ant-btn {
      width: 100%;
      background-color: #3f3f44;
      padding: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #ffffff;
      text-align: center;
      height: auto;
      &:hover {
        border-color: transparent;
      }
    }
  }
`;

export default WithDirection(PropertyModalWrapper);
