import React from "react";
import logo from "@iso/assets/images/logo.png";

const RightSection = () => {
  return (
    <img className="large-logo" src={logo} />
  );
};

export default RightSection;
