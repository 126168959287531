import styled from 'styled-components';


const TrafficLightGroupWrap = styled.div`
  // width: 100%;
  // height: auto;
  margin-bottom: 15px;
  span.traffic-light-label {
    width: 100%;
    padding: 15px 5px;
    border-radius: 5px;
    display: block;
    text-align: center;
    line-height: 1;
    font-weight: bold;
  }
 
`;

export default TrafficLightGroupWrap;