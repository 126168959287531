export const licenceTypes = [
  {
    label: 'Vehicles',
    value: 'vehicles',
  },
  {
    label: 'Machinery',
    value: 'machinery',
  },
  {
    label: 'Other',
    value: 'other',
  }
]