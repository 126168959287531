import React, {useState, useRef,useEffect } from 'react';
import { Row, Col } from 'antd';
import DeviceMap from './DeviceMap';
import { useSelector} from 'react-redux';
// import { iotDevice } from '@iso/services';
import { iotDevice } from '../../../services/iotdevice.service';
import Box from '@iso/components/utility/box';
import DeviceTable from './DeviceTable';
import AlertDevice from './AlertDevice';
import SafeFarmWrapper from './SafeFarm.styles'; 
import IntlMessages from '@iso/components/utility/intlMessages';
import { propertyService } from '@iso/services';

import _ from 'lodash';
import L from 'leaflet';

const SafeFarm = () => {

  const mapRef = useRef();
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [positionDevice, setPositionDevice] = useState();
  const [centerPoint, setCenterPoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [showMarkerAlert, setShowMarkerAlert] = useState(false);
  const [propertyPosition, setPropertyPosition] = useState(null);
  
  const viewProperty = (id) => {
    propertyService.viewProperty(id).then(res => {
      if(res.code === '0000'){
        setPropertyPosition([res.property.lat, res.property.lng]);
        setPrimaryObjects(res.primary_objects);
      }
    })
  }

  useEffect(() => {
    if(activePropertyId){
      viewProperty(activePropertyId);
    }
  },[activePropertyId]);

  const getPositionDevice = () =>{
    const {current = {}} = mapRef;
    const {leafletElement: map} = current;
    let position = [];
    iotDevice.positionDevice(activePropertyId).then(res => {
      if(res.code === '0000'){
        setPositionDevice(res.positions);
        setLoading(false);
        if(res.positions && res.positions.length > 0){
          _.forEach(res.positions, function(data){
            if(data.position && data.position.length > 0){
              _.forEach(data.position, function(p){
                if(p.latitude && p.longitude){
                  position.push([p.latitude,p.longitude]);
                }
              });
            }
          });
          let bounds = new L.LatLngBounds(position);
          let center = bounds.getCenter();
          setCenterPoint(center);
          if(position.length > 1){
            map.fitBounds(bounds,{padding: [30, 30]});
          }
        }
      }  
    });
  }

  return (
    <SafeFarmWrapper>
      <Row gutter={[15,15]}>
        <Col xs={{span: 24}} md={{span:24}} lg={{span: 24}}>
          <Box className="statistic-card">
            <DeviceMap 
              mapRef={mapRef}
              propertyId={activePropertyId} 
              setLoading={setLoading} 
              loading={loading} 
              getPositionDevice={getPositionDevice} 
              centerPoint={centerPoint} 
              positionDevice={positionDevice}
              showMarkerAlert={showMarkerAlert}
              setShowMarkerAlert={setShowMarkerAlert}
              propertyPosition={propertyPosition}
              primaryObjects={primaryObjects}
            />
          </Box>
        </Col> 
        <Col xl={12} md={24} sm={24} xs={24}>
        
          <Box className="statistic-cards">
           
            <AlertDevice
              propertyId={activePropertyId}
              setPositionDevice={setPositionDevice}
              setCenterPoint={setCenterPoint}
              setShowMarkerAlert={setShowMarkerAlert}
            />
          </Box>
        </Col>
        <Col xl={12} md={24} sm={24} xs={24}>
          <Box className="statistic-cards">
          
            <DeviceTable
              propertyId={activePropertyId}
              getPositionDevice={getPositionDevice}
            />
          </Box>
        </Col>
      </Row> 
    </SafeFarmWrapper>
  );
}

export default SafeFarm;
