import _ from "lodash";
import PropertyArea from "./Icons/PropertyArea";
import Dashboard from "./Icons/Dashboard";
import Employee from "./Icons/Employee";
import PropertyMap from "./Icons/PropertyMap";
import Hazards from "./Icons/Hazards";
import DefaultProperty from "./Icons/DefaultProperty";
import Tasks from "./Icons/Tasks";
import marketplace from "./Icons/marketplace.png";
import HealthSafety from "./Icons/HealthSafety";
import React from "react";
import Propertymapicon from "./Icons/Propertymapicon";
import Weather from "./Icons/Weather";
import ManageAssets from "./Icons/ManageAssets";
import './Sidebar.styles'
import Assets from "./Icons/Assets";
import Visitors from "./Icons/Visitors";


export function options(properties, activeId = null,currentUser, is_system_admin = null) {
  var shareWithPropertyOwner =  currentUser.share_with_property_owner;
  var options =
    is_system_admin !== 1
      ? [
        {
          key: "dashboard",
          label: "sidebar.dashboard",
          leftIcon: <Dashboard />,
        },
        {
          key: "manage-properties",
          label: "Properties",
          leftIcon: <PropertyMap />,
        },
        {
          key: "property-map",
          label: "Map",
          leftIcon: <Propertymapicon />,
        },
        {
          key: "property-area",
          label: "Area",
          leftIcon: <PropertyArea />,
        },
        {
          key: "hazards",
          label: "sidebar.hazards",
          // leftIcon : <img src={process.env.PUBLIC_URL + "./images/icons/HazardIcon.png"} style={{ width: "25px", height: "25px", marginRight: '8px'}} alt="Hazards"></img>,
          leftIcon: <Hazards />,
        },
        {
          key: "visitor",
          label: "sidebar.visitors",
          leftIcon: <Visitors/> ,
          // leftIcon: <img src={process.env.PUBLIC_URL + "./images/icons/visitors.png"} style={{ width: "25px", height: "25px", marginRight: '16px',borderColor:'black' }} alt="HealthSafety"></img>,
        },
        {
          key: 'manage-assets',
          label: 'sidebar.manageAsset',
          // leftIcon: <img src={process.env.PUBLIC_URL + "./images/icons/safety_assets.png"} style={{ width: "25px", height: "25px", marginRight: '8px' }} alt="HealthSafety"></img>,
          leftIcon: <Assets/>,
        },
       
        {
          key: "employee",
          label: "sidebar.employee",
          leftIcon: <Employee />,
        },
        {
          key: "tasks",
          label: "sidebar.tasks",
          leftIcon: <Tasks />,
        },
        {
          key: "weather",
          label: "sidebar.weather",
          leftIcon: <Weather />,
        },
        {
          key: "health-safety",
          label: "sidebar.healthSafety",
          // leftIcon: <img src={process.env.PUBLIC_URL + "./images/icons/HealthSafety.svg"} style={{ width: "25px", height: "25px", marginRight: '15px' }} fill = "red" alt="HealthSafety"></img>,
          leftIcon: <HealthSafety/>,
        },
        {
          key: "safe-farm",
          label: "sidebar.safeFarm",
          leftIcon: <Assets/>,
        },
      ]
      : [
        {
          key: "admin-dashboard",
          label: "sidebar.adminDashboard",
          leftIcon: <Employee />,
        },
        {
          key: "manage-product-category",
          label: "sidebar.manageProductCaregory",
          leftIcon: <Employee />,
        },
        {
          key: "manage-product",
          label: "sidebar.manageProduct",
          leftIcon: <Employee />,
        },
        {
          key: "manage-property",
          label: "sidebar.manageProperty",
          leftIcon: <Employee />,
        },
        {
          key: "manage-user",
          label: "sidebar.manageUser",
          leftIcon: <Employee />,
        },
        {
          key: "manage-sample-avatar",
          label: "sidebar.manageSampleAvatar",
          leftIcon: <Employee />,
        },
        {
          key: "manage-form",
          label: "sidebar.manageForm",
          leftIcon: <Employee />,
        },
        {
          key: "category-form",
          label: "sidebar.categoryForm",
          leftIcon: <Employee />,
        },
      ];

  // const activeProperty = JSON.parse(localStorage.getItem("active_property"));
  // if (activeProperty && is_system_admin !== 1) {
  //   options.unshift({
  //     key: "dashboard",
  //     label: activeProperty.name,
  //     leftIcon: <DefaultProperty />,
  //   });
  // }
  if (!shareWithPropertyOwner) {
    options = options.filter(option => option.key !== "employee");
  }
  return options;
}
