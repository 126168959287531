import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .ant-btn{
    border-radius: 4px;
  }
  .ant-spin-dot-item {
    background-color: #f80e46;
  }
  .ant-popover.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-inner{
    border-radius : 10px !important;
  }
  .leaflet-tile-dark {
    .leaflet-tile {
      -webkit-filter: brightness(60%)!important;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    "" /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
  border: 1px solid #F80E46!important;
  box-sizing: border-box!important;
  border-radius: 5px!important;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.deleteButton:hover{
  background-color:#f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:#F80E46;
}
.action-button.ant-btn:hover, .action-button.ant-btn:focus{
  color:#F80E46;
  border-color:#F80E46;
}
.ant-input:focus, .ant-input-focused, .ant-select:focus, .ant-select-focused, .ant-input-number-focused, .ant-input-number:focus{
  // border-color:#F80E46;
  // box-shadow: 0 0 0 2px #F80E46;
  
}
.ant-input:hover, .ant-select:hover, .ant-input-number:hover{
  border-color:#F80E46;
}

.cancel-button.ant-btn:hover, .history-button:hover, .history-button:focus{
  color:#F80E46;
  border-color:#F80E46;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color:#F80E46;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-color:#F80E46;
  
}
.ant-picker-active-bar{
  background:#F80E46 !important;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-radio-checked .ant-radio-inner{
  border-color:#f80e46;
}
.ant-radio-inner::after{
  background-color:#F80E46;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color:#f80e46;
  border-color:#f80e46;
  border-radius: 4px;
}

.ant-checkbox-checked::after{
  border-color : #f80e46 !important;
}
span.ant-checkbox-inner :hover{
  border-color : #f80e46 !important;
}
 .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-checked:hover, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked:hover{
  border-color : #f80e46 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: #F80E46;
}
.ant-picker-today-btn, .ant-picker-header-view button:hover{
  color: #F80E46;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid #F80E46;
}

.ant-tabs-tab.ant-tabs-tab-active, .ant-tabs-tab-btn.ant-tabs-tab-btn:active{
  color: #F80E46!important;
  .ant-tabs-tab-btn {
    color: #F80E46!important;
  }

}
.ant-tabs-tab:hover{
  color: #F80E46
}

.ant-tabs-ink-bar {
   background: #F80E46;
}

.sidebar-icon{
  stroke: rgb(143, 148, 151) !important;
  margin-right: 10px;
  // opacity: 0.5;
  display: inline-flex;
}

.ant-layout-sider-collapsed .sidebar-icon{
    // height: 10%;
    // width: 70%;
    // margin-right: 10px;
    // margin-bottom: 30px;
    // opacity: 0.5;
    // padding-top: 27px;
    min-width: 22px;
    margin-right: 10px;
    display: inline-flex;
    stroke: rgb(143, 148, 151) !important;
}

#custom-menu-icon:hover{
  .sidebar-icon{
    opacity: 0.7;
  }
}

.ant-menu-item-selected .sidebar-icon{
  opacity: 1;
  g.sidebar-icon {
    fill: red;
}
  svg path {
    stroke: #F80E46;
    .fillClass{
      fill : red ;
    }
  
  }

}

.fc-cal-header-container{
  background-color: #65BB38!important;
}

.fc-cal-user-selecteddatedefault{
  background-color: #65BB38!important;
}

.fc-cal-user-selecteddatedefault:hover{
  background-color: #65BB38!important;
}
#zoom-default {
  position: absolute;
  bottom: 70px;
  left: 9px;
  z-index: 400;
  width: 28px;
  height: 28px;
  padding: unset!important;
}

#zoom-default:hover{
    cursor: pointer;
}

.ant-switch-checked{
  background-color: #F80E46;
}

.ant-checkbox-group-item {
    display: block;
    margin-right: 0;
}

.ant-input-number, .ant-input, .ant-picker, .react-tagsinput {
  border: 1px solid #e9e9e9;
  box-sizing: border-box!important;
  border-radius: 5px!important;
}

.ant-select-selector{
  border-radius: 5px!important;
  border: 1px solid #F80E46 !important;
}


.ant-modal-title {
  color: #F80E46;
}

.ant-btn {
  border-radius: 8px;
  border: 1px solid transparent;
}

.ant-modal-content, .ant-modal-header {
  border-radius: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #FFFFFF;
}

.ant-picker-dropdown .ant-picker-cell-range-end > .ant-picker-cell-inner{
  background: rgb(239, 203, 25) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #fdffd6;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #fdffd6;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before{
  background: #fdffd6;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner{
  background: rgb(239, 203, 25) !important;
}
.menu-item-custom.ant-menu-item {
  &:active {
    background-color: #f5f5f5;
  }
}
.menu-item-custom.ant-menu-item-selected {
  background-color: #f5f5f5 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #F80E46;
}
.search-bar {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 12px;
  border: none;
  margin-left: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row-reverse;
  max-width: 228px;
}
.site-form-item-icon {
  height: 19px;
  width: 19px;
  color: #8F92A1;
  font-size: 19px;
}
.site-form-item-cancel {
  height: 15px;
  width: 15px;
  color: #8F92A1;
  font-size: 15px;
}
.sidebar-icon{
  margin-right: 9px;
}

.sidebar-icon.visitors-icon{
  margin-right: 19px;
  display:flex;
}

.ant-menu-item-selected .sidebar-icon.visitors-icons svg path:first-child {
  stroke: none;
}

.sidebar-icons g{
  margin-right : 9px ;
  fill: rgb(143, 148, 151) !important;
  
  stroke-width:2 !important;
  stroke-linecap:round !important;
  stroke-linejoin:round !important;
}
.ant-form-item-control-input-content .inputFieldCstm:focus {

  border-color: red !important; 
    box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
}

// .ant-picker:hover, .ant-picker-focused{
//   box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
// }
.sidebar-icons g:active{
  fill: #F80E46 !important;

}
.sidebar-icons{
  margin-right : 9px;
}
.ant-menu-item-selected .sidebar-icons{
  
  opacity: 1;
  g.sidebar-icons{
    fill: #F80E46 !important;
}
  svg path {
    stroke: #F80E46 !important;
    fill:#F80E46 !important;
    .fillClass{
      fill : #F80E46 !important;
    }
  }
}
.ant-form-item-control-input-content .inputFieldCstm:focus {
  border-color: red !important; 
  box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
}
input:focus {
  box-shadow:rgb(248, 14, 70) !important; 
}
.ant-picker.ant-picker-large.ant-picker-focused{
  border-color: red !important; 
  box-shadow :rgb(248, 14, 70)  0px 0px 0px 0.5px !important; 
}
. {
  color: #f80e46 !important;
  width: 1.7em !important;
  height: 1.7em !important;
}
.dotIcon svg{
  color: #f80e46 !important;
  width: 1.8em !important;
  height: 1.5em !important;
}

.modal-title-style form .form-body input.ant-input:focus::placeholder {
  color: gray;
}
.modal-title-style form .form-body input.ant-input:focus,
.modal-title-style form .form-body .ant-select-selector :focus{
  background: rgb(247, 247, 247) !important;
}
.ant-picker.ant-picker-large.ant-picker-focused{
  box-shadow:none !important;
  background: rgb(247, 247, 247) !important;

}
.ant-picker.ant-picker-large.ant-picker-focused ::placeholder{
  color:gray;
}
.ant-select-focused .ant-select-selector .ant-select-selection-search-input:focus {
  border-color:red !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow:none !important;
  border-color :red !important;
  background: rgb(247, 247, 247) !important;

}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector ::placeholder{
  color:gray !important;
}
.react-tagsinput input.focused {
  background: rgb(247, 247, 247) !important;
    border-color: red !important;
    box-shadow: none !important;
}
input.react-tagsinput-input:focused{
  background: rgb(247, 247, 247) !important;
}
`;

export default GlobalStyles;
