import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm, Timeline, Card, Row, Col } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { activityService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import PropertyManagePage from './Property.styles';

const AssetHistory = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const dispatch = useDispatch();
  const [assetHistory, setAssetHistory] = useState([]);

  useEffect(() => {
    if(props.propertyId){
       activityService.getHistoryByProperty(props.propertyId, 'facility').then(res => {
        if(res.code === '0000'){
          setAssetHistory(res.activities.reverse());
        }
      });
    }
  }, [props.propertyId]);

  return (
    <PropertyManagePage>
      <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
      <br/>
        <Timeline>
         {(assetHistory) ? (assetHistory.map((value, index) => {
          if(value.metadata.usage){
            return (
            <Timeline.Item color="green" key={index}>
              <p>{moment(value.created_at).format('ll')}</p>
              <br/>
              <h3>{value.object_name}</h3>
              <Card title='Usage' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.type}</b>
                  </Col>
                </Row>
                {(value.metadata.usage.type === 'Hours') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Hours</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.total_hours}</b>
                  </Col>
                </Row>}
                {(value.metadata.usage.type === 'Volume') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Volume</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.total_volume}</b>
                  </Col>
                </Row>}
                {(value.metadata.usage.type === 'Other') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Other Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.other_type}</b>
                  </Col>
                </Row>}
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Date</p> 
                  </Col>
                    
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                  <b className="card_value">{moment(value.metadata.usage.date).format('ll')}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Location</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.location}</b>
                  </Col>
                </Row>
                {(value.metadata.usage.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.usage.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.service){
            return (
            <Timeline.Item color="green" key={index}>
              <p>{moment(value.created_at).format('ll')}</p>
              <br/>
              <h3>{value.object_name}</h3>
              <Card title='Service' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.type}</b>
                  </Col>
                </Row>
                {(value.metadata.service.type === 'Hours') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Hours</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.total_hours}</b>
                  </Col>
                </Row>}
                {(value.metadata.service.type === 'Volume') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Volume</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.total_volume}</b>
                  </Col>
                </Row>}
                {(value.metadata.service.type === 'Other') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Other Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.other_type}</b>
                  </Col>
                </Row>}
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Date</p> 
                  </Col>
                    
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                  <b className="card_value">{moment(value.metadata.service.date).format('ll')}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Location</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.location}</b>
                  </Col>
                </Row>
                {(value.metadata.service.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.service.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.refill){
            return (
            <Timeline.Item color="green" key={index}>
              <p>{moment(value.created_at).format('ll')}</p>
              <br/>
              <h3>{value.object_name}</h3>
              <Card title='Refill' bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.type}</b>
                  </Col>
                </Row>
                {(value.metadata.refill.type === 'Hours') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Hours</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.total_hours}</b>
                  </Col>
                </Row>}
                {(value.metadata.refill.type === 'Volume') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Volume</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.total_volume}</b>
                  </Col>
                </Row>}
                {(value.metadata.refill.type === 'Other') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Other Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.other_type}</b>
                  </Col>
                </Row>}
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Date</p> 
                  </Col>
                    
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                  <b className="card_value">{moment(value.metadata.refill.date).format('ll')}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Location</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.location}</b>
                  </Col>
                </Row>
                {(value.metadata.refill.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.refill.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }

          if(value.metadata.other){
            return (
            <Timeline.Item color="green" key={index}>
              <p>{moment(value.created_at).format('ll')}</p>
              <br/>
              <h3>{value.object_name}</h3>
              <Card title={value.metadata.other.other_category} bordered={true}>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.type}</b>
                  </Col>
                </Row>
                {(value.metadata.other.type === 'Hours') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Hours</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.total_hours}</b>
                  </Col>
                </Row>}
                {(value.metadata.other.type === 'Volume') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Total Volume</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.total_volume}</b>
                  </Col>
                </Row>}
                {(value.metadata.other.type === 'Other') && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Other Type</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.other_type}</b>
                  </Col>
                </Row>}
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Date</p> 
                  </Col>
                    
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                  <b className="card_value">{moment(value.metadata.other.date).format('ll')}</b>
                  </Col>
                </Row>
                <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Location</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.location}</b>
                  </Col>
                </Row>
                {(value.metadata.other.description) && <Row>
                  <Col xs={{span: 11}} md={{span: 11}}>
                    <p className="card_content">Description</p> 
                  </Col>
                   
                  <Col xs={{span: 11, offset: 2}} md={{span: 11, offset: 2}}>
                   <b className="card_value">{value.metadata.other.description}</b>
                  </Col>
                </Row>}
              </Card>
            </Timeline.Item>
            );
          }  
        })) : ''}
        </Timeline>
      </div>
    </PropertyManagePage>
  )
}

export default AssetHistory;