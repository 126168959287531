export const livestockSex = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Heifer',
    value: 'heifer',
  },
  {
    label: 'Steer',
    value: 'steer',
  },
  {
    label: 'Cow',
    value: 'cow',
  },
  {
    label: 'Bull',
    value: 'bull',
  },
  {
    label: 'Calf',
    value: 'calf',
  },
  {
    label: 'Terminal',
    value: 'terminal',
  },
  {
    label: 'Wether',
    value: 'wether',
  },
  {
    label: 'Ewe',
    value: 'ewe',
  },
  {
    label: 'Ram',
    value: 'ram',
  },
  {
    label: 'Lamb',
    value: 'lamb',
  },
  {
    label: 'Stallion',
    value: 'stallion',
  },
  {
    label: 'Stud',
    value: 'stud',
  },
  {
    label: 'Filly',
    value: 'filly',
  },
  {
    label: 'Colt',
    value: 'colt',
  },
  {
    label: 'Mare',
    value: 'mare',
  },
  {
    label: 'Gelding',
    value: 'gelding',
  },
  {
    label: 'Board',
    value: 'board',
  },
  {
    label: 'Gilt',
    value: 'gilt',
  },
  {
    label: 'Sow',
    value: 'sow',
  },
  {
    label: 'Cockerels',
    value: 'cockerels',
  },
  {
    label: 'Pullets',
    value: 'pullets',
  },
  {
    label: 'Hen',
    value: 'hen',
  },
  {
    label: 'Rooster',
    value: 'rooster',
  },
  {
    label: 'Doe',
    value: 'doe',
  },
  {
    label: 'Nanny',
    value: 'nanny',
  },
  {
    label: 'Billy',
    value: 'billy',
  }
]