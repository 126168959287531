import React, { useState, useEffect } from "react";
import BlueIcon from "@iso/assets/images/welcomeModals/property-blue-icon.png";
import RedIcon from "@iso/assets/images/welcomeModals/property-red-icon.png";
import GreenIcon from "@iso/assets/images/welcomeModals/property-green-icon.png";
import PurpleIcon from "@iso/assets/images/welcomeModals/property-purple-icon.png";
import { propertyService } from "@iso/services";

const RightSection = () => {
  const [data, setData] = useState({
    number_of_properties: 0,
    number_of_livestocks: 0,
    number_of_mobs: 0,
    number_of_assets: 0,
  });

  useEffect(()=>{
    propertyService.getGeneralStatistic().then(res => {
      if(res.code === '0000'){
        setData(res.data)
      }
    })
  }, [])
  return (
    <div className="right-section">
      <div className="group-two">
        <div className="group-card group-card-1">
          <div className="headers">
            <img src={BlueIcon}></img>
            <p>Properties</p>
          </div>
          <p className="quantity">
            {data.number_of_properties.toLocaleString()}
          </p>
        </div>
        <div className="group-card group-card-2">
          <div className="headers">
            <img src={RedIcon}></img>
            <p>Livestock</p>
          </div>
          <p className="quantity">{data.number_of_livestocks.toLocaleString()}</p>
        </div>
      </div>
      <div className="group-two">
        <div className="group-card group-card-3">
          <div className="headers">
            <img src={GreenIcon}></img>
            <p>Mobs</p>
          </div>
          <p className="quantity">{data.number_of_mobs.toLocaleString()}</p>
        </div>
        <div className="group-card  group-card-4">
          <div className="headers">
            <img src={PurpleIcon}></img>
            <p>Assets</p>
          </div>
          <p className="quantity">{data.number_of_assets.toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};

export default RightSection;
