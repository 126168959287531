export const taskStatus = [
  {
    label: 'To Do',
    value: 'todo',
  },
  {
    label: 'Underway',
    value: 'underway',
  },
  {
    label: 'Completed',
    value: 'completed'
  }
]