export const areaHistoryCategories = [
  {
    label: 'Treatment',
    value: 'treatment',
  },
  {
    label: 'Fertilizer',
    value: 'fertilizer',
  },
  {
    label: 'Sow',
    value: 'sow',
  },
  {
    label: 'Pasture Development',
    value: 'pasture_development',
  },
  {
    label: 'Earth Works',
    value: 'earth_works',
  },
  {
    label: 'Other',
    value: 'other',
  }
]