import actions from './actions';

const initialState = {
  properties: [],
  activePropertyTrialSatelliteExpired: null
};

export default function trialSatelliteExpiredReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STORE_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case actions.CHANGE_ACTIVE_TRIAL_SATELLITE_EXPIRED:
      return {
        ...state,
        activePropertyTrialSatelliteExpired: action.activePropertyTrialSatelliteExpired
      };
    default:
      return state;
  }
}
