import React, { useState, useEffect } from 'react';
import QuestionPage from './Question.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined } from '@ant-design/icons';
import { surveyQuestionService} from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import Table from '@iso/components/collapsing-table/table';
import { surveyQuestionTypes } from '@iso/constants/surveyQuestionTypes';
import { useSelector } from 'react-redux';
import QuestionModal from './QuestionModal'; 

const Question = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const [questions, setQuestions] = useState({});
  const formId = props.formId;
  const [visibilityQuestionModal, setVisibilityQuestionModal] = useState(false);
  const [questionData, setQuestionData] = useState({});

  useEffect(() => {
    updateQuestionData();
  }, [formId]);

  const updateQuestionData = () => {
    if(formId){
       surveyQuestionService.getList(formId).then(res => {
        if(res.code === '0000'){
          setQuestions(res.survey_questions);
        }
      });
    }
  }

  const menu = (question) => (
    <Menu onClick={handleMenuClick.bind(this, question)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <p style={{cursor:'pointer'}} className="deleteButton">
        <Popconfirm
                placement="bottomRight"
                title="You are about to delete individual record"
                onConfirm={() => {
                  confirmDelete(question);
                }}
                okText="Yes"
                cancelText="No"
                className="delete-button"
        >
        <a style={{color:'rgba(0, 0, 0, 0.65)', marginLeft: '11px'}}>
          {<DeleteOutlined />}&nbsp;&nbsp;
          <IntlMessages id="propertyPage.managePage.delete" />
        </a>
        </Popconfirm>
      </p>
    </Menu>
  );
  const handleMenuClick = (question, e) => {
    if(e.key === 'editDetails'){
     setQuestionData(question);
     showQuestionModal();
    }
  }

  const confirmDelete = (del_question) => {
    if(del_question.id){
      surveyQuestionService.destroy(del_question.id).then(res => {
        if(res.code === '0000'){
          updateQuestionData();
        }
      });
    }

  }

  const columns = [
    {
      label: <IntlMessages id="formPage.modal.questionTitle" />,
      accessor: 'title',
      minWidth: 0,
      sortable: true,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="formPage.modal.questionType" />,
      accessor: 'question_type',
      minWidth: 400,
      sortable: true,
      position: 2,
      priorityLevel: 2,
      CustomComponent: cell => {
        let question = cell.row
        return surveyQuestionTypes.map((value, index) => {
          if(value.value == question.question_type){
            return (  
              <div>{value.label}</div>
            )
          }
        });
      }
    },
    {
      label: <IntlMessages id="formPage.modal.questionCreatedAt" />,
      accessor: 'created_at',
      minWidth: 400,
      sortable: true,
      position: 3,
      priorityLevel: 3,
      CustomComponent: cell => {
        let question = cell.row
        return moment(question.created_at).format('DD/MM/YYYY');
      }
    },
    {
      label: <IntlMessages id="formPage.modal.questionUpdatedAt" />,
      accessor: 'updated_at',
      minWidth: 400,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: cell => {
        let question = cell.row
        return moment(question.updated_at).format('DD/MM/YYYY');
      }
    },
    {
      label: '',
      accessor: 'actions',
      minWidth: 100,
      sortable: true,
      position: 5,
      priorityLevel: 5,
      noTitle: true,
      CustomComponent: cell => {
        let question = cell.row
        return (
            <Dropdown overlay={menu(question)} trigger={['click']}>
              <Button className="action-button">
                <IntlMessages id="antTable.title.actions" /> <DownOutlined />
              </Button>
            </Dropdown>
        );
      },
    },
  ];

  const showQuestionModal = () => {
    setVisibilityQuestionModal(true);
  }

  const cancelQuestionModal = () => {
    setVisibilityQuestionModal(false);
    setQuestionData({});
  }
  return (
    <QuestionPage>
      <div className="button-group">
        {(activePermission == "owner" || activePermission == "admin") && <Button icon={<PlusOutlined />} type="primary" className="btn-success" onClick={showQuestionModal}>
          <IntlMessages id="formPage.modal.addQuestion" />
        </Button>}
      </div>
      {
        questions.length > 0 &&
        <Table 
          columns={columns} 
          rows={questions}
          containerId="containerBox"
          showPagination={false}
          rowSize={99999}
        />            
      }
      <QuestionModal visibilityQuestionModal={visibilityQuestionModal} cancelQuestionModal={cancelQuestionModal} 
        updateQuestionData={updateQuestionData} formId={formId} questionData={questionData}/>
    </QuestionPage>
  )
}

export default Question;