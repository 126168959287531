import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const PropertyManagePage = styled.div`
  width: 100%;
  height: 100%;
 
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
    margin-right: 10px;
  }
  
  .button-group{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .fAgWDa .ant-table-content{
    overflow-x: unset;
  }
  .fAgWDa{
    overflow-x: unset;
  }
  .dtr-data{
    white-space: break-spaces;
  }

  .card_value{
    float:right;
  }
  .margin-bottom-5{
    margin-bottom: 5px;
  }
  .margin-right-0{
    margin-right: 0px;
  }
  @media only screen and (max-width : 768px)
{
  .manage-property-header {
    // flex-wrap: wrap;
  }
  .manage-property-header > .mp-title {
    min-width: 26% !important;
  }
  .search-bar{
    margin-left: 0rem;
    width : 250px ;
  } 
}
`;

export default WithDirection(PropertyManagePage);