export const inventoryTypes = [
  {
    label: 'Animal Treatment',
    value: 'animal_treatment',
  },
  {
    label: 'Fertiliser',
    value: 'fertiliser',
  },
  {
    label: 'Stock Feed',
    value: 'stock_feed',
  },
  {
    label: 'Vaccine',
    value: 'vaccine',
  },
  {
    label: 'Fuel',
    value: 'fuel',
  },
  {
    label: 'Aircraft',
    value: 'aircraft',
  },
  {
    label: 'Water',
    value: 'water',
  },
  {
    label: 'Chemical',
    value: 'chemical'
  },
  {
    label: 'Other',
    value: 'other',
  }
]