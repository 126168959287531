export function authHeader(leaveBlank) {
  let result = {
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
    Accept: 'application/json',
  }
  if(!leaveBlank){
    result["Content-Type"] = "application/json";
  }
  return result;
}
