export const weatherDurationTomorrowIo = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow',
  },
  {
    label: 'This Week',
    value: 'this_week',
  },
  {
    label: 'Next Week',
    value: 'next_week',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last Week',
    value: 'last_week',
  },
]
