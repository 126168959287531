import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const searchService = {
  search
};

function search(search_text){
  return (
    axios
      .post(
        `${apiEndpoint.search}`, {
          search: `${search_text}`
        },
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data.data;
        }
      })
  );
}