import React, { useEffect, useLayoutEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import PageHeader from "@iso/components/utility/pageHeader";
import IntlMessages from "@iso/components/utility/intlMessages";
import { useSelector, useDispatch } from "react-redux";
import { configs } from "@iso/components/Tables/configs";
import { Button, Dropdown, Divider, Menu, DatePicker, Space, Select,Input, Pagination } from "antd";

import FormPage from "../../containers/Pages/Form/Form.styles";
import {
  PlusOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import propertyActions from "@iso/redux/property/actions";

import Table from "@iso/components/collapsing-table/table";
import FormModal from "../../containers/Pages/Form/FormModal";
import { propertyService, permissionService, formService } from "@iso/services";
import { subscriptionPlan } from "@iso/constants/subscriptionPlan";
import { formTypes } from "@iso/constants/formTypes";
import { saveAs } from 'file-saver'
import FormSubmission from "../../containers/Pages/Form/FormSubmission";
import VisitorModaWlrapper from './VisitorModal.styles'
// import VisitorModalWrapper from "../../containers/Pages/Visitor/VisitorModal.styles";
import ShareOptionsModalWrapper from './ShareOptionsModal.styles';
import { } from 'antd';
import { UploadOutlined,EllipsisOutlined ,EditOutlined,FileOutlined, FileDoneOutlined} from '@ant-design/icons';
import _ from 'lodash';
import InspectionModal from "../../containers/Pages/Form/InspectionModal";



const FormsModal = (props) => {
  
    const dispatch = useDispatch();
    const { changeActiveProperty } = propertyActions;
    const { RangePicker } = DatePicker;
    const activePropertyId = useSelector(
      (state) => state.property.activePropertyId
    );
    const activePropertySubscription = useSelector(
      (state) => state.property.activePropertySubscription
    );
    const activePermission = useSelector(
      (state) => state.permission.activePermission
    );
    const [visibleFormModal, setVisiblityFormModal] = React.useState(false);
    // const activeProperty=localStorage.getItem( "active_property" );
    const activeProperty = JSON.parse(localStorage.getItem("active_property"));
    const [property, setProperty] = React.useState({});
    const { Option } = Select;
    const [properties, setProperties] = React.useState([]);
    const [isSaved, setIsSaved] = React.useState(false);
    const [forms, setForms] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [visibleformSubmissions, setVisibleformSubmissions] = React.useState(false);
    const [selectedDateRange, setSelectedDateRange] = React.useState([]);
    const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState();
    const [searchInput, setsearchInput] = React.useState('');
    const [visibleInspectionModal, setVisibleInspectionModal] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedForms =searchQuery?.slice(startIndex, endIndex);
  
    const handlePageSizeChange = (current, size) => {
      setCurrentPage(1);
      setPageSize(size);
    };
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
  
    useEffect(() => {
      if (activePropertyId) {
        formService.getList(activePropertyId).then((res) => {
          if (res.code === "0000") {
            setForms(res.forms);
            setSearchQuery(res.forms)
          }
        });
      }
    }, [activePropertyId, isSaved, activePropertySubscription]);
  
    useEffect(() => {
      if (props?.handleFormData) {
        props?.handleFormData(forms.length);
      }
  
    }, [forms]);
  
    const updateFormData = () => {
      if (activePropertyId) {
        formService.getList(activePropertyId).then((res) => {
          if (res.code === "0000") {
            setSearchQuery(res.forms)
            setForms(res.forms);
          }
        });
      }
    };
  
    useEffect(() => {
      updateFormData();
    }, [activePropertyId]);
  
    useEffect(() => {
      propertyService.getProperties().then((res) => {
        if (res.properties.length === 0) {
          setVisiblitySelectProperty(true);
        } else {
          setProperties(res.properties);
          if (res.properties.length === 1) {
            localStorage.setItem(
              "active_property",
              JSON.stringify(res.properties[0])
            );
            dispatch(changeActiveProperty(res.properties[0]));
          }
        }
      });
    }, [activePropertyId, isSaved, activePropertySubscription]);
  
    const showModal = () => {
      setVisiblityFormModal(true);
    };
  
    const cancelModal = () => {
      setVisiblityFormModal(false);
      setFormData({});
    };
  
    const openEditModal = (form) => {
      setFormData(form);
      setVisiblityFormModal(true);
    };
  
    const openSubmissionForm = (form) => {
      setFormData(form)
      setVisibleInspectionModal(true);
    }
  
    const cancelSubmissionForm = () => {
      setVisibleInspectionModal(false);
    }
 
    const handleReport = (form) => {
      formService.exportSubmissions(form.id).then(res => {
        if(res.code === '0000'){
          window.location.href = res.file_path
        }
      });
    }
  
    const menu = (form) => (
      <Menu onClick={handleMenuClick.bind(this, form)}>
        <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="formPage.table.button.edit" />
        </Menu.Item>
        <Menu.Item key="report" icon={<FileOutlined/>}>
        <IntlMessages id="formPage.table.button.report" />
        </Menu.Item>
  
        <Menu.Item key="Submissions" icon={<FileDoneOutlined />}>
        <IntlMessages id="Submissions" />
        </Menu.Item>
  
  
      </Menu>
    );
    const handleMenuClick = (form, e) => {
      if (e.key === 'editDetails') {
        openEditModal(form)
      }
      if (e.key === 'report') {
  
        handleReport(form)
      }
  
      if (e.key === "Submissions") {
        openSubmissionForm(form); 
  
  
      }
    }
    const columns = [
      {
        label: <IntlMessages id="formPage.table.title.formName" />,
        accessor: "name",
        minWidth: 200,
        sortable: true,
        position: 1,
        priorityLevel: 1,
        alignItems: 'center',
      },
      {
        label: <IntlMessages id="formPage.table.title.formStatus" />,
        accessor: "is_public",
        minWidth: 200,
        sortable: true,
        position: 2,
        priorityLevel: 2,
        alignItems: 'center',
        CustomComponent: (cell) => {
          let form = cell.row;
  
          if (form.is_public == 0) {
            return "Private";
          } else {
            return "Public";
          }
        },
      },
      {
        label: <IntlMessages id="Last Updated At" />,
        accessor: "updated_at",
        minWidth: 200,
        sortable: true,
        position: 3,
        priorityLevel: 3,
        alignItems: 'center',
      },
      {
        label: <IntlMessages id="Last Activity At" />,
        accessor: "last_activity",
        minWidth: 200,
        sortable: true,
        position: 4,
        priorityLevel: 4,
        alignItems: 'center',
      },
      {
        label: "Action",
        accessor: "dot-menu",
        minWidth: 100,
        sortable: true,
        alignItems:'center',
        position: 5,
        priorityLevel: 5,
        noTitle: true,
        CustomComponent: (cell) => {
            let form = cell.row;
           
          return (
            <Dropdown overlay={menu(form)} trigger={["click"]}>
              <div
                className="property-area-dot-btn"
                style={{ cursor: "pointer" }}
              >
                <EllipsisOutlined className="dotIcon"/>
              </div>
            </Dropdown>
          );
        },
      },
      // {
      //   label: "",
      //   accessor: "actions",
      //   minWidth: 300,
      //   sortable: true,
      //   position: 5,
      //   priorityLevel: 5,
  
      //   alignItems: 'left',
      //   CustomComponent: (cell) => {
      //     let form = cell.row;
  
      //     return (
      //       <>
      //         <Button
      //           className="action-button"
      //           onClick={() => {
      //             openEditModal(form);
      //           }}
      //         >
      //           <IntlMessages id="formPage.table.button.edit" />
      //         </Button>
      //         &nbsp; &nbsp;
      //         <Button className="action-button" onClick={() => handleReport(form)}>
      //           <IntlMessages id="formPage.table.button.report" />
      //         </Button>
      //         &nbsp; &nbsp;
      //         <Button className="action-button" onClick={() => { openSubmissionForm(form) }}>
      //           <IntlMessages id="Submissions" />
      //         </Button>
      //       </>
      //     );
      //   },
      // },
  
    ];
  
    const handleOptionClick = (value) => {
      _.forEach(properties, (property) => {
        if (property.id == value) {
          localStorage.setItem("active_property", JSON.stringify(property));
          dispatch(changeActiveProperty(property));
        }
      });
    };
  
    const renderOptionsProperty = () => {
      let options = [];
      _.forEach(properties, (property, index) => {
        options.push(
          <Option key={index} value={property?.id} className="property-option">
            {property?.name}
          </Option>
        );
      });
  
      return (
        <Select
          className="ctm-form-property-select"
          placeholder="Select Active Property"
          value={activeProperty?.name}
          size="large"
          style={{ width: 150 }}
          onChange={handleOptionClick}
        >
          {options}
        </Select>
      );
    };
    const handleDateRangeChange = (dates) => {
    
      setSelectedDateRange(dates);
      // Check if dates is not null before calling filterFormsByDateRange
      if (dates) {
        filterFormsByDateRange(dates);
      }else{
        setSearchQuery(forms);
      }
    };
  
    const filterFormsByDateRange = (dates) => {
      const [startDate, endDate] = dates;
  
      if (startDate && endDate) {
        const filteredForms = forms.filter((form) => {
          const formDate = new Date(form.updated_at); // Assuming 'updated_at' is a date property in your form object
          return formDate >= startDate && formDate <= endDate;
        });
        
        setSearchQuery(filteredForms);
      } else {
        // If no date range is selected, show all forms
        setSelectedDateRange([])
        setSearchQuery(forms);
  
      }
    };
    const handleCancel = () => {
  
      setSelectedDateRange([])
        setSearchQuery(forms);
    };
  
    const handleSearch = (e) => {
      if (e.key === "Enter") {
        const filteredForms = forms.filter((form) => {
          const searchTerm = searchInput.toLowerCase();
          const formName = form.name.toLowerCase();
          const formStatus = form.is_public === 0 ? "private" : "public";
          const lastUpdatedAt = form.updated_at.toLowerCase(); // You might need to format this date
          const lastActivity = form.last_activity.toLowerCase();
          // Add more columns here as needed
  
          return (
            formName.includes(searchTerm) ||
            formStatus.includes(searchTerm) ||
            lastUpdatedAt.includes(searchTerm) ||
            lastActivity.includes(searchTerm)
            // Add more conditions for other columns
          );
        });
  
        setSearchQuery(filteredForms);
      }
    };
  
  
  
    const handleCancelSearch = () => {
      setsearchInput('')
      setSearchQuery(forms);
    };
  
    
    const cancelFromModal = () =>{
      props.setopenFormModal(false)
    }
  return (
    <>
    <VisitorModaWlrapper
       className='visitor-modal'
      forceRender={true}
      open={props.openFormModal}
      title={<IntlMessages id="Forms"/>}
      onCancel={cancelFromModal}
      footer={false}
    >
        <FormPage>
        <div className='manage-property-header'>
          
            <Input
              placeholder="Search…"
              suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
              prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
              size="large"
              className="search-bar"
              value={searchInput}
              onChange={(e) => setsearchInput(e.target.value)}
              onKeyDown={handleSearch}
            />

            <div className="button-group">
              {(activePermission == "owner" || activePermission == "admin") && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="btn-success"
                  onClick={showModal}
                  size="large"
                >
                  Add Form
                </Button>
              )}
              {renderOptionsProperty()}
              <RangePicker
                value={selectedDateRange}
                onCancel={handleCancel}
              onChange={handleDateRangeChange} renderExtraFooter={() => 'extra footer'} className="startEndDate" />
              {/* <RangePicker onChange={handleDateRangeChange} renderExtraFooter={() => 'extra footer'} className="startEndDate" /> */}
            </div>

          </div>
            <div className="table_forms" id="containerBox">
            {displayedForms && displayedForms.length > 0 ? (
              <>
                <Table
                  columns={columns}
                  rows={displayedForms}
                  containerId="containerBox"
                  showPagination={false}
                  rowSize={99999}
                />
                <div style={{margin:'20px'}}>
                  <Pagination
                    current={currentPage}
                    total={searchQuery.length}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger
                    pageSizeOptions={["10", "20", "50", "100"]}
                    onShowSizeChange={handlePageSizeChange}
                  /></div></>
            ) : (
              <h3>
                <IntlMessages id="dashboardPage.noData" />
              </h3>
            )}
          </div>
          
      </FormPage>    
    </VisitorModaWlrapper>
     <FormModal
          visibleFormModal={visibleFormModal}
          cancelModal={cancelModal}
          propertyId={activePropertyId}
          updateFormData={updateFormData}
          formData={formData}
        />
{visibleInspectionModal ?
<InspectionModal 
          visibleInspectionModal={visibleInspectionModal} 
          cancelInspectionModal={cancelSubmissionForm} 
          formId={formData?.id} 
          propertyId={formData?.property_id}
        />:''}
        {/* <FormSubmission
          visibleformSubmissions={visibleformSubmissions}
          cancelSubmissionForm={cancelSubmissionForm}
          propertyId={activePropertyId}
          formData={formData}
        />  */}
    </>
  );
}

export default FormsModal;