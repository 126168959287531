export const WorkingRight = [
    {
      label: 'Working Visa',
      value: 0,
    },
    {
      label: 'Citizen',
      value: 1,
    },
   
  ]