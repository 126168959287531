import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const kmzService = {
  handleKmz,
  exportKmz
};

function exportKmz(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.kmz}/export-kmz?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function handleKmz(data,id){
  return (
    axios
      .post(
        `${apiEndpointFunction.kmz(id)}/handle-kmz`,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}