import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';
import ImageSectionBackground from "@iso/assets/images/welcomeModals/image-section-background.png";

const WelcomeModalWrapper = styled(Modal)`
${props => props.step === 'initial_property' ? 
  `top: 40px !important;` : ''}
  .ant-modal-footer{
    display: none;
  }
  .image-section{
    border-radius: 24px;
    background-image: url(${ImageSectionBackground});
    position: relative;
    @media only screen and (max-width: 992px) {
      height: 350px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
    @media only screen and (max-width: 425px) {
      height: 500px;
    }
  }
  .button-group{
    text-align: center;
    margin-top: 25px;
  }
  @media only screen and (max-width: 767px) {
    .button-group{
      button{
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .submit-btn{
    border-radius: 10px !important;
    width: 190px;
    height: 56px;
    font-weight: 600;
    background: var(--primary-color, rgb(248, 14, 70));
    
  }
  .outline-btn{
    border-radius: 10px !important;
    width: 190px;
    height: 56px;
    color: rgb(248, 14, 70);
    border: 1px solid var(--primary-color, rgb(248, 14, 70));
  }
  .large-logo{
    height: 100px;
    @media only screen and (max-width: 767px) {
      height: 70px;
    }
  }
  .small-logo{
    height: 36px;
    margin-bottom: 37px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .text-title{
    line-height: normal;
    font-size: 40px;
    @media only screen and (max-width: 767px) {
      font-size: 30px;
    }
  }
  .purpose-options{
    display: grid;
    .ant-radio-wrapper{
      margin-bottom: 16px;
      span{
        font-weight: 400;
        font-size: 16px
      }
    }
  }
  &.user-access-modal{
    width: 640px !important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #65bb38;
    border-color: #559f2f;
  }
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  .form-body{
    padding: 30px 30px 30px 73px;
    @media only screen and (max-width: 767px) {
      padding: 25px 15px 25px 35px;
    }
  }
  @media only screen and (max-width: 425px) {
      
    }
`;

export default WithDirection(WelcomeModalWrapper);