import React from "react";
import SubscriptionModalWrapper from "./SubscriptionModal.styles";
import {
  Button
} from "antd";

const TrialConfirmationModal = (props) => {
  const cancelModal = () => {
    props.cancelTrialConfirmationModal();
  };

  const showSubscriptionModal = () => {
    props.showSubscriptionModal();
    cancelModal()
  };  

  return (
    <SubscriptionModalWrapper
      title="Subscription"
      visible={props.visibleTrialConfirmationModal}
      onCancel={cancelModal}
      maskClosable={false}
      footer={null}
    >
      <div className="form-body">
        <p className="instruction">
          Do you want to take the 3 Month Free Gold Subscription?
        </p>
        <span className="extra-note">
          Payment will not be taken, unless you continue after the trial period
        </span>
        <p className="price">$<span>39.99</span><span>/month</span></p>
        <Button className="submit-btn" type="primary" size="large" onClick={showSubscriptionModal}>
          Yes, I want
        </Button>
      </div>
    </SubscriptionModalWrapper>
  );
};

export default TrialConfirmationModal;
