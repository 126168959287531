import React , { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col, Radio, Checkbox, DatePicker,Dropdown,Menu,Popconfirm } from 'antd';
import { PlusOutlined,EditOutlined,EyeOutlined,EllipsisOutlined,DeleteOutlined } from '@ant-design/icons';
import { emergencyService } from '@iso/services';
import userActions from '@iso/redux/user/actions';
import IntlMessages from '@iso/components/utility/intlMessages';
import EmergencyWrapper from './Emergency.styles';
import Table from '@iso/components/collapsing-table/table';
import Box from '@iso/components/utility/box';
import EmergencyModal from './EmergencyModal';
import _ from 'lodash';
import moment from 'moment';
import LicenceViewModal from './LicenceViewModal';

const { store } = userActions;

const Emergency = (props) => {
  const [emergencyContact, setEmergencyContact] = useState([]);
  const [emergencyExpiryTetanus, setEmergencyExpiryTetanus] = useState([]);
  const [emergencyOther, setEmergencyOther] = useState([]);
  const [emergencyNotes, setEmergencyNotes] = useState([]);
  const [emergencyData, setEmergencyData] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [visibleEmergency, setVisibilityEmergency] = React.useState(false);
  const [editEmergency, setEditEmergency] = useState(false);
  const [viewSummary,setViewSummary] = useState(false);
  const [title,setTitle] =useState("Add Emergency");
  const user_id = props.userId || '';
  const form_id = props.formId || '';
  const isDisableForm = user_id && form_id ? true : false
  useEffect(() => {
    updateEmergencyData();
  }, [currentUser.id, user_id, form_id,]);

  const updateEmergencyData = () => {
    
    if(user_id !== undefined && user_id !== "" && form_id !== undefined && form_id !== ""){
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "emergency_contact")
        .then((res) => {
          if (res?.code === "0000") {
            setEmergencyContact(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "expiry_of_tetanus")
        .then((res) => {
          if (res?.code === "0000") {
            setEmergencyExpiryTetanus(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "other")
        .then((res) => {
          if (res?.code === "0000") {
            setEmergencyOther(res.emergencies);
          }
        });
      emergencyService
        .getCheckinFormUserEmergency(form_id, user_id, "notes")
        .then((res) => {
          if (res?.code === "0000") {
            setEmergencyNotes(res.emergencies);
          }
        });
    } else if(currentUser){
      emergencyService.getList(currentUser.id, 'emergency_contact').then(res => {
        if(res.code === '0000'){
          setEmergencyContact(res.emergencies);
        }
      });
      emergencyService.getList(currentUser.id, 'expiry_of_tetanus').then(res => {
        if(res.code === '0000'){
          setEmergencyExpiryTetanus(res.emergencies);
        }
      });
      emergencyService.getList(currentUser.id, 'other').then(res => {
        if(res.code === '0000'){
          setEmergencyOther(res.emergencies);
        }
      });
      emergencyService.getList(currentUser.id, 'notes').then(res => {
        if(res.code === '0000'){
          setEmergencyNotes(res.emergencies);
        }
      });
    }
  }
  const menuList = (emergency, permission) => (
    <Menu onClick={handleMenuClick.bind(this, emergency)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      <Menu.Item key="viewDetails" icon={<EyeOutlined />}>
        <IntlMessages id="View Details" />
      </Menu.Item>
     

      <p style={{ cursor: "pointer" }} className="deleteButton">
       <Popconfirm
         placement="bottomRight"
         title="You are about to delete individual record"
         onConfirm={() => {
           confirmDelete(emergency);
         }}
         okText="Yes"
         cancelText="No"
         className="delete-button"
       >
         <a style={{ color: "rgba(0, 0, 0, 0.85)", marginLeft: "11px" }}>
           {<DeleteOutlined />}&nbsp;&nbsp;
           <IntlMessages id="Remove" />
         </a>
       </Popconfirm>
     </p>
    </Menu>
  );

  const confirmDelete = (emergency) => {
    if (emergency.id) {
      emergencyService.destroy(emergency.id).then((res) => {
        if (res.code === "0000") {
          updateEmergencyData();
        }
      });
    }
  };
  const handleMenuClick = (emergency, e) => {


    if (e.key === "viewDetails") {

      setViewSummary(true);
      setEmergencyData(emergency);
      setTitle("Emergency")
    }
    if (e.key === "editDetails") {
      showEditModal(emergency);
    

    }
  }
  const showModal = () => {
    setVisibilityEmergency(true);
  }

  const showEditModal = (qualification) => {
    setEditEmergency(true);
    setEmergencyData(qualification);
    setVisibilityEmergency(true);
    setTitle("Edit Emergency")

  }

  const cancelModal = () => {
    setVisibilityEmergency(false);
    setEditEmergency(false);
    setEmergencyData({});
  }

  const onSaved = () => {
    updateEmergencyData();
  }

  const columns_emergency_contact = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.fullName" />,
      accessor: 'full_name',
      minWidth: 100,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="Email" />,
      accessor: 'email',
      minWidth: 100,
      sortable: false,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.relationship" />,
      accessor: 'relationship',
      minWidth: 100,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.phoneNumber" />,
      accessor: 'phone_number',
      minWidth: 100,
      sortable: false,
      position: 4,
      priorityLevel: 4
    },
    
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(emergency)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];
  const columns_tetanus_expiry = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.tetanusExpiry" />,
      accessor: 'expiry_of_tetanus',
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1,
      CustomComponent: cell => {
        let emergency = cell.row
        return (emergency.expiry_of_tetanus) ? moment(emergency.expiry_of_tetanus).format('L') : ''
      }
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(emergency)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const columns_other = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.type" />,
      accessor: 'other_type',
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.location" />,
      accessor: 'location',
      minWidth: 200,
      sortable: false,
      position: 2,
      priorityLevel: 2
    },
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.applyDate" />,
      accessor: 'apply_date',
      minWidth: 200,
      sortable: false,
      position: 3,
      priorityLevel: 3
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(emergency)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const columns_notes = [
    {
      label: <IntlMessages id="profilePage.emergencyTab.table.notes" />,
      accessor: 'note',
      minWidth: 200,
      sortable: false,
      position: 1,
      priorityLevel: 1
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      noTitle: true,
      CustomComponent: (cell) => {
        let emergency = cell.row
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menuList(emergency)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
    <EmergencyWrapper
    style={{padding:'15px'}}
     className="profile-area"
    >
      <div className="form-title">
        <h2>{<IntlMessages id="profilePage.managePage.emergencyTab" />}</h2>
        <Button icon={<PlusOutlined />} type="primary" className='AddEmergency' onClick={showModal}>
          <IntlMessages id="profilePage.emergencyTab.addEmergency" />
        </Button>
       
      </div>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.emergencyTab.type.contact" />}</h3>
      </div>
      <Box id="containerBox">
          {emergencyContact.length > 0 ?
          <Table 
              columns={columns_emergency_contact} 
              rows={emergencyContact}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
      </Box>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.emergencyTab.type.tetanusExpiry" />}</h3>
      </div>
      <Box id="containerBox">
          {emergencyExpiryTetanus.length > 0 ?
          <Table 
              columns={columns_tetanus_expiry} 
              rows={emergencyExpiryTetanus}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
      </Box>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.emergencyTab.type.Other" />}</h3>
      </div>
      <Box id="containerBox">
          {emergencyOther.length > 0 ?
          <Table 
              columns={columns_other} 
              rows={emergencyOther}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            /> : <h4><IntlMessages id="dashboardPage.noData" /></h4>
          }
      </Box>
      <div className="form-title">
        <h3>{<IntlMessages id="profilePage.emergencyTab.type.Notes" />}</h3>
      </div>
      <Box id="containerBox">
          {emergencyNotes.length > 0 ?
          <Table 
              columns={columns_notes} 
              rows={emergencyNotes}
              containerId="containerBox"
              showPagination={false}
              rowSize={99999}
            />
         : <h4><IntlMessages id="dashboardPage.noData" /></h4>}
      </Box>
      <EmergencyModal        
        visibleEmergency={visibleEmergency} cancelModal={cancelModal} onSaved={onSaved}
        editEmergency={editEmergency} emergencyData={emergencyData}
        title={title}
        viewSummary={viewSummary}
          setViewSummary={setViewSummary}
      />
    </EmergencyWrapper>
    {/* <LicenceViewModal
          viewSummary={viewSummary}
          setViewSummary={setViewSummary}
          emergencyData={emergencyData}
          title = 'Emergency Details'
        /> */}
    </>
  );
}

export default Emergency;