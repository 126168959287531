import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import Box from "@iso/components/utility/box";
import InspectionWrapper from "./Inspection.styles";
import IntlMessages from "@iso/components/utility/intlMessages";
import { Button, Dropdown, Menu, message, Col, Modal, Select, Row, Input } from "antd";
import {
  SearchOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  UserOutlined,
  SendOutlined,
  EllipsisOutlined
} from "@ant-design/icons";
import {
  propertyService,
  profileService,

  userFormService,
} from "@iso/services";
import { visitorFormService } from "../../../services/visitorform.service";
import _ from "lodash";
import Table from "@iso/components/collapsing-table/table";
import { formTypes } from "@iso/constants/formTypes";
import { userTypes } from "@iso/constants/userTypes";
import VisitorModal from "@iso/containers/Pages/Visitor/VisitorModal";
import CheckinUserProfileModal from "@iso/containers/Pages/Profile/CheckinUserProfileModal";
import ViewSurveyResultModal from "./ViewSurveyResultModal";


const pageSize = 15;
const Inspection = (props) => {
  const formTypeLabels = _.keyBy(formTypes, "value");

  const [visibleViewSurveyResult, setVisibleViewSurveyResult] = useState(false);

  const [totalPages, setTotalPages] = useState();
  const [totalUserFormPages, setTotalUserFormPages] = useState(0);
  const [totalVisitorFormPages, setTotalVisitorFormPages] = useState(0);
  const [currentPage, setCurrentPage] = useState();
  const [surveyResult, setSurveyResult] = useState({});
  const [visitor, setVisitor] = React.useState({});
  const [visibleCheckinUserProfileModal, setVisibleCheckinUserProfileModal] =
    React.useState(false);
  const [checkinUserId, setCheckinUserId] = React.useState(null);
  const [visibleVisitorModal, setVisibleVisitorModal] = React.useState(false);
  const [userFormList, setUserFormList] = useState([]);
  const [visitorFormList, setVisitorFormList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState('');
  const [selectedUserType, setSelectedUserType] = useState("registered_user");
  const { Option } = Select;

  const menu = (userForm) => (
    <Menu onClick={handleMenuClick.bind(this, userForm)}>
      {(userForm.is_investigated || userForm.visitor_id) &&
        (userForm.form.type === "self_create" ||
          userForm.form.type === "system_forms" ||
          userForm.form.type === "checkin_form" ||
          userForm.form.type === "bio_form") && (
          <Menu.Item key="viewResult" icon={<EditOutlined />}>
            View Result
          </Menu.Item>
        )}
      {userForm.visitor_info && (
        <Menu.Item key="viewVisitor" icon={<UserOutlined />}>
          View Visitor
        </Menu.Item>
      )}
      {userForm.user_info && userForm.user_info.share_with_property_owner ? (
        <Menu.Item key="viewProfile" icon={<UserOutlined />}>
          View Profile
        </Menu.Item>
      ) : (
        <></>
      )}
      {userForm.user_info && !userForm.user_info.share_with_property_owner ? (
        <Menu.Item key="requestShareProfile" icon={<SendOutlined />}>
          Request To See Profile
        </Menu.Item>
      ) : (
        <></>
      )}
      {userForm.signature_path && (
        <Menu.Item key="viewSignature" icon={<EditOutlined />}>
          View Signature
        </Menu.Item>
      )}
    </Menu>
  );

  const showViewSurveyResult = (item) => {
    setSurveyResult(item);
    setVisibleViewSurveyResult(true);
  };

  const handleMenuClick = (userForm, e) => {
    if (e.key === "viewResult") {
      showViewSurveyResult(userForm);
    }
    if (e.key === "viewVisitor") {
      setVisitor(userForm.visitor_info);
      setVisibleVisitorModal(true);
    }
    if (e.key === "viewProfile") {
      setVisibleCheckinUserProfileModal(true);
      setCheckinUserId(userForm.user_id);
      setSelectedFormId(userForm.form_id);
    }
    if (e.key === "requestShareProfile") {
      profileService
        .requestShareProfile(userForm.form_id, userForm.user_id)
        .then((res) => {
          if (res.code === "0000") {
            message.success(res.message);
          } else {
            message.error(res.message);
          }
        });
    }
    if (e.key === "viewSignature") {
      setPreviewImage(userForm?.signature_path);
      setPreviewOpen(true);
    }
  };

  const columns = [
    {
      label: <IntlMessages id="Name" />,
      accessor: "user",
      minWidth: 200,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let row = cell.row;
        return <b>{row.user ? row.user : row.visitor}</b>;
      },
    },
    {
      minWidth: 100,
      label: <IntlMessages id="User Type" />,
      accessor: "userType",
      sortable: false,
      position: 2,
      priorityLevel: 2,
      CustomComponent: (cell) => {
        let row = cell.row;
        return row.visitor ? "Visitor" : "Registered User";
      },
    },
    {
      minWidth: 100,
      label: <IntlMessages id="Form Type" />,
      accessor: "formType",
      sortable: false,
      position: 3,
      priorityLevel: 3,
      CustomComponent: (cell) => {
        let row = cell.row;
        let formType = row?.form?.type;
        formType =
          formType && formTypeLabels[formType]
            ? formTypeLabels[formType]["label"]
            : "";
        return <>{formType}</>;
      },
    },
    {
      minWidth: 100,
      label: <IntlMessages id="Completed At" />,
      accessor: "updated_at",
      sortable: true,
      position: 4,
      priorityLevel: 4,
    },
    {
      label: "Action",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let row = cell.row;
        // let permission = permissionService.getPermission(row.permission_id);
        return (
          <Dropdown overlay={menu(row)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleChange = (params = {}) => {
    const page = params.page ? params.page : 1;
    setCurrentPage(page);

    // Update the search input in the params
    params.search = searchInput;

    // Call the API functions
    getUserForms(params);
    getVisitorForms(params);
  };




  const getUserForms = (params = {}) => {
    params.is_pagination = 1;
    params.search = searchInput; // Include the search query
    userFormService.getList(props.formId, params)
      .then((res) => {
        if (res.code === "0000") {
          setTotalUserFormPages(res.total_page);
          setUserFormList(res.user_forms);
        }
      });
  };

  const getVisitorForms = (params = {}) => {
    params.is_pagination = 1;
    params.search = searchInput; // Include the search query
    visitorFormService.getList(props.formId, params)
      .then((res) => {
        if (res.code === "0000") {
          setTotalVisitorFormPages(res.total_page);
          setVisitorFormList(res.visitor_forms);
        }
      });
  };



  useEffect(() => {
    if (props.formId) {
      getUserForms();
      getVisitorForms();
    }
  }, [props.formId]);

  const getDataSource = () => {
    if (selectedUserType == "visitor") {
      return visitorFormList;
    } else {
      return userFormList;
    }
  };

  const closePreviewModal = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };

  const cancelVisitorlModal = () => {
    setVisibleVisitorModal(false);
    setVisitor({});
  };

  const cancelCheckinUserProfileModal = () => {
    setVisibleCheckinUserProfileModal(false);
    setCheckinUserId(null);
  };

  const renderUserTypeOptions = () => {
    let options = [];
    _.forEach(userTypes, (userType, index) => {
      options.push(
        <Option key={index} value={userType.value} className="property-option">
          {userType.label}
        </Option>
      );
    });
    return (
      <Select
        placeholder="Select User Type"
        value={selectedUserType}
        size="large"
        style={{ width: 170 }}
        onChange={(value) => {
          setSelectedUserType(value);
        }}
      >
        {options}
      </Select>
    );
  };

  const cancelViewSurveyResult = () => {
    setSurveyResult({});
    setVisibleViewSurveyResult(false);
  };

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
  };

  const handleCancelSearch = () => {
    setsearchInput('');
  };


  return (
    <LayoutWrapper>
      <InspectionWrapper>

        <div className="mainDiv">



          <Input
            placeholder="Search…"
            suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
            prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
            size="large"
            className="search-bar"
            value={searchInput}
            onChange={(e) => handleSearch(e)}
          />


          <div className="button-group">
            <label className="filter-label">User Type: </label>
            {renderUserTypeOptions()}

          </div>
        </div>
        <Box id="containerBox">
          <Table
            columns={columns}
            rows={getDataSource()}
            containerId="containerBox"
            showPagination={true}
            rowSize={15}
            totalPages={
              selectedUserType === "visitor"
                ? totalVisitorFormPages
                : totalUserFormPages
            }
            currentPage={currentPage}
            updateData={(params) => handleChange({ ...params, search: searchInput })}
          />

        </Box>
        <Modal
          open={previewOpen}
          footer={null}
          onCancel={closePreviewModal}
          title="Signature"
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={process.env.REACT_APP_API_BASE_URL + previewImage}
          />
        </Modal>
        <VisitorModal
          visibleVisitorModal={visibleVisitorModal}
          cancelModal={cancelVisitorlModal}
          propertyId={props.propertyId}
          editedVisitor={visitor}
          updateVisitorData={getVisitorForms}
        />
        {visibleCheckinUserProfileModal && (
          <CheckinUserProfileModal
            formId={selectedFormId}
            userId={checkinUserId}
            isVisible={visibleCheckinUserProfileModal}
            cancelModal={cancelCheckinUserProfileModal}
          />
        )}
        <ViewSurveyResultModal
          visibleViewSurveyResult={visibleViewSurveyResult}
          cancelViewSurveyResult={cancelViewSurveyResult}
          surveyData={surveyResult}
          type={surveyResult?.form?.type}
        />
      </InspectionWrapper>
    </LayoutWrapper>
  );
};

export default Inspection;