import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Form, Select, Row, Col } from "antd";
import logo from "@iso/assets/images/logo.png";
import Alert from "@iso/components/Feedback/Alert";
import { propertyService, googleService } from "@iso/services";
import _ from "lodash";

const { Option } = Select;

let autoComplete;

function handleAddressAutoComplete(
  setAddress,
  autoCompleteRef,
  setAddressShortName,
  setPoint
) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current.input,
    { types: ["address"] }
  );

  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(setAddress, setAddressShortName, setPoint);
  });
}

async function handlePlaceSelect(setAddress, setAddressShortName, setPoint) {
  const addressObject = autoComplete.getPlace();
  const fields = [
    {
      name: ["address"],
      value: addressObject.formatted_address,
    },
  ];
  for (var i = 0; i < addressObject.address_components.length; i++) {
    var addressType = addressObject.address_components[i].types[0];
    if (addressType === "country") {
      fields.push({
        name: "country",
        value: addressObject.address_components[i].long_name,
      });
    }
    if (addressType === "administrative_area_level_1") {
      fields.push({
        name: "state",
        value: addressObject.address_components[i].long_name,
      });
    }
  }
  setAddress(fields);
}

const LeftSection = (props) => {
  const autoCompleteRef = useRef(null);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddress = () => {
    handleAddressAutoComplete(setFields, autoCompleteRef);
  };

  useEffect(() => {
    setFields([
      {
        name: ["name"],
        value: "",
      },
      {
        name: ["address"],
        value: "",
      },
      {
        name: ["is_public"],
        value: "",
      },
      {
        name: ["country"],
        value: "",
      },
      {
        name: ["state"],
        value: "",
      },
    ]);
  }, []);

  useEffect(() => {
    if (autoCompleteRef) {
      handleAddress();
    }
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const res = await googleService.getLatLng(values.address);
        var point = {};
        var addressShortName = {};
        var addressLongName = {};
        if (
          res.results.length > 0 &&
          res.results[res.results.length - 1].geometry
        ) {
          point = {
            lat: res.results[res.results.length - 1].geometry.location.lat,
            lng: res.results[res.results.length - 1].geometry.location.lng,
          };

          const addressComponents =
            res.results[res.results.length - 1].address_components;
          const indexCountry = _.findIndex(
            addressComponents,
            function (address) {
              return address.types[0] === "country";
            }
          );
          const indexState = _.findIndex(addressComponents, function (address) {
            return address.types[0] === "administrative_area_level_1";
          });
          addressShortName = {
            country: addressComponents[indexCountry].short_name || "",
            state: addressComponents[indexState].short_name || "",
          };

          addressLongName = {
            country: addressComponents[indexCountry].long_name || "",
            state: addressComponents[indexState].long_name || "",
          };
        } else {
          setErrMsg("The address invalid");
          setLoading(false);
        }

        const data = {
          property: Object.assign(values, { point }, addressLongName, {
            acreage_unit: "acres",
            use_my_account: false,
            purpose: props.purpose,
          }),
          addressShortName: addressShortName,
        };
        data["property"].address =
          res.results[res.results.length - 1].formatted_address;
        data["property"].is_public =
          data["property"].is_public === "public" ? 1 : 0;
        if (Object.keys(addressShortName).length !== 0) {
          onStore(data);
        } else {
          setLoading(false);
          setTimeout(() => {
            setErrMsg("");
          }, 3000);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const onStore = (data) => {
    propertyService.storeOrUpdateProperties(data, null).then((res) => {
      if (res.code === "0000") {
        console.log(res);
        console.log(res.property.id);
        setLoading(false);
        props.getPropertyList();
        props.setStep("invitation");
        props.setPropertyId(res.property.id)
        localStorage.setItem("active_property", JSON.stringify(res.property));
      }
    });
  };

  return (
    <>
      <img className="small-logo" src={logo} />
      <h1 className="text-title">What would you like to name your Property?</h1>
      <p className="text-noti">
        Your property is where you build your map, manage safety assets,
        livestock, machinery, crops and staff.
      </p>
      <Form form={form} layout="vertical" scrollToFirstError fields={fields}>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            style={{ margin: "15px 15px 0 15px" }}
          />
        )}
        <Form.Item
          name={["name"]}
          className="isoInputWrapper"
          label="Property Name"
          rules={[
            {
              required: true,
              message: "This field is required",
              whitespace: true,
            },
          ]}
        >
          <Input size="large" placeholder="Property Name" />
        </Form.Item>
        <Form.Item
          name={["address"]}
          className="isoInputWrapper"
          label="Property Address"
          rules={[
            {
              required: true,
              message: "This field is required",
              whitespace: true,
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Property Address"
            ref={autoCompleteRef}
          />
        </Form.Item>
        <Form.Item
          name={["is_public"]}
          className="isoInputWrapper"
          label="Public Registration"
          rules={[
            {
              required: true,
              message: "This field is required",
              whitespace: true,
            },
          ]}
        >
          <Select placeholder="Public Registration" size="large">
            <Option value={"public"}>Public</Option>
            <Option value={"private"}>Private</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={["state"]}
          className="isoInputWrapper hidden"
          label="State"
        >
          <Input size="large" placeholder="State" />
        </Form.Item>
        <Form.Item
          name={["country"]}
          className="isoInputWrapper hidden"
          label="Country"
        >
          <Input size="large" placeholder="Country" />
        </Form.Item>
      </Form>
      <p className="text-footer">
        This allows outside people to request to join your property, you still
        allow or reject the request but it can make things easier for you.
      </p>
      <Row className="button-group" gutter={16}>
        <Col span={24}>
          <Button
            className="submit-btn"
            type="primary"
            size="large"
            loading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
      
    </>
  );
};

export default LeftSection;
