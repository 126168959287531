import React from 'react';
import { Button} from 'antd';

const StyleSatelliteControl = (props) =>  {
  const {setStyleMap,styleMap,mapgl,switchLayer} = props;
  const onClick =() => {
    if(mapgl){
      setStyleMap(process.env.REACT_APP_MAPBOX_STYLE_SATELLITE)
      switchLayer(mapgl,process.env.REACT_APP_MAPBOX_STYLE_SATELLITE);
    }
    
  }
  return(
    <Button 
      className={
        styleMap === process.env.REACT_APP_MAPBOX_STYLE_SATELLITE ? 
        "mapboxgl-ctrl-active mapboxgl-ctrl-icon mapboxgl-ctrl-satellite-mode" : 
        "mapboxgl-ctrl-icon mapboxgl-ctrl-satellite-mode"
      } 
      onClick={onClick}></Button>
  )
}

export default StyleSatelliteControl;


