import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const InvitationModalWrapper = styled(Modal)`
  .ant-modal-body{
    padding: 32px;
  }
  .instruction {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 33px;
  }
  .submit-btn {
    border-radius: 10px !important;
    background:rgb(248, 14, 70);
    width: 100%;
    height: 48px;
    margin-top: 51px;
  }
`;

export default WithDirection(InvitationModalWrapper);
