import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const DeviceMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius : 10px;
  .reload-button{
    color: #729D68;
    padding: 5px 8px;
  }
  .reload-button .anticon-sync{
    line-height: 0;
  }
  .leaflet-container{
    border-radius : 10px;
    z-index:1;
  }
  .statistic-card{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.6);
    border-radius: 10px;
    height:auto;
    margin-bottom: 15px;
  }
  .primary-objects-tooltip{
    background: transparent;
    color: white;
    &.dark {
      color: #000000!important;
    }
    font-weight: 600;
    border: none;
    box-shadow: none;
  }
`;
export default WithDirection(DeviceMapWrapper);