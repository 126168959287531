import styled from 'styled-components';

const HeatBarWrapper = styled.div`
  width: 80px;
  height: 60vh;

  .heat-label {
    color: #FFFFFF;
  }

  .mt-1 {
    margin-top: 0.5rem;
  }

  .heat-item {
    height: calc(100% / 15);
    width: 100%;
    display: flex;
    align-items: center;

    .color-rec {
      width: 45%;
      height: 100%;
      margin-right: 5px;
    }
    span {
      color: #FFFFFF;
      float: right;
    }
  }
`;

export { HeatBarWrapper };
