import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const dashboardService = {
  get,
  getDashboardData
};

function get(propertyId,weather_api_type){
  return (
    axios
      .get(
        `${apiEndpoint.dashboard}?property_id=${propertyId}&weather_api_type=${weather_api_type}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}
function getDashboardData(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.dashboard}/get-dashboard-data?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}
