import React from 'react';
import { Table, Tag, Space } from 'antd';
import type { ColumnsType } from 'antd/lib/table';


const Table9 = () => {
  const columns = [
    {
      title: 'Month',
      dataIndex: 'month',
    },
    {
      title: 'Poor',
      dataIndex: 'poor',
    },
    {
      title: 'Average',
      dataIndex: 'average',
    },    
    {
      title: 'Good',
      dataIndex: 'good',
    },  
    {
      title: 'Very good',
      dataIndex: 'veryGood',
    },
  ];
  const data = [
    { key: 1,
      month: 'April',
      poor: 5,
      average: 15,
      good: 25,
      veryGood: 30,
    },
    { key: 2,
      month: 'May',
      poor: 15,
      average: 20,
      good: 25,
      veryGood: 30,
    },
    { key: 3,
      month: 'June',
      poor: 7,
      average: 15,
      good: 20,
      veryGood: 25,
    },
    { key: 4,
      month: 'July',
      poor: 5,
      average: 10,
      good: 15,
      veryGood: 25,
    },
    { key: 5,
      month: 'August',
      poor: 15,
      average: 30,
      good: 45,
      veryGood: 60,
    }, 
    { key: 6,
      month: 'September',
      poor: 35,
      average: 50,
      good: 80,
      veryGood: 90,
    },     
    { key: 7,
      month: 'October',
      poor: 40,
      average: 70,
      good: 90,
      veryGood: 100,
    },    
    { key: 8,
      month: 'November',
      poor: 30,
      average: 60,
      good: 80,
      veryGood: 80,
    }, 
    { key: 9,
      month: 'December',
      poor: 20,
      average: 40,
      good: 60,
      veryGood: 60,
    }, 
    
  ];

  
  return (
    <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      
    </div>
  )
}


export default Table9;