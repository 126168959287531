import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const mobService = {
  getList,
  storeOrUpdate,
  destroy,
  getByObject,
  splitMob,
  importMob,
  mergeMob,
  archiveMob,
  getDataReportCSV,
  chooseSplitMob
};

function getList(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.mobs}?property_id=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.mobs(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.mobs,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.mobs(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function getDataReportCSV(id){
  return (
    axios
      .get(
        `${apiEndpoint.mobs}/get-data-report?id=${id}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getByObject(objectId){
  return (
    axios
      .get(
        `${apiEndpoint.mobs}/get-mobs-by-object?primary_object_id=${objectId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function splitMob(data){
  return (
    axios
      .post(
        apiEndpoint.splitMob,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function chooseSplitMob(data){
  return (
    axios
      .post(
        `${apiEndpoint.mobs}/choose-split-mob`,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function importMob(file_id){
  return (
    axios
      .post(
        `${apiEndpoint.mobs}/import-mob`,
        file_id,
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function mergeMob(data){
  return (
    axios
      .post(
        apiEndpoint.mergeMob,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function archiveMob(data){
  return (
    axios
      .post(
        apiEndpoint.archiveMob,
        data,
        {
          headers: authHeader()
        }
      )
  ).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}