export const aircraftUnits = [
  {
    label: 'Pounds',
    value: 'pounds',
  },
  {
    label: 'Kilograms',
    value: 'kilograms',
  },
  {
    label: 'Litres',
    value: 'litres',
  },
  {
    label: 'Other',
    value: 'other',
  }
]