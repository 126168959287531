import React, { useState } from "react";
import { Button, Row, Col } from "antd";
import logo from "@iso/assets/images/logo.png";
import { set } from "lodash";
import { Link } from "react-router-dom";

const LeftSection = (props) => {
  return (
    <>
      <img className="small-logo" src={logo} />
      <h1 className="text-title">Our Golden Gift</h1>
      <div className="description">
        <p>
          We want to show you everything we have been working on, so on behalf
          of the SafeFarm team. Here is an invitation to 3 months free on our top
          “Gold” subscription
        </p>
        <p>
          You can downgrade at anytime but this gives you the time to explore
          and setup with no stress or time pressure
        </p>
      </div>
      <Row className="button-group" gutter={0}>
        <Col xs={{span: 24}} md={{span:12}} lg={{span: 12}}>
          <Link to="/property-map">
            <Button
              id="continue-free-version-btn"
              className="submit-btn"
              type="primary"
              size="large"
            >
              Continue with the free version
            </Button>
          </Link>
        </Col>
        <Col xs={{span: 24}} md={{span:12}} lg={{span: 12}}>
          <Button
            className="outline-btn"
            size="large"
            onClick={props.openTrialConfirmationModal}
          >
            Try Gold for free
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default LeftSection;
