import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Input, Button, InputNumber, Select, Popconfirm, Row, Col, Tabs, Upload, Switch,Space,Collapse,Card} from 'antd';
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { calculatorPaddock } from '@iso/services';
import { EditOutlined,CloseOutlined } from '@ant-design/icons';
import PaddockWrap from './PaddockWrap.styles';
import EditPlanModal from './EditPlanModal';
import styled from 'styled-components';

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const Paddock = (props) =>{
  const [visibleEditPlan, setVisibleEditPlan] = useState(false);
  const [idPaddock,setIdPaddock] = useState('');
  const [activeKey,setActiveKey] = useState([]);
  const [dataPaddockId,setDataPaddockId] = useState({});
  const [dataResultId,setDataResultId] = useState({});

  const onHandleEditPaddock = (e) =>{
    
    setVisibleEditPlan(true);
    setIdPaddock(e.id);
    setDataPaddockId(e);
      _.forEach(e.stock, (item, index) => {
          setActiveKey(activeKey => [...activeKey, item.stock_number]);
      });
    calculatorPaddock.view(e.id).then(res => {
      if(res.code === '0000'){
        setDataResultId(res.result);
      }
    });

    
  } 

  const onHandleDeletePaddock = (e) =>{
    calculatorPaddock.destroy(e.id).then(res => {
      if(res.code === '0000'){
        props.getListPaddock();
      }
    });
  }
  const cancelEditPlanModal = () =>{
    setVisibleEditPlan(false);
    setActiveKey([]);

  }

  return(
    <PaddockWrap className="paddock-wrap">
    <br/>
    {
      props.dataPaddock.map((paddock, index) => { 
        return(
          <div>
            <Card 
            title={paddock.name}
              extra={
                <div>
                  <Button type="link" className="btn-n-br btn-edit-paddock" onClick={onHandleEditPaddock.bind(this, paddock)}>
                    <EditOutlined />
                  </Button>
                  <Popconfirm
                  className="confirm-delete-plan"
                  title="Do you want to delete"
                  trigger="click"
                  onConfirm={onHandleDeletePaddock.bind(this, paddock)}
                  okText="Yes"
                  cancelText="No"
                  
                  >
                  <Button type="link" className="btn-n-br btn-delete-paddock">
                    <CloseOutlined/>
                  </Button> 
                  </Popconfirm>
                </div>
              } 
              >
              <p><b>Paddock size:</b> {paddock.size} ha</p>
              <p><b>Current feed on offer (FOO):</b> {paddock.foo} Kg DM/ha</p>
              <p><b>Consumption Wastage Factor:</b> {paddock.consumption_wastage_factor} %</p>
              <p><b>Pasture growth: </b>{paddock.pasture_growth} Kg DM/ha/day</p>
              <p><b>Desired FOO when animals removed: </b>{paddock.desired_foo} Kg DM/ha</p>
            </Card>
          <br/>
          </div>

        )
       })
    }
    <EditPlanModal visibleEditPlanModal={visibleEditPlan} cancelEditPlanModal={cancelEditPlanModal} idPaddock={idPaddock} activeKey={activeKey}getListPaddock={props.getListPaddock}/>
    </PaddockWrap>
  )

}

export default Paddock;
