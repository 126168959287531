export const averageWeightCows  = [
  {
    label: '400',
    value: 400,
  },
  {
    label: '500',
    value: 500,
  },
  {
    label: '600',
    value: 600
  },
  {
    label: '700',
    value: 700
  },
]