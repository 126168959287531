export const dataIntake = [
{
  "cows" : {
    "500" : {
      "40" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 1.1,
            "energy": 53,
          },
          "latePreg":{
            "intake": 1.1,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 1.7,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 2.2,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 1.3,
            "energy": 61,
          },
          "latePreg":{
            "intake": 1.3,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 2,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 2.7,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 1.6,
            "energy": 68,
          },
          "latePreg":{
            "intake": 1.6,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 2.4,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 3.1,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 1.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 1.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 2.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 3.5,
            "energy": 198,
          }
        },
      },
      "50" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 2,
            "energy": 53,
          },
          "latePreg":{
            "intake": 2,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 3.1,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 4.1,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 2.5,
            "energy": 61,
          },
          "latePreg":{
            "intake": 2.5,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 3.8,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 5,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 3,
            "energy": 68,
          },
          "latePreg":{
            "intake": 3,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 4.5,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 5.9,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 3.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 3.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 5.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 6.6,
            "energy": 198,
          }
        },
      },
      "60" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 2.6,
            "energy": 53,
          },
          "latePreg":{
            "intake": 2.6,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 4.1,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 5.4,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 3.3,
            "energy": 61,
          },
          "latePreg":{
            "intake": 3.3,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 5,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 6.6,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 3.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 3.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 5.9,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 7.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 4.6,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.6,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 6.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 8.7,
            "energy": 198,
          }
        },
      },      
      "70" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 3.2,
            "energy": 53,
          },
          "latePreg":{
            "intake": 3.2,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 5.1,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 6.6,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 4.1,
            "energy": 61,
          },
          "latePreg":{
            "intake": 4.1,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 6.2,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 8.2,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 4.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 7.3,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 9.6,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 5.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 5.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 8.4,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 10.8,
            "energy": 198,
          }
        },
      }, 
      "75" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 3.9,
            "energy": 53,
          },
          "latePreg":{
            "intake": 3.9,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 7.7,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 4.9,
            "energy": 61,
          },
          "latePreg":{
            "intake": 4.9,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 7.3,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 9.5,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 5.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 5.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 8.5,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 11.1,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 6.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 6.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 9.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 12.4,
            "energy": 198,
          }
        },
      },   
      "80" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 4.5,
            "energy": 53,
          },
          "latePreg":{
            "intake": 4.5,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 6.8,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 8.8,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 5.6,
            "energy": 61,
          },
          "latePreg":{
            "intake": 5.6,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 8.3,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 10.8,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 6.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 6.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 9.7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 12.5,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 7.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 7.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 11.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 14,
            "energy": 198,
          }
        },
      }
    },    
    "1000" : {
      "40" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 1.8,
            "energy": 53,
          },
          "latePreg":{
            "intake": 1.8,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 2.8,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 3.7,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 2.2,
            "energy": 61,
          },
          "latePreg":{
            "intake": 2.2,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 3.4,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 4.5,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 2.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 2.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 4.1,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 5.2,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 3.2,
            "energy": 68,
          },
          "latePreg":{
            "intake": 3.2,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 4.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 5.9,
            "energy": 198,
          }
        },
      },
      "50" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 3.1,
            "energy": 53,
          },
          "latePreg":{
            "intake": 3.1,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 4.9,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 6.4,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 3.9,
            "energy": 61,
          },
          "latePreg":{
            "intake": 3.9,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 6.1,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 7.9,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 4.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 7.1,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 9.2,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 5.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 5.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 8.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 10.4,
            "energy": 198,
          }
        },
      },
      "60" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 4.1,
            "energy": 53,
          },
          "latePreg":{
            "intake": 4.1,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 6.3,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 8.2,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 5.1,
            "energy": 61,
          },
          "latePreg":{
            "intake": 5.1,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 7.7,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 10.2,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 6.1,
            "energy": 68,
          },
          "latePreg":{
            "intake": 6.1,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 9.1,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 11.8,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 7.1,
            "energy": 68,
          },
          "latePreg":{
            "intake": 7.1,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 10.4,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 13.3,
            "energy": 198,
          }
        },
      },      
      "70" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 5,
            "energy": 53,
          },
          "latePreg":{
            "intake": 5,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 7.6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 9.8,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 6.3,
            "energy": 61,
          },
          "latePreg":{
            "intake": 6.3,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 9.3,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 12.1,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 4.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 7.3,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 9.6,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 7.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 7.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 10.9,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 13.9,
            "energy": 198,
          }
        },
      }, 
      "75" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 5.6,
            "energy": 53,
          },
          "latePreg":{
            "intake": 5.6,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 8.3,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 10.7,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 7,
            "energy": 61,
          },
          "latePreg":{
            "intake": 7,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 10.2,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 13.2,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 8.4,
            "energy": 68,
          },
          "latePreg":{
            "intake": 8.4,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 12,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 15.2,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 9.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 13.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 17.1,
            "energy": 198,
          }
        },
      },   
      "80" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.1,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.1,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 11.6,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 7.6,
            "energy": 61,
          },
          "latePreg":{
            "intake": 7.6,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 14.3,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.2,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.2,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 13,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 16.5,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 10.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 10.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 15,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 18.5,
            "energy": 198,
          }
        },
      }
    },
    "1500" : {
      "40" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 2.3,
            "energy": 53,
          },
          "latePreg":{
            "intake": 2.3,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 3.6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 4.7,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 2.9,
            "energy": 61,
          },
          "latePreg":{
            "intake": 2.9,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 4.4,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 5.8,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 3.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 3.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 5.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 6.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 4.1,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.1,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 6,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 7.6,
            "energy": 198,
          }
        },
      },
      "50" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 3.8,
            "energy": 53,
          },
          "latePreg":{
            "intake": 3.8,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 7.8,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 4.8,
            "energy": 61,
          },
          "latePreg":{
            "intake": 4.8,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 7.3,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 9.6,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 5.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 5.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 8.7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 11.4,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 6.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 6.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 10,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 12.5,
            "energy": 198,
          }
        },
      },
      "60" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 4.8,
            "energy": 53,
          },
          "latePreg":{
            "intake": 4.8,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 7.4,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 9,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 6.1,
            "energy": 61,
          },
          "latePreg":{
            "intake": 6.1,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 9.1,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 11.9,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 7.3,
            "energy": 68,
          },
          "latePreg":{
            "intake": 7.3,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 10.7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 13.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 8.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 8.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 12.3,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 15.4,
            "energy": 198,
          }
        },
      },      
      "70" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 5.9,
            "energy": 53,
          },
          "latePreg":{
            "intake": 5.9,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 8.9,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 11.4,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 7.4,
            "energy": 61,
          },
          "latePreg":{
            "intake": 7.4,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 10.8,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 14,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 8.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 8.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 12.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake":16.2,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 10.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 10.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 14.7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 18.1,
            "energy": 198,
          }
        },
      }, 
      "75" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.2,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.2,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9.2,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 11.8,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 7.8,
            "energy": 61,
          },
          "latePreg":{
            "intake": 7.8,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11.2,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 14.5,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.3,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.3,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 13.3,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 16.8,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 10.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 10.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 15.3,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 18.8,
            "energy": 198,
          }
        },
      },   
      "80" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.4,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.4,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9.5,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 12.2,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 8.1,
            "energy": 61,
          },
          "latePreg":{
            "intake": 8.1,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11.6,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 15,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.7,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.7,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 13.7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 17.3,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 11.3,
            "energy": 68,
          },
          "latePreg":{
            "intake": 11.3,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 15.8,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 19.4,
            "energy": 198,
          }
        },
      }
    },  
    "2000" : {
      "40" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 2.7,
            "energy": 53,
          },
          "latePreg":{
            "intake": 2.7,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 4.2,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 5.5,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 3.4,
            "energy": 61,
          },
          "latePreg":{
            "intake": 3.4,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 5.1,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 6.7,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 4.1,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.1,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 6,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 7.8,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 4.8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 4.8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 7,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 8.8,
            "energy": 198,
          }
        },
      },
      "50" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 4.3,
            "energy": 53,
          },
          "latePreg":{
            "intake": 4.3,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 6.6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 8.6,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 5.4,
            "energy": 61,
          },
          "latePreg":{
            "intake": 5.4,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 8.1,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 10.6,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 6.4,
            "energy": 68,
          },
          "latePreg":{
            "intake": 6.4,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 9.5,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 12.3,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 7.5,
            "energy": 68,
          },
          "latePreg":{
            "intake": 7.5,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 11,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 13.8,
            "energy": 198,
          }
        },
      },
      "60" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 5.3,
            "energy": 53,
          },
          "latePreg":{
            "intake": 5.3,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 8,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 10.3,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 6.6,
            "energy": 61,
          },
          "latePreg":{
            "intake": 6.6,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 9.8,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 12.7,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 8,
            "energy": 68,
          },
          "latePreg":{
            "intake": 8,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 11.6,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 14.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 9.3,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.3,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 13.4,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 16.5,
            "energy": 198,
          }
        },
      },      
      "70" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.4,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.4,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9.5,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 12.2,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 8,
            "energy": 61,
          },
          "latePreg":{
            "intake": 8,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11.9,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 15.4,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 14,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 17.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 11.6,
            "energy": 68,
          },
          "latePreg":{
            "intake": 11.6,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 16.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 19.9,
            "energy": 198,
          }
        },
      }, 
      "75" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.5,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.5,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9.6,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 12.4,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 8.2,
            "energy": 61,
          },
          "latePreg":{
            "intake": 8.2,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11.9,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 15.4,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 14,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 17.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 11.6,
            "energy": 68,
          },
          "latePreg":{
            "intake": 11.6,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 16.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 19.9,
            "energy": 198,
          }
        },
      },   
      "80" : {
        "400" : {
          "dryEarlyPreg":{
            "intake": 6.6,
            "energy": 53,
          },
          "latePreg":{
            "intake": 6.6,
            "energy": 68,
          },
          "earlyLactation":{
            "intake": 9.7,
            "energy": 106,
          },
          "lateLactation":{
            "intake": 12.5,
            "energy": 137,
          }
        },
        "500" : {
          "dryEarlyPreg":{
            "intake": 8.3,
            "energy": 61,
          },
          "latePreg":{
            "intake": 8.3,
            "energy": 84,
          },
          "earlyLactation":{
            "intake": 11.9,
            "energy": 137,
          },
          "lateLactation":{
            "intake": 15.4,
            "energy": 167,
          }
        },
        "600" : {
          "dryEarlyPreg":{
            "intake": 9.9,
            "energy": 68,
          },
          "latePreg":{
            "intake": 9.9,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 14,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 17.7,
            "energy": 198,
          }
        },
        "700" : {
          "dryEarlyPreg":{
            "intake": 11.6,
            "energy": 68,
          },
          "latePreg":{
            "intake": 11.6,
            "energy": 99,
          },
          "earlyLactation":{
            "intake": 16.2,
            "energy": 167,
          },
          "lateLactation":{
            "intake": 19.9,
            "energy": 198,
          }
        },
      }
    },
  },
  "weanerCattle" : {
    "500":{
      "40":{
        "150":{
          "intake": 1,
          "energy": 20,
        },
        "200":{
          "intake": 1.1,
          "energy": 25,
        },
        "250":{
          "intake": 1.2,
          "energy": 30,
        },
        "300":{
          "intake": 1.3,
          "energy": 35,
        },
        "350":{
          "intake": 1.4,
          "energy": 40,
        },
        "400":{
          "intake": 1.5,
          "energy": 45,
        },
        "450":{
          "intake": 1.6,
          "energy": 50,
        },
        "500":{
          "intake": 1.6,
          "energy": 55,
        },
      },
      "50":{
        "150":{
          "intake": 1.8,
          "energy": 20,
        },
        "200":{
          "intake": 2.1,
          "energy": 25,
        },
        "250":{
          "intake": 2.4,
          "energy": 30,
        },
        "300":{
          "intake": 2.5,
          "energy": 35,
        },
        "350":{
          "intake": 2.7,
          "energy": 40,
        },
        "400":{
          "intake": 2.8,
          "energy": 45,
        },
        "450":{
          "intake": 2.9,
          "energy": 50,
        },
        "500":{
          "intake": 3,
          "energy": 55,
        },
      }, 
      "60":{
        "150":{
          "intake": 2.3,
          "energy": 20,
        },
        "200":{
          "intake": 2.8,
          "energy": 25,
        },
        "250":{
          "intake": 3.1,
          "energy": 30,
        },
        "300":{
          "intake": 3.3,
          "energy": 35,
        },
        "350":{
          "intake": 3.6,
          "energy": 40,
        },
        "400":{
          "intake": 3.8,
          "energy": 45,
        },
        "450":{
          "intake": 3.9,
          "energy": 50,
        },
        "500":{
          "intake": 3.9,
          "energy": 55,
        },
      },  
      "70":{
        "150":{
          "intake": 2.9,
          "energy": 20,
        },
        "200":{
          "intake": 3.5,
          "energy": 25,
        },
        "250":{
          "intake": 3.9,
          "energy": 30,
        },
        "300":{
          "intake": 4.1,
          "energy": 35,
        },
        "350":{
          "intake": 4.5,
          "energy": 40,
        },
        "400":{
          "intake": 4.7,
          "energy": 45,
        },
        "450":{
          "intake": 4.9,
          "energy": 50,
        },
        "500":{
          "intake": 4.9,
          "energy": 55,
        },
      }, 
      "75":{
        "150":{
          "intake": 2.9,
          "energy": 20,
        },
        "200":{
          "intake": 3.5,
          "energy": 25,
        },
        "250":{
          "intake": 4.6,
          "energy": 30,
        },
        "300":{
          "intake": 4.9,
          "energy": 35,
        },
        "350":{
          "intake": 5.4,
          "energy": 40,
        },
        "400":{
          "intake": 5.6,
          "energy": 45,
        },
        "450":{
          "intake": 5.8,
          "energy": 50,
        },
        "500":{
          "intake": 5.8,
          "energy": 55,
        },
      },
      "80":{
        "150":{
          "intake": 3.9,
          "energy": 20,
        },
        "200":{
          "intake": 4.7,
          "energy": 25,
        },
        "250":{
          "intake": 5.3,
          "energy": 30,
        },
        "300":{
          "intake": 5.7,
          "energy": 35,
        },
        "350":{
          "intake": 6.2,
          "energy": 40,
        },
        "400":{
          "intake": 6.5,
          "energy": 45,
        },
        "450":{
          "intake": 6.7,
          "energy": 50,
        },
        "500":{
          "intake": 6.7,
          "energy": 55,
        },
      },
    },
    "1000":{
      "40":{
        "150":{
          "intake": 1.6,
          "energy": 20,
        },
        "200":{
          "intake": 1.9,
          "energy": 25,
        },
        "250":{
          "intake": 2.1,
          "energy": 30,
        },
        "300":{
          "intake": 2.2,
          "energy": 35,
        },
        "350":{
          "intake": 2.4,
          "energy": 40,
        },
        "400":{
          "intake": 2.6,
          "energy": 45,
        },
        "450":{
          "intake": 2.6,
          "energy": 50,
        },
        "500":{
          "intake": 2.7,
          "energy": 55,
        },
      },
      "50":{
        "150":{
          "intake": 2.7,
          "energy": 20,
        },
        "200":{
          "intake": 3.3,
          "energy": 25,
        },
        "250":{
          "intake": 3.7,
          "energy": 30,
        },
        "300":{
          "intake": 4,
          "energy": 35,
        },
        "350":{
          "intake": 4.2,
          "energy": 40,
        },
        "400":{
          "intake": 4.5,
          "energy": 45,
        },
        "450":{
          "intake": 4.6,
          "energy": 50,
        },
        "500":{
          "intake": 4.7,
          "energy": 55,
        },
      }, 
      "60":{
        "150":{
          "intake": 3.5,
          "energy": 20,
        },
        "200":{
          "intake": 4.2,
          "energy": 25,
        },
        "250":{
          "intake": 4.8,
          "energy": 30,
        },
        "300":{
          "intake": 5.1,
          "energy": 35,
        },
        "350":{
          "intake": 5.4,
          "energy": 40,
        },
        "400":{
          "intake": 5.9,
          "energy": 45,
        },
        "450":{
          "intake": 6,
          "energy": 50,
        },
        "500":{
          "intake": 6.1,
          "energy": 55,
        },
      },  
      "70":{
        "150":{
          "intake": 4.3,
          "energy": 20,
        },
        "200":{
          "intake": 5.2,
          "energy": 25,
        },
        "250":{
          "intake": 5.4,
          "energy": 30,
        },
        "300":{
          "intake": 6.4,
          "energy": 35,
        },
        "350":{
          "intake": 6.7,
          "energy": 40,
        },
        "400":{
          "intake": 7.2,
          "energy": 45,
        },
        "450":{
          "intake": 7.4,
          "energy": 50,
        },
        "500":{
          "intake": 7.5,
          "energy": 55,
        },
      }, 
      "75":{
        "150":{
          "intake": 4.7,
          "energy": 20,
        },
        "200":{
          "intake": 5.8,
          "energy": 25,
        },
        "250":{
          "intake": 6.4,
          "energy": 30,
        },
        "300":{
          "intake": 7.3,
          "energy": 35,
        },
        "350":{
          "intake": 7.8,
          "energy": 40,
        },
        "400":{
          "intake": 8.3,
          "energy": 45,
        },
        "450":{
          "intake": 8.5,
          "energy": 50,
        },
        "500":{
          "intake": 8.6,
          "energy": 55,
        },
      },
      "80":{
        "150":{
          "intake": 5,
          "energy": 20,
        },
        "200":{
          "intake": 6.3,
          "energy": 25,
        },
        "250":{
          "intake": 7.3,
          "energy": 30,
        },
        "300":{
          "intake": 8.2,
          "energy": 35,
        },
        "350":{
          "intake": 8.9,
          "energy": 40,
        },
        "400":{
          "intake": 9.3,
          "energy": 45,
        },
        "450":{
          "intake": 9.5,
          "energy": 50,
        },
        "500":{
          "intake": 9.6,
          "energy": 55,
        },
      },
    },
    "1500":{
      "40":{
        "150":{
          "intake": 2,
          "energy": 20,
        },
        "200":{
          "intake": 2.4,
          "energy": 25,
        },
        "250":{
          "intake": 2.7,
          "energy": 30,
        },
        "300":{
          "intake": 2,
          "energy": 35,
        },
        "350":{
          "intake": 3.2,
          "energy": 40,
        },
        "400":{
          "intake": 3.3,
          "energy": 45,
        },
        "450":{
          "intake": 3.4,
          "energy": 50,
        },
        "500":{
          "intake": 3.5,
          "energy": 55,
        },
      },
      "50":{
        "150":{
          "intake": 3.2,
          "energy": 20,
        },
        "200":{
          "intake": 3.9,
          "energy": 25,
        },
        "250":{
          "intake": 4.5,
          "energy": 30,
        },
        "300":{
          "intake": 4.9,
          "energy": 35,
        },
        "350":{
          "intake": 5.3,
          "energy": 40,
        },
        "400":{
          "intake": 5.6,
          "energy": 45,
        },
        "450":{
          "intake": 5.7,
          "energy": 50,
        },
        "500":{
          "intake": 5.8,
          "energy": 55,
        },
      }, 
      "60":{
        "150":{
          "intake": 4,
          "energy": 20,
        },
        "200":{
          "intake": 4.9,
          "energy": 25,
        },
        "250":{
          "intake": 5.6,
          "energy": 30,
        },
        "300":{
          "intake": 6.2,
          "energy": 35,
        },
        "350":{
          "intake": 6.7,
          "energy": 40,
        },
        "400":{
          "intake": 7,
          "energy": 45,
        },
        "450":{
          "intake": 7.2,
          "energy": 50,
        },
        "500":{
          "intake": 7.3,
          "energy": 55,
        },
      },  
      "70":{
        "150":{
          "intake": 4.9,
          "energy": 20,
        },
        "200":{
          "intake": 6,
          "energy": 25,
        },
        "250":{
          "intake": 6.9,
          "energy": 30,
        },
        "300":{
          "intake": 7.5,
          "energy": 35,
        },
        "350":{
          "intake": 8.2,
          "energy": 40,
        },
        "400":{
          "intake": 8.6,
          "energy": 45,
        },
        "450":{
          "intake": 8.8,
          "energy": 50,
        },
        "500":{
          "intake": 8.9,
          "energy": 55,
        },
      }, 
      "75":{
        "150":{
          "intake": 5,
          "energy": 20,
        },
        "200":{
          "intake": 6.2,
          "energy": 25,
        },
        "250":{
          "intake": 7.1,
          "energy": 30,
        },
        "300":{
          "intake": 7.9,
          "energy": 35,
        },
        "350":{
          "intake": 8.6,
          "energy": 40,
        },
        "400":{
          "intake": 9,
          "energy": 45,
        },
        "450":{
          "intake": 9.2,
          "energy": 50,
        },
        "500":{
          "intake": 9.3,
          "energy": 55,
        },
      },
      "80":{
        "150":{
          "intake": 5,
          "energy": 20,
        },
        "200":{
          "intake": 6.3,
          "energy": 25,
        },
        "250":{
          "intake": 7.3,
          "energy": 30,
        },
        "300":{
          "intake": 8.2,
          "energy": 35,
        },
        "350":{
          "intake": 8.9,
          "energy": 40,
        },
        "400":{
          "intake": 9.3,
          "energy": 45,
        },
        "450":{
          "intake": 9.5,
          "energy": 50,
        },
        "500":{
          "intake": 9.6,
          "energy": 55,
        },
      },
    },
    "2000":{
      "40":{
        "150":{
          "intake": 2.3,
          "energy": 20,
        },
        "200":{
          "intake": 2.7,
          "energy": 25,
        },
        "250":{
          "intake": 3.1,
          "energy": 30,
        },
        "300":{
          "intake": 3.4,
          "energy": 35,
        },
        "350":{
          "intake": 3.7,
          "energy": 40,
        },
        "400":{
          "intake": 3.9,
          "energy": 45,
        },
        "450":{
          "intake": 4,
          "energy": 50,
        },
        "500":{
          "intake": 4,
          "energy": 55,
        },
      },
      "50":{
        "150":{
          "intake": 3.5,
          "energy": 20,
        },
        "200":{
          "intake": 4.3,
          "energy": 25,
        },
        "250":{
          "intake": 5,
          "energy": 30,
        },
        "300":{
          "intake": 5.4,
          "energy": 35,
        },
        "350":{
          "intake": 5.9,
          "energy": 40,
        },
        "400":{
          "intake": 6.2,
          "energy": 45,
        },
        "450":{
          "intake": 6.3,
          "energy": 50,
        },
        "500":{
          "intake": 6.4,
          "energy": 55,
        },
      }, 
      "60":{
        "150":{
          "intake": 4.3,
          "energy": 20,
        },
        "200":{
          "intake": 5.1,
          "energy": 25,
        },
        "250":{
          "intake": 6.1,
          "energy": 30,
        },
        "300":{
          "intake": 6.7,
          "energy": 35,
        },
        "350":{
          "intake": 7.3,
          "energy": 40,
        },
        "400":{
          "intake": 7.6,
          "energy": 45,
        },
        "450":{
          "intake": 7.8,
          "energy": 50,
        },
        "500":{
          "intake": 7.9,
          "energy": 55,
        },
      },  
      "70":{
        "150":{
          "intake": 5.1,
          "energy": 20,
        },
        "200":{
          "intake": 6.4,
          "energy": 25,
        },
        "250":{
          "intake": 7.4,
          "energy": 30,
        },
        "300":{
          "intake": 8.2,
          "energy": 35,
        },
        "350":{
          "intake": 8.9,
          "energy": 40,
        },
        "400":{
          "intake": 9.3,
          "energy": 45,
        },
        "450":{
          "intake": 9.7,
          "energy": 50,
        },
        "500":{
          "intake": 9.9,
          "energy": 55,
        },
      }, 
      "75":{
        "150":{
          "intake": 5.2,
          "energy": 20,
        },
        "200":{
          "intake": 6.5,
          "energy": 25,
        },
        "250":{
          "intake": 7.5,
          "energy": 30,
        },
        "300":{
          "intake": 8.3,
          "energy": 35,
        },
        "350":{
          "intake": 9,
          "energy": 40,
        },
        "400":{
          "intake": 9.4,
          "energy": 45,
        },
        "450":{
          "intake": 9.7,
          "energy": 50,
        },
        "500":{
          "intake": 9.9,
          "energy": 55,
        },
      },
      "80":{
        "150":{
          "intake": 5.2,
          "energy": 20,
        },
        "200":{
          "intake": 6.5,
          "energy": 25,
        },
        "250":{
          "intake": 7.5,
          "energy": 30,
        },
        "300":{
          "intake": 8.4,
          "energy": 35,
        },
        "350":{
          "intake": 9.1,
          "energy": 40,
        },
        "400":{
          "intake": 9.5,
          "energy": 45,
        },
        "450":{
          "intake": 9.7,
          "energy": 50,
        },
        "500":{
          "intake": 9.9,
          "energy": 55,
        },
      },
    },
  },
  "ewesWethers":{
    "500":{
      "40":{
        "40":{
          "dry":{  
            "intake": 0.2,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.2,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.2,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.2,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.2,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.2,
              "energy": 8.8,
            },
            "singles":{
               "intake": 0.2,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.2,
              "energy": 11,
            },
            "singles":{
               "intake": 0.2,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.4,
              "energy": 19.7,
            },
            "singles":{
               "intake": 0.2,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.4,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.4,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.4,
              "energy": 17.3,
            },
            "singles":{
               "intake": 0.4,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.3,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.3,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.3,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.3,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.3,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.3,
              "energy": 10.5,
            },
            "singles":{
               "intake": 0.3,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.3,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.3,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.5,
              "energy": 23.4,
            },
            "singles":{
               "intake": 0.5,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.5,
              "energy": 25.8,
            },
            "singles":{
              "intake": 0.5,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.5,
              "energy": 20.6,
            },
            "singles":{
              "intake": 0.5,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.4,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.4,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.4,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.4,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.4,
              "energy": 12.2,
            },
            "singles":{
               "intake": 0.4,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.4,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.4,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.6,
              "energy": 27.1,
            },
            "singles":{
               "intake": 0.6,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.6,
              "energy": 29.9,
            },
            "singles":{
              "intake": 0.6,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.6,
              "energy": 23.9,
            },
            "singles":{
              "intake": 0.6,
              "energy": 19.4,
            },
          },
        },   
       "70":{
          "dry":{  
            "intake": 0.4,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.4,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.4,
              "energy": 12,
            },
            "singles":{
              "intake": 0.4,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.4,
              "energy": 13.9,
            },
            "singles":{
               "intake": 0.4,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.4,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.4,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.7,
              "energy": 30.9,
            },
            "singles":{
               "intake": 0.7,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.7,
              "energy": 34.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.7,
              "energy": 27.2,
            },
            "singles":{
              "intake": 0.7,
              "energy": 22,
            },
          },
        },   
      },
      "50":{
        "40":{
          "dry":{  
            "intake": 0.4,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.4,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.4,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.4,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.4,
              "energy": 8.8,
            },
            "singles":{
               "intake":0.4,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.4,
              "energy": 11,
            },
            "singles":{
              "intake": 0.4,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.7,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.7,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.7,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.7,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.7,
              "energy": 17.3,
            },
            "singles":{
               "intake": 0.7,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.5,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.5,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.5,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.5,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.5,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.5,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.5,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.5,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.5,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.9,
              "energy": 23.4,
            },
            "singles":{
               "intake": 0.9,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.9,
              "energy": 25.8,
            },
            "singles":{
              "intake": 0.9,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.9,
              "energy": 20.6,
            },
            "singles":{
              "intake": 0.9,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.7,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.7,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.4,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 12.2,
            },
            "singles":{
               "intake": 0.7,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.7,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.1,
              "energy": 27.1,
            },
            "singles":{
               "intake": 1.1,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.1,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.1,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.1,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.1,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 0.8,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 12,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 13.9,
            },
            "singles":{
               "intake": 0.8,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.8,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.3,
              "energy": 30.9,
            },
            "singles":{
               "intake": 1.3,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.3,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.3,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 22,
            },
          },
        },   
      },  
      "60":{
        "40":{
          "dry":{  
            "intake": 0.6,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.6,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.6,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.6,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.6,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.6,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.6,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.6,
              "energy": 11,
            },
            "singles":{
              "intake": 0.6,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.9,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.9,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.9,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.9,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.9,
              "energy": 17.3,
            },
            "singles":{
               "intake": 0.9,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.7,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.7,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.7,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.1,
              "energy": 23.4,
            },
            "singles":{
               "intake": 1.1,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.1,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.1,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.1,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.1,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.8,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.8,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.8,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 12.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.4,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.4,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1,
              "energy": 12,
            },
            "singles":{
              "intake": 1,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.6,
              "energy": 30.9,
            },
            "singles":{
               "intake": 1.6,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.6,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.6,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.6,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.6,
              "energy": 22,
            },
          },
        },   
      },
      "70":{
        "40":{
          "dry":{  
            "intake": 0.7,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.7,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.7,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 11,
            },
            "singles":{
              "intake": 0.7,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.2,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.2,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.2,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.2,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.2,
              "energy": 17.3,
            },
            "singles":{
               "intake": 1.2,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.9,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.9,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.9,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.9,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.9,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.9,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.9,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.9,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.9,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 23.4,
            },
            "singles":{
               "intake": 1.4,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.4,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.4,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.7,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.7,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.7,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.7,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.7,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.7,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.2,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.2,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 12,
            },
            "singles":{
              "intake": 1.2,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1.2,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.2,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.9,
              "energy": 30.9,
            },
            "singles":{
               "intake": 1.9,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.9,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.9,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.9,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.9,
              "energy": 22,
            },
          },
        },   
      },    
      "75":{
        "40":{
          "dry":{  
            "intake": 0.8,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.8,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.8,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.8,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 11,
            },
            "singles":{
              "intake": 0.8,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.3,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.3,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.3,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.3,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.3,
              "energy": 17.3,
            },
            "singles":{
               "intake": 1.3,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.1,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.1,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.1,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.1,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.1,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.1,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.7,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1.7,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.7,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.7,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.7,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.7,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.3,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.3,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.3,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.3,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.3,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.3,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.3,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.5,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.5,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.5,
              "energy": 12,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.5,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.5,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.5,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.5,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.3,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.3,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.3,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.3,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.3,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.3,
              "energy": 22,
            },
          },
        },   
      },      
      "80":{
        "40":{
          "dry":{  
            "intake": 0.9,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.9,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.9,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.9,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.9, 
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.9,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.9,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.9,
              "energy": 11,
            },
            "singles":{
              "intake": 0.9,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.4,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.4,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 17.3,
            },
            "singles":{
               "intake": 1.4,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.2,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.2,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.9,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1.9,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.9,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.9,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.9,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.9,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.5,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.5,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.5,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.5,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.5,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.5,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.5,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.2,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.2,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.2,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.2,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.2,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.2,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.7,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.7,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.7,
              "energy": 12,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.7,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.7,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.7,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.7,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.6,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.6,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.6,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.6,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.6,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.6,
              "energy": 22,
            },
          },
        },   
      },
    },
    "1000":{
      "40":{
        "40":{
          "dry":{  
            "intake": 0.3,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.3,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.3,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.3,
              "energy": 7.6,
            },
            "singles":{
              "intake": 0.3,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.3,
              "energy": 8.8,
            },
            "singles":{
               "intake": 0.3,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.3,
              "energy": 11,
            },
            "singles":{
               "intake": 0.3,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.6,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.6,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.6,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.6,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.6,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.6,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.4,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.4,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.4,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.4,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.4,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.4,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.4,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.4,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.8,
              "energy": 23.4,
            },
            "singles":{
              "intake": 0.8,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.8,
              "energy": 25.8,
            },
            "singles":{
              "intake": 0.8,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.8,
              "energy": 20.6,
            },
            "singles":{
              "intake": 0.8,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.5,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.5,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.5,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.5,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.5,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.5,
              "energy": 12.2,
            },
            "singles":{
              "intake": 0.5,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.5,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.5,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.9,
              "energy": 27.1,
            },
            "singles":{
              "intake": 0.9,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.9,
              "energy": 29.9,
            },
            "singles":{
              "intake": 0.9,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.9,
              "energy": 23.9,
            },
            "singles":{
              "intake": 0.9,
              "energy": 19.4,
            },
          },
        },   
       "70":{
          "dry":{  
            "intake": 0.4,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.4,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.6,
              "energy": 12,
            },
            "singles":{
              "intake": 0.6,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.6,
              "energy": 13.9,
            },
            "singles":{
              "intake": 0.6,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.6,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.6,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1,
              "energy": 30.9,
            },
            "singles":{
              "intake": 1,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1,
              "energy": 22,
            },
          },
        },   
      },
      "50":{
        "40":{
          "dry":{  
            "intake": 0.5,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.5,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.5,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.5,
              "energy": 7.6,
            },
            "singles":{
              "intake": 0.5,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.5,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.5,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.5,
              "energy": 11,
            },
            "singles":{
              "intake": 0.5,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.8,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.8,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.8,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.8,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.8,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.5,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.6,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.6,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.6,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.6,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.6,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.6,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.6,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.6,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.7,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.7,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.4,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 12.2,
            },
            "singles":{
               "intake": 0.7,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.7,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.2,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.2,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.2,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.2,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.2,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 0.8,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 12,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 13.9,
            },
            "singles":{
               "intake": 0.8,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.8,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.3,
              "energy": 30.9,
            },
            "singles":{
               "intake": 1.3,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.3,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.3,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 22,
            },
          },
        },   
      },  
      "60":{
        "40":{
          "dry":{  
            "intake": 0.8,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.8,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.8,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.8,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 11,
            },
            "singles":{
              "intake": 0.8,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.3,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.3,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.3,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.3,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.3,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.3,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.6,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1.6,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.6,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.6,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.6,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.6,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.2,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.2,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.2,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.2,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.2,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.9,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.9,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.9,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.9,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.9,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.9,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.4,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.4,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.4,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.4,
              "energy": 12,
            },
            "singles":{
              "intake": 1.4,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.4,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1.4,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.4,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.4,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.2,
              "energy": 30.9,
            },
            "singles":{
               "intake": 2.2,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.2,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.2,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.2,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.2,
              "energy": 22,
            },
          },
        },   
      },
      "70":{
        "40":{
          "dry":{  
            "intake": 1,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1,
              "energy": 7.6,
            },
            "singles":{
               "intake": 1,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1,
              "energy": 11,
            },
            "singles":{
              "intake": 1,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.6,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.6,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.6,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.6,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.6,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.6,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.2,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.2,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.9,
              "energy": 23.4,
            },
            "singles":{
               "intake": 1.9,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.9,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.9,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.9,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.9,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.4,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.4,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.4,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.4,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.4,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.4,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.4,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.4,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.4,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.2,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.2,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.2,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.2,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.2,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.2,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.7,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.7,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.7,
              "energy": 12,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.7,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1.7,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.7,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.7,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.6,
              "energy": 30.9,
            },
            "singles":{
               "intake": 2.6,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.6,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.6,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.6,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.6,
              "energy": 22,
            },
          },
        },   
      },    
      "75":{
        "40":{
          "dry":{  
            "intake": 1.1,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.1,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.1,
              "energy": 7.6,
            },
            "singles":{
               "intake": 1.1,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.1,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1.1,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.1,
              "energy": 11,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.7,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.7,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.7,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.7,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.7,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.7,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.3,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.3,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.3,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.3,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.3,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2,
              "energy": 23.4,
            },
            "singles":{
              "intake": 2,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2,
              "energy": 25.8,
            },
            "singles":{
              "intake": 2,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2,
              "energy": 20.6,
            },
            "singles":{
              "intake": 2,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.5,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.5,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.5,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.5,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.5,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.5,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.5,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.4,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.4,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.4,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.4,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.4,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.4,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.8,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.8,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.8,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.8,
              "energy": 12,
            },
            "singles":{
              "intake": 1.8,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.8,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.8,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.8,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.8,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.8,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.8,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.8,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.8,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.8,
              "energy": 22,
            },
          },
        },   
      },      
      "80":{
        "40":{
          "dry":{  
            "intake": 1.1,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.1,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.1,
              "energy": 7.6,
            },
            "singles":{
              "intake": 1.1, 
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.1,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1.1,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.1,
              "energy": 11,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.8,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.8,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.8,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.8,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.8,
              "energy": 17.3,
            },
            "singles":{
               "intake": 1.8,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.3,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.3,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.3,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.3,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.3,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.1,
              "energy": 23.4,
            },
            "singles":{
              "intake": 2.1,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.1,
              "energy": 25.8,
            },
            "singles":{
              "intake": 2.1,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.1,
              "energy": 20.6,
            },
            "singles":{
              "intake": 2.1,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.6,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.6,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.6,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.6,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.6,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.6,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.6,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.6,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.6,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.5,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.5,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.5,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.5,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.5,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.5,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.9,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.9,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.9,
              "energy": 12,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.9,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.9,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.9,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.9,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.9,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.9,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.9,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.9,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.9,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.9,
              "energy": 22,
            },
          },
        },   
      },
    },  
    "1500":{
      "40":{
        "40":{
          "dry":{  
            "intake": 0.4,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.4,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.3,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.4,
              "energy": 7.6,
            },
            "singles":{
              "intake": 0.4,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.4,
              "energy": 8.8,
            },
            "singles":{
               "intake": 0.4,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.4,
              "energy": 11,
            },
            "singles":{
               "intake": 0.4,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.7,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.7,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.7,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.7,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.7,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.7,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.6,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.6,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.6,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.6,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.6,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.6,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.6,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.6,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.6,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.9,
              "energy": 23.4,
            },
            "singles":{
              "intake": 0.9,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.9,
              "energy": 25.8,
            },
            "singles":{
              "intake": 0.9,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.9,
              "energy": 20.6,
            },
            "singles":{
              "intake": 0.9,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.5,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.7,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.7,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 12.2,
            },
            "singles":{
              "intake": 0.7,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.7,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.2,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.2,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.2,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.2,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.2,
              "energy": 19.4,
            },
          },
        },   
       "70":{
          "dry":{  
            "intake": 0.8,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 12,
            },
            "singles":{
              "intake": 0.8,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 13.9,
            },
            "singles":{
              "intake": 0.8,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.8,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.3,
              "energy": 30.9,
            },
            "singles":{
              "intake": 1.3,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.3,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.3,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 22,
            },
          },
        },   
      },
      "50":{
        "40":{
          "dry":{  
            "intake": 0.5,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.5,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.5,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.5,
              "energy": 7.6,
            },
            "singles":{
              "intake": 0.5,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.5,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.5,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.5,
              "energy": 11,
            },
            "singles":{
              "intake": 0.5,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 0.9,
              "energy": 19.7,
            },
            "singles":{
              "intake": 0.9,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 0.9,
              "energy": 21.7,
            },
            "singles":{
              "intake": 0.9,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 0.9,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.9,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 0.7,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.7,
              "energy": 8.5,
            },
            "singles":{
              "intake": 0.7,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.7,
              "energy": 9.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.7,
              "energy": 10.5,
            },
            "singles":{
              "intake": 0.7,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.7,
              "energy": 13.1,
            },
            "singles":{
              "intake": 0.7,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 0.8,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 9.9,
            },
            "singles":{
              "intake": 0.8,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 10.6,
            },
            "singles":{
              "intake": 0.8,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 12.2,
            },
            "singles":{
               "intake": 0.8,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 15.2,
            },
            "singles":{
              "intake": 0.8,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 27.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 29.9,
            },
            "singles":{
              "intake": 1.4,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 23.9,
            },
            "singles":{
              "intake": 1.4,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 0.9,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.9,
              "energy": 11.2,
            },
            "singles":{
              "intake": 0.9,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.9,
              "energy": 12,
            },
            "singles":{
              "intake": 0.9,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.9,
              "energy": 13.9,
            },
            "singles":{
               "intake": 0.9,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.9,
              "energy": 17.3,
            },
            "singles":{
              "intake": 0.9,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 30.9,
            },
            "singles":{
               "intake": 1.4,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 34.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 27.2,
            },
            "singles":{
              "intake": 1.4,
              "energy": 22,
            },
          },
        },   
      },  
      "60":{
        "40":{
          "dry":{  
            "intake": 0.8,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 0.8,
              "energy": 7.1,
            },
            "singles":{
              "intake": 0.8,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 0.8,
              "energy": 7.6,
            },
            "singles":{
               "intake": 0.8,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 0.8,
              "energy": 8.8,
            },
            "singles":{
              "intake": 0.8,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 0.8,
              "energy": 11,
            },
            "singles":{
              "intake": 0.8,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.4,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.4,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.4,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.4,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.4,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.4,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.1,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.1,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.1,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.1,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.1,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.1,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.7,
              "energy": 23.4,
            },
            "singles":{
              "intake": 1.7,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.7,
              "energy": 25.8,
            },
            "singles":{
              "intake": 1.7,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.7,
              "energy": 20.6,
            },
            "singles":{
              "intake": 1.7,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.3,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.3,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.3,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.3,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.3,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.3,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.3,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.3,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.1,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.1,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.1,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.1,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.1,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.1,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.5,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.5,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.5,
              "energy": 12,
            },
            "singles":{
              "intake": 1.5,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.5,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1.5,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.5,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.5,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.4,
              "energy": 30.9,
            },
            "singles":{
               "intake": 2.4,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.4,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.4,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.4,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.4,
              "energy": 22,
            },
          },
        },   
      },
      "70":{
        "40":{
          "dry":{  
            "intake": 1.1,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.1,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1.1,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.1,
              "energy": 7.6,
            },
            "singles":{
               "intake": 1.1,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.1,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1.1,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.1,
              "energy": 11,
            },
            "singles":{
              "intake": 1.1,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.7,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.7,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.7,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.7,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.7,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.7,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.3,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.3,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.3,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.3,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.3,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.3,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.3,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2,
              "energy": 23.4,
            },
            "singles":{
               "intake": 2,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2,
              "energy": 25.8,
            },
            "singles":{
              "intake": 2,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2,
              "energy": 20.6,
            },
            "singles":{
              "intake": 2,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.6,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.6,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.6,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.6,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.6,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.6,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.6,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.6,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.6,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.4,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.4,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.4,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.4,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.4,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.4,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.8,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.8,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.8,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.8,
              "energy": 12,
            },
            "singles":{
              "intake": 1.8,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.8,
              "energy": 13.9,
            },
            "singles":{
               "intake": 1.8,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.8,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.8,
              "energy": 30.9,
            },
            "singles":{
               "intake": 2.8,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.8,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.8,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.8,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.8,
              "energy": 22,
            },
          },
        },   
      },    
      "75":{
        "40":{
          "dry":{  
            "intake": 1.2,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 7.6,
            },
            "singles":{
               "intake": 1.2,
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1.2,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 11,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.8,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.8,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.8,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.8,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.8,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.8,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.4,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.4,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.4,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.4,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.4,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.4,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.4,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.1,
              "energy": 23.4,
            },
            "singles":{
              "intake": 2.1,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.1,
              "energy": 25.8,
            },
            "singles":{
              "intake": 2.1,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.1,
              "energy": 20.6,
            },
            "singles":{
              "intake": 2.1,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.7,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.7,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.7,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.7,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.7,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.7,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.7,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.5,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.5,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.5,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.5,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.5,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.5,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.9,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.9,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.9,
              "energy": 12,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.9,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.9,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.9,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.9,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.9,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.9,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.9,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.9,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.9,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.9,
              "energy": 22,
            },
          },
        },   
      },      
      "80":{
        "40":{
          "dry":{  
            "intake": 1.2,
            "energy": 7,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.2,
              "energy": 7.1,
            },
            "singles":{
              "intake": 1.2,
              "energy": 7.2,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.2,
              "energy": 7.6,
            },
            "singles":{
              "intake": 1.2, 
              "energy": 7.6,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.2,
              "energy": 8.8,
            },
            "singles":{
              "intake": 1.2,
              "energy": 8,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.2,
              "energy": 11,
            },
            "singles":{
              "intake": 1.2,
              "energy": 9.4,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 1.9,
              "energy": 19.7,
            },
            "singles":{
              "intake": 1.9,
              "energy": 15.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 1.9,
              "energy": 21.7,
            },
            "singles":{
              "intake": 1.9,
              "energy": 17,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 1.9,
              "energy": 17.3,
            },
            "singles":{
               "intake": 1.9,
              "energy": 14,
            },
          },
        },   
        "50":{
          "dry":{  
            "intake": 1.4,
            "energy": 8.3,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.4,
              "energy": 8.5,
            },
            "singles":{
              "intake": 1.4,
              "energy": 8.6,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.4,
              "energy": 9.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.4,
              "energy": 10.5,
            },
            "singles":{
              "intake": 1.4,
              "energy": 9.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.4,
              "energy": 13.1,
            },
            "singles":{
              "intake": 1.4,
              "energy": 11.2,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.2,
              "energy": 23.4,
            },
            "singles":{
              "intake": 2.2,
              "energy": 18.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.2,
              "energy": 25.8,
            },
            "singles":{
              "intake": 2.2,
              "energy": 20.2,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.2,
              "energy": 20.6,
            },
            "singles":{
              "intake": 2.2,
              "energy": 16.7,
            },
          },
        },       
        "60":{
          "dry":{  
            "intake": 1.7,
            "energy": 9.6,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.7,
              "energy": 9.9,
            },
            "singles":{
              "intake": 1.7,
              "energy": 10,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.7,
              "energy": 10.6,
            },
            "singles":{
              "intake": 1.7,
              "energy": 10.4,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.7,
              "energy": 12.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 11,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.7,
              "energy": 15.2,
            },
            "singles":{
              "intake": 1.7,
              "energy": 13,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.6,
              "energy": 27.1,
            },
            "singles":{
              "intake": 2.6,
              "energy": 21.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.6,
              "energy": 29.9,
            },
            "singles":{
              "intake": 2.6,
              "energy": 23.4,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.6,
              "energy": 23.9,
            },
            "singles":{
              "intake": 2.6,
              "energy": 19.4,
            },
          },
        },   
        "70":{
          "dry":{  
            "intake": 1.9,
            "energy": 11,
          },
          "pregnant50":{
            "twins":{
              "intake": 1.9,
              "energy": 11.2,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.4,
            },
          },
          "pregnant70":{
            "twins":{
              "intake": 1.9,
              "energy": 12,
            },
            "singles":{
              "intake": 1.9,
              "energy": 11.9,
            },
          },
          "pregnant100":{
           "twins":{
              "intake": 1.9,
              "energy": 13.9,
            },
            "singles":{
              "intake": 1.9,
              "energy": 12.5,
            },
          },
          "pregnant130":{
            "twins":{
              "intake": 1.9,
              "energy": 17.3,
            },
            "singles":{
              "intake": 1.9,
              "energy": 14.8,
            },
          },  
          "lactating10":{
            "twins":{
              "intake": 2.9,
              "energy": 30.9,
            },
            "singles":{
              "intake": 2.9,
              "energy": 24.7,
            },
          }, 
          "lactating30":{
            "twins":{
              "intake": 2.9,
              "energy": 34.1,
            },
            "singles":{
              "intake": 2.9,
              "energy": 26.7,
            },
          },  
          "lactating50":{
            "twins":{
              "intake": 2.9,
              "energy": 27.2,
            },
            "singles":{
              "intake": 2.9,
              "energy": 22,
            },
          },
        },   
      },
    },
  },
  "lambs":{
    "500":{
      "40":{
        "15":{
          "intake": 0.2,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.3,
          "energy": 5,
        },
        "35":{
          "intake": 0.3,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.3,
          "energy": 7.8,
        },
      }, 
      "50":{
        "15":{
          "intake": 0.3,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.5,
          "energy": 5,
        },
        "35":{
          "intake": 0.6,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.7,
          "energy": 7.8,
        },
      },  
      "60":{
        "15":{
          "intake": 0.4,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.6,
          "energy": 5,
        },
        "35":{
          "intake": 0.8,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.9,
          "energy": 7.8,
        },
      }, 
      "70":{
        "15":{
          "intake": 0.5,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.8,
          "energy": 5,
        },
        "35":{
          "intake": 1,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.1,
          "energy": 7.8,
        },
      }, 
      "75":{
        "15":{
          "intake": 0.6,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.9,
          "energy": 5,
        },
        "35":{
          "intake": 1.1,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.2,
          "energy": 7.8,
        },
      },   
      "80":{
        "15":{
          "intake": 0.6,
          "energy": 3.5,
        },
        "25":{
          "intake": 1,
          "energy": 5,
        },
        "35":{
          "intake": 1.2,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.3,
          "energy": 7.8,
        },
      },
    },
    "1000":{
      "40":{
        "15":{
          "intake": 0.3,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.4,
          "energy": 5,
        },
        "35":{
          "intake": 0.5,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.6,
          "energy": 7.8,
        },
      }, 
      "50":{
        "15":{
          "intake": 0.5,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.7,
          "energy": 5,
        },
        "35":{
          "intake": 0.9,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.9,
          "energy": 7.8,
        },
      },  
      "60":{
        "15":{
          "intake": 0.6,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.9,
          "energy": 5,
        },
        "35":{
          "intake": 1.1,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.2,
          "energy": 7.8,
        },
      }, 
      "70":{
        "15":{
          "intake": 0.7,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.1,
          "energy": 5,
        },
        "35":{
          "intake": 1.3,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.5,
          "energy": 7.8,
        },
      }, 
      "75":{
        "15":{
          "intake": 0.8,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.2,
          "energy": 5,
        },
        "35":{
          "intake": 1.4,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.6,
          "energy": 7.8,
        },
      },   
      "80":{
        "15":{
          "intake": 0.8,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.2,
          "energy": 5,
        },
        "35":{
          "intake": 1.5,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.6,
          "energy": 7.8,
        },
      },
    },   
    "1500":{
      "40":{
        "15":{
          "intake": 0.4,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.5,
          "energy": 5,
        },
        "35":{
          "intake": 0.6,
          "energy": 6.4,
        },
        "45":{
          "intake": 0.7,
          "energy": 7.8,
        },
      }, 
      "50":{
        "15":{
          "intake": 0.6,
          "energy": 3.5,
        },
        "25":{
          "intake": 0.8,
          "energy": 5,
        },
        "35":{
          "intake": 0.9,
          "energy": 6.4,
        },
        "45":{
          "intake": 1,
          "energy": 7.8,
        },
      },  
      "60":{
        "15":{
          "intake": 0.7,
          "energy": 3.5,
        },
        "25":{
          "intake": 1,
          "energy": 5,
        },
        "35":{
          "intake": 1.2,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.3,
          "energy": 7.8,
        },
      }, 
      "70":{
        "15":{
          "intake": 0.8,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.2,
          "energy": 5,
        },
        "35":{
          "intake": 1.4,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.6,
          "energy": 7.8,
        },
      }, 
      "75":{
        "15":{
          "intake": 0.9,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.3,
          "energy": 5,
        },
        "35":{
          "intake": 1.5,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.7,
          "energy": 7.8,
        },
      },   
      "80":{
        "15":{
          "intake": 0.9,
          "energy": 3.5,
        },
        "25":{
          "intake": 1.3,
          "energy": 5,
        },
        "35":{
          "intake": 1.5,
          "energy": 6.4,
        },
        "45":{
          "intake": 1.7,
          "energy": 7.8,
        },
      },
    },
  }
}

]