import React, {useEffect } from 'react';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import _ from 'lodash';
import DeviceMapWrapper from './DeviceMap.styles'; 
import { objectColors } from '@iso/constants/objectColors';
import DeviceMapMarker from './DeviceMapMarker';


const DeviceMap = (props) => {
  // const {centerPoint, getPositionDevice, positionDevice, propertyId, loading, setLoading, showMarkerAlert, setShowMarkerAlert,mapRef, propertyPosition } = props;
  const {centerPoint, getPositionDevice, positionDevice, propertyId, loading, setLoading, showMarkerAlert, setShowMarkerAlert,mapRef, propertyPosition ,primaryObjects} = props;
  
  const objectRef = React.useRef([]);
  const handleShowPrimaryObjects = () => {
    var html = [];
    if(primaryObjects.length > 0){
      _.forEach(primaryObjects, function(object){
        const colorIndex = _.findIndex(objectColors, (color) => {
          return color.value === object.color
        });
        var color = objectColors[colorIndex].color;
        html.push(
          <Polygon key={object.id}
            positions={object.area}
            fillColor={color}
            color={color}
            className={`primary-objects type_id_${object.type_id}`}
            ref={el => (objectRef.current[object.id] = el)}
            fillOpacity={0.2}
          >
            <Tooltip permanent={true} direction="center" className={`primary-objects-tooltip type_id_${object.type_id}`}>
              <p>{object.name}</p>
            </Tooltip>
          </Polygon>
        );
      });
    }
    return html;
  }
  return (
    <DeviceMapWrapper>
     <Map ref={mapRef} center={ (centerPoint && centerPoint !== null) ? centerPoint : propertyPosition} doubleClickZoom={false} zoom={16} zoomControl={true} style={{width:'100%', height:400}}>
        <ReactLeafletGoogleLayer type={'satellite'} useGoogMapsLoader={false}/>
        <DeviceMapMarker 
          propertyId={propertyId} 
          getPositionDevice={getPositionDevice} 
          setLoading={setLoading} 
          loading={loading} 
          positionDevice={positionDevice}
          showMarkerAlert={showMarkerAlert}
          setShowMarkerAlert={setShowMarkerAlert}
        />
          {handleShowPrimaryObjects()}
      </Map>
    </DeviceMapWrapper>
  );
}

export default DeviceMap;
