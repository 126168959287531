import actions from './actions';

const initialState = {
  permissions: [],
  activePermission: null
};

export default function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case actions.CHANGE_ACTIVITY_PERMISSION:
      return {
        ...state,
        activePermission: action.activePermission
      };  
    default:
      return state;
  }
}
