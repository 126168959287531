import styled from 'styled-components';
import Modal from '@iso/components/Feedback/Modal';
import WithDirection from '@iso/lib/helpers/rtl';

const InspectionModalWrapper = styled(Modal)`
  &.user-access-modal{
    width: 1000px!important;
  }
  .ant-modal-body{
    padding: 0;
  }
  .btn-success{
    background-color: #f80e46;
    border-color: #f80e46;
  }
  
  .form-title{
    padding: 16px 24px;
    border-bottom: 1px solid #eaeef1;
    h3{
      font-size: 1rem;
    }
  }
  button.ant-btn.ant-btn-default.cancel-button{
    color: black !important;
    border-color: rgb(248, 14, 70) !important;
    width: 10%;
    color:white !important;
  }
 .ant-modal-footer{
    display:block !important;
  }
`;

export default WithDirection(InspectionModalWrapper);