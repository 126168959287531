export const weatherTypes = [
  {
    label: 'Rain Gauge',
    value: 'rain_gauge',
  },
  {
    label: 'Temperature Gauge',
    value: 'temperature_gauge',
  },
  {
    label: 'Weather Station',
    value: 'weather_station',
  },
]