import styled from "styled-components";
import Modal from "@iso/components/Feedback/Modal";
import WithDirection from "@iso/lib/helpers/rtl";

const SubscriptionModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding: 32px;
  }
  .instruction {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 13px;
  }
  .extra-note{
    display: block;
    margin-bottom: 33px;
  }
  .price {
    font-size: 32px;
    font-weight: 400;
    span:first-child {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
    }
    span:last-child {
      opacity: 0.68;
    }
  }
  .submit-btn {
    border-radius: 10px !important;
    background:rgb(248, 14, 70);
    width: 100%;
    height: 48px;
    margin-top: 51px;
  }
  .card-container {
    width: 100%;
    padding: 20px 20px 26px 20px;
    height: 200px;
    border-radius: 20px;
    border: 1px solid #fff;
    background: var(
      --gradient-grey-90060045-deg,
      linear-gradient(45deg, #101828 0%, #475467 100%)
    );
    box-shadow: 8px 10px 16px 0px rgba(0, 0, 0, 0.05);
    p {
      color: white;
    }
  }
  .card-row {
    color: white;
    display: flex;
    justify-content: space-between;
  }
`;

export default WithDirection(SubscriptionModalWrapper);
