import React, {useEffect,useRef } from 'react';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl,Marker,Popup } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import _ from 'lodash';
import { SyncOutlined } from '@ant-design/icons';
import Control from '@skyeer/react-leaflet-custom-control'
import {Button} from 'antd';
import {IotDeviceIcon} from './icons/IotDeviceIcon';

const DeviceMapMarker = (props) => {
  const {propertyId, getPositionDevice, setLoading, loading, positionDevice, setShowMarkerAlert, showMarkerAlert} = props;
  const objectRef = React.useRef([]);
  
  useEffect(() => {
    if(propertyId){
     getPositionDevice();
    }
  },[propertyId]);

  const showMakerDevice = (data) => {
    var html = [];
    if (data && data.length > 0){
      _.forEach(data, function(d){
        if(d.position && d.position.length > 0){
          _.forEach(d.position, function(p){
            if(p.latitude && p.longitude){
              html.push(
                <Marker 
                  key={p.id} 
                  position={[p.latitude, p.longitude]}
                  icon={IotDeviceIcon()}
                  // onClick={handleClickAsset.bind(this, asset)}
                  ref={el => (objectRef.current[p.id] = el)}            
                >
                  <Tooltip>
                    {d.device.name}
                  </Tooltip>
                </Marker>
              );
            }
          });
        }
      });
    }
    return html;
  }
  const showMarkerDeviceAlert = (data) => {
    if (data){
      return(
        <Marker 
          key={data.id} 
          position={[data.lat, data.lng]}
          icon={IotDeviceIcon()}
          // onClick={handleClickAsset.bind(this, asset)}
          ref={el => (objectRef.current[data.id] = el)}            
        >
          <Tooltip>
            {data.device_name}
          </Tooltip>
        </Marker>
      );
    }
  }
  const reloadMap = () => {
    setLoading(true);
    setShowMarkerAlert(false);
    getPositionDevice();
  }

  return (
    <>
    {showMarkerAlert ? showMarkerDeviceAlert(positionDevice) : showMakerDevice(positionDevice)}
    <Control position="topright">
      <Button className="reload-button" onClick={reloadMap} >
         { loading ? <SyncOutlined spin /> : <SyncOutlined />}
      </Button>
    </Control>
    </>
  );
}

export default DeviceMapMarker;
