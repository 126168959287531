import React, { useState, useEffect } from "react";
import TaskChartWrapper from "./TaskChart.styles";
import { Gantt, ViewMode } from "gantt-task-react";
import { ViewSwitcher } from "./ViewSwitcher";
import IntlMessages from "@iso/components/utility/intlMessages";
import "gantt-task-react/dist/index.css";
import moment from "moment";

export default function TaskChart(props) {
  const { dataTaskType } = props;
  const [view, setView] = useState(ViewMode.Day);
  const [taskView, setTaskView] = useState([]);

  let columnWidth = 65;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Year) {
    columnWidth = 200;
  }

  useEffect(() => {
    if (dataTaskType) {
      let arr = [];
      dataTaskType.lanes.forEach((item) => {
        item.cards.forEach((ele) => {
          arr.push({ ...ele, type: item.id });
        });
      });

      listViewTask(arr);
    }
  }, [dataTaskType]);

  const listViewTask = (lanes) => {
    const tasksArr = [];

    if (lanes.length > 0) {
      lanes.forEach((lane) => {
        const startDate = lane.metadata?.start_date
          ? formatDate(lane.metadata?.start_date)
          : formatDate(lane.metadata.created_at);

        const endDate = lane.metadata?.due_date
          ? formatDate(lane.metadata?.due_date)
          : formatDate(lane.metadata.created_at);

        if (startDate && endDate) {
          tasksArr.push({
            start: new Date(startDate),
            end: new Date(endDate),
            name: lane.title || "",
            id: lane.id || "",
            styles: {
              backgroundColor:
                lane.type === "todo"
                  ? "rgba(24, 119, 242, 0.60)"
                  : lane.type === "underway"
                  ? "rgba(250, 196, 101, 0.60)"
                  : lane.type === "completed"
                  ? "rgb(0, 100, 0, 0.60)"
                  : "#7CFC00",
            },
          });
        }
      });

      setTaskView(tasksArr);
    }
  };

  const formatDate = (dateString) => {
    return dateString.split(' ')[0];
  };

  const handleExpanderClick = (task) => {
    setTaskView(
      taskView.map((t) =>
        t.id === task.id ? task : t
      )
    );
  };
  return (
    <TaskChartWrapper>
      {taskView.length > 0 ? (
        <>
          <ViewSwitcher setView={setView} view={view} />
          <Gantt
            tasks={taskView}
            viewMode={view}
            ganttHeight={500}
            onExpanderClick={handleExpanderClick}
            columnWidth={columnWidth}
            rowHeight={64}
            barCornerRadius={10}
          />
        </>
      ):
      <div className="CustProgress">
      <div className="msgcust">
      <h1>
                      <IntlMessages id="No task found under this property" />
                    </h1>
                    </div>
                    </div>
      }
    </TaskChartWrapper>
  );
}
