import React, { useState, useEffect } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import { Button, Dropdown, Menu, Popconfirm, Timeline, Card, Row, Col } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined, DeleteOutlined,PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { weatherDataService } from '@iso/services';
import _ from 'lodash';
import moment from 'moment';
import PropertyManagePage from './Property.styles';

const WeatherHistory = (props) => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const activePermission = useSelector((state) => state.permission.activePermission);
  const dispatch = useDispatch();
  const [weatherHistory, setWeatherHistory] = useState([]);

  useEffect(() => {
    if(props.propertyId){
       weatherDataService.getList(props.propertyId).then(res => {
        if(res.code === '0000'){
          setWeatherHistory(res.weather_data.reverse());
        }
      });
    }
  }, [props.propertyId]);

  return (
    <PropertyManagePage>
      <div style={{ overflow: "scroll", width: "100%", height: "600px" }}>
      <br/>
        <Timeline>
         {(weatherHistory) ? (weatherHistory.map((value, index) => {
              if(value){
                 return  (<Timeline.Item color="green" key={index}>
                            <p>{moment(value.timelog).format('ll')}</p>
                            <Card title="Weather Data" bordered={true}>
                              <Row>
                                <Col xs={{span: 9}} md={{span: 9}}>
                                  <p className="card_content">Location</p> 
                                </Col>
                                <Col xs={{span: 13, offset: 2}} md={{span: 13, offset: 2}}>
                                 <b className="card_value">{value.primary_object_name}</b>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={{span: 9}} md={{span: 9}}>
                                  <p className="card_content">Created By</p> 
                                </Col>
                                <Col xs={{span: 13, offset: 2}} md={{span: 13, offset: 2}}>
                                 <b className="card_value">{value.username}</b>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={{span: 9}} md={{span: 9}}>
                                  <p className="card_content">Temperature</p> 
                                </Col>
                                <Col xs={{span: 13, offset: 2}} md={{span: 13, offset: 2}}>
                                 <b className="card_value">{value.temperature}&#8451;</b>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={{span: 9}} md={{span: 9}}>
                                  <p className="card_content">Rain</p> 
                                </Col>
                                <Col xs={{span: 13, offset: 2}} md={{span: 13, offset: 2}}>
                                 <b className="card_value">{value.rain} mm</b>
                                </Col>
                              </Row>
                            </Card>
                          </Timeline.Item>)
              }
              return false
          })) : ''}
        </Timeline>
      </div>
    </PropertyManagePage>
  )
}

export default WeatherHistory;