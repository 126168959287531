import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const healthsafety = {
  getCategoryChart,
  getDataTypePolicy,
  getDataActivity,
  storeOrUpdateActivityPolicies,
  destroyActivity,
  getActivityPolicyByProperty,
  getHealthAndSafetyAssets,
  trafficLight,
  search
};

function getCategoryChart(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.getCategoryChart}?propertyId=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.attachments(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}
function destroyActivity(id){
  return (
    axios
      .delete(
        apiEndpointFunction.activityPolicies(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function getDataActivity(facilitiesId){
  return (
    axios
      .get(
        `${apiEndpoint.activityPolicies}?facilitiesId=${facilitiesId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );

}
function getDataTypePolicy(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.getDataTypePolicy}?propertyId=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function storeOrUpdateActivityPolicies(data,id = null){
    return (id ? (
    axios
      .put(
        apiEndpointFunction.activityPolicies(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.activityPolicies,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function getActivityPolicyByProperty(propertyId){
  return (
    axios
      .get(
        `${apiEndpointFunction.activityPolicies(propertyId)}/get-activity-policy-by-property`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function getHealthAndSafetyAssets(propertyId){
  return (
    axios
      .get(
        `${apiEndpoint.facilities}/get-health-and-safety-assets?propertyId=${propertyId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}

function search(propertyId,s){
  return (
    axios
      .get(
        `${apiEndpoint.healthSafety}/search?property_id=${propertyId}&s=${s}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}

function trafficLight(propertyId){
  return (
    axios
      .get(
        `${apiEndpointFunction.activityPolicies(propertyId)}/traffic-light`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}