import axios from "axios";
import { apiEndpoint, apiEndpointFunction } from '@iso/lib/helpers/endpoint';
import { authHeader } from '@iso/lib/helpers/authHeader';

export const calculatorPaddock = {
  getList,
  view,
  storeOrUpdate,
  destroy
};

function getList(primaryObjectsId){
  return (
    axios
      .get(
        `${apiEndpoint.calculatorPaddock}?primary_object_id=${primaryObjectsId}`,
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200 && res.data.code === '0000'){
          return res.data;
        }
      })
  );
}
function view(id){
  return (
    axios
      .get(
        apiEndpointFunction.calculatorPaddock(id),
        {
          headers: authHeader(),
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
        return {};
      })
  );
}

function storeOrUpdate(data, id = null){
  return (id ? (
    axios
      .put(
        apiEndpointFunction.calculatorPaddock(id),
        data,
        {
          headers: authHeader(),
        }
      )
  ) : (
    axios
      .post(
        apiEndpoint.calculatorPaddock,
        data,
        {
          headers: authHeader()
        }
      )
  )).then(res => {
    if(res && res.status === 200){
      return res.data;
    }
    return {};
  });
}

function destroy(id){
  return (
    axios
      .delete(
        apiEndpointFunction.calculatorPaddock(id),
        {
          headers: authHeader()
        }
      ).then(res => {
        if(res && res.status === 200){
          return res.data;
        }
      })
  );
}