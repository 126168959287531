// Note: If you update Stripe Plan, please update the backend code as well.
// Sorry for the inconvenience, we will optimize this in the future if possible.

export const subscriptionPlan = [
  {
    label: 'Bronze - Free',
    value: process.env.REACT_APP_STRIPE_PLAN_BRONZE,
  },
  {
    label: 'Silver - $4.95 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_SILVER,
  },
  {
    label: 'Gold - $39.95 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_GOLD,
  },
  {
    label: 'SafeFarm - Single PLD (monthly) - $45.00 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD,
  },
  {
    label: 'SafeFarm - Single PLD (annual) - $360.00 pa',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_SINGLE_PLD_YEARLY,
  },
  {
    label: 'SafeFarm - Two PLDs (monthly) - $90.00 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLDS,
  },
  {
    label: 'SafeFarm - Two PLDs (annual) - $720.00 pa',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_TWO_PLD_YEARLY,
  },
  {
    label: 'SafeFarm - Three PLDs (monthly) - $135.00 pm',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD,
  },
  {
    label: 'SafeFarm - Three PLDs (annual) - $1080.00 pa',
    value: process.env.REACT_APP_STRIPE_PLAN_SAFEFARM_THREE_PLD_YEARLY,
  }
]
