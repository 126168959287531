export const propertyHistoryCategories = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Mob',
    value: 'mob',
  },
  {
    label: 'Livestock',
    value: 'livestock',
  },
  {
    label: 'Asset',
    value: 'asset',
  },
  {
    label: 'Weather',
    value: 'weather',
  }
]