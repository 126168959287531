import notificationActions from './actions';

const initialState = {
  notificationData: {}
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case notificationActions.NOTIFICATION_DATA:
      return {
        notificationData: action.data,
      };
    default:
      return state;
  }
}
